/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";

import { ModalDrawer } from "../ModalDrawer";

// components
import TermsAndCondition from "./components/TermsAndCondition";
import WalletList from "./components/WalletList";

interface WalletMenuType {
  isOpen: boolean;
  termsPageLink: string;
  connectorsList: Array<string>;
  closeModal(): void;
  onTermsLinkClick(): void;
  onTermsSelectChange(isChecked: boolean): void;
  onConnectWallet(id: string): void;
}

const WalletConnectModal = ({
  isOpen,
  termsPageLink,
  connectorsList,
  closeModal,
  onTermsSelectChange,
  onTermsLinkClick,
  onConnectWallet,
}: WalletMenuType) => {
  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setChecked(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const connectorSection = document.querySelectorAll(
        '#__CONNECTKIT__ div[role="dialog"] > div:last-child > div:last-child > div:last-child > div > div > div > div:first-child'
      );

      const connectorSectionElement = Array.from(connectorSection)[0];
      if (isChecked) {
        connectorSectionElement?.classList.add("enable");
      } else {
        connectorSectionElement?.classList.remove("enable");
      }
    } else {
      setChecked(false);
    }
  }, [isOpen, isChecked, setChecked]);

  const handleClose = () => {
    setChecked(false);
    closeModal();
  };

  const toggle = () => {
    onTermsSelectChange(!isChecked);
    setChecked((prev) => !prev);
  };

  return (
    <ModalDrawer
      isOpen={isOpen}
      closeAlert={handleClose}
      bodyProps={{ mt: "2rem" }}
      header={<Text width="100%">Select wallet</Text>}
      contentProps={{ p: { base: "2.5rem 1rem 1rem", md: "2.5rem 3rem" } }}
      headerProps={{
        p: "0",
        fontSize: "1.5rem",
        fontWeight: "600",
        textAlign: "center",
      }}
    >
      <>
        <TermsAndCondition
          isChecked={isChecked}
          termsPageLink={termsPageLink}
          onChange={toggle}
          onTermsLinkClick={onTermsLinkClick}
        />
        <WalletList
          isChecked={isChecked}
          onConnectWallet={onConnectWallet}
          connectorsList={connectorsList}
        />
      </>
    </ModalDrawer>
  );
};

export default WalletConnectModal;
