import { useEffect, useMemo, useState } from "react";
import { Flex, Text, CloseButton, Center } from "@chakra-ui/react";

// Constants
import { useDappSDKProvider } from "dapp-sdk-v2";
import CMSJson from "@constants/cms.json";

// Hooks
import { useCookies } from "react-cookie";
import { useRouter } from "next/router";
import { ETH } from "@constants/constants";
import { useEmbed } from "dapp-sdk-v2/hooks/useEmbed";

const token = process.env.NEXT_PUBLIC_TOKEN || "";

const initialState: any = {
  text: null,
  link: null,
  show: false,
  ctaText: null,
  isClosable: true,
  type: "",
  htmlEntity: "",
};

const cmsData: any = { ...CMSJson };

const cookieName = `promo_banner_disabled_${token}`;
const VID = "vid";

type Props = {
  Component: any;
};

const Banner = ({ Component }: Props) => {
  const router = useRouter();
  const { isEmbed } = useEmbed();
  const { isSafeMode, setUserData } = useDappSDKProvider();
  const [cookies, setCookie] = useCookies([cookieName]);
  const [bannerDetails, setBannerDetails] = useState(initialState);

  const isBannerExist = useMemo(() => {
    return (
      cmsData &&
      cmsData?.banner &&
      (cmsData.banner?.text ||
        cmsData.banner?.textSafeMode ||
        (isEmbed && cmsData.banner_ledger?.text))
    );
  }, [isEmbed]);

  useEffect(() => {
    if (router.query[VID] && !cookies[VID]) {
      setCookie(VID, router.query[VID]);
    }
  }, [router.query, cookies, setCookie]);

  useEffect(() => {
    if (isSafeMode && token === ETH && isBannerExist) {
      const bannerData = cmsData?.banner;
      setBannerDetails({
        ...bannerData,
        text: bannerData?.textSafeMode || bannerData?.text,
      });
    }
  }, [isBannerExist, isSafeMode]);

  useEffect(() => {
    if (cookies[cookieName]) {
      return;
    }
    if (token && !isSafeMode && isBannerExist) {
      if (isEmbed && cmsData?.banner_ledger?.text) {
        setBannerDetails(cmsData?.banner_ledger);
      } else {
        setBannerDetails(cmsData?.banner);
      }
    }
  }, [cookies, isSafeMode, isBannerExist, isEmbed]);

  const closeBanner = () => {
    setCookie(cookieName, true, {
      maxAge: 1209600,
    });
    setBannerDetails((prev: any) => {
      return prev ? { ...prev, show: false } : { show: false };
    });
  };

  const isBannerNotAllowed = ((Component as any).isHomePage ||
    (Component as any).isNodePage) as boolean;

  const getBGForBanner = useMemo(() => {
    if (isSafeMode) {
      return "#FF4D4D";
    } else if (bannerDetails.type === "info") {
      return "#2196F3";
    }
    return "primary";
  }, [isSafeMode, bannerDetails]);

  useEffect(() => {
    if (!isBannerNotAllowed && bannerDetails.show && bannerDetails.text) {
      setUserData({ isBannerVisible: true });
    } else {
      setUserData({ isBannerVisible: false });
    }
  }, [isBannerNotAllowed, bannerDetails]);

  if (!isBannerNotAllowed && bannerDetails.show && bannerDetails.text) {
    return (
      <Flex
        w="100%"
        bg={getBGForBanner}
        color="#FFFFFF"
        _dark={{
          color: "#142528",
        }}
        fontWeight="600"
        alignItems="center"
        pos={{ base: "relative", lg: "fixed" }}
        top="0"
        zIndex={4}
      >
        <Center flexGrow={1} flexDir={{ base: "column", lg: "row" }} p="8px">
          <Text
            textAlign={{ base: "left", md: "center" }}
            fontSize={{ base: "12px", lg: "14px" }}
            lineHeight={{ base: "18px", lg: "20px" }}
          >
            <Text as="span">{bannerDetails.text}</Text>
            {bannerDetails.htmlEntity && !isSafeMode && (
              <Text
                display="inline"
                ml="2px"
                sx={{
                  "& p": {
                    display: "inline",
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: `<p>${bannerDetails.htmlEntity} </p>`,
                }}
              />
            )}
          </Text>
          {bannerDetails.link && bannerDetails.ctaText && (
            <Text
              as="a"
              href={bannerDetails.link}
              target="_blank"
              rel="noreferrer"
              p="0.25rem"
              fontSize={{ base: "12px", lg: "14px" }}
              lineHeight={{ base: "18px", lg: "20px" }}
              color="white"
              textDecor="underline !important"
              cursor="pointer"
            >
              {bannerDetails.ctaText}
            </Text>
          )}
        </Center>

        {bannerDetails.isClosable && (
          <CloseButton
            onClick={() => {
              closeBanner();
            }}
          />
        )}
      </Flex>
    );
  }
  return null;
};

export default Banner;
