import { default as DocumentLegalLowHammer } from "./assets/document-legal-low-hammer.svg";
import { default as InsuranceHandsHeart } from "./assets/insurance-hands-heart.svg";
import { default as ThumpsUpCircle } from "./assets/thumps-up-circle.svg";
import { default as StaderInput } from "./assets/stader-input.svg";
import { default as SDStake } from "./assets/sd-stake.svg";
import { default as HandCoinsCurrency } from "./assets/hand-coins-currency.svg";
import { default as MoneyCoinsExchange } from "./assets/money-coins-exchange.svg";
import { default as HammerLegal } from "./assets/hammer-legal.svg";
import { default as Layout } from "./assets/layout.svg";
import { default as Wheel } from "./assets/wheel.svg";
import { default as Coins } from "./assets/coins.svg";
import { default as MeatSteak } from "./assets/meat-steak.svg";
import { default as PolygonShape } from "./assets/polygon.svg";
import { default as eth } from "./assets/blockchains/eth.svg";
import { default as bnb } from "./assets/blockchains/binance.svg";
import { default as JewelRingsWedding } from "./assets/jewel-rings-wedding.svg";
import { default as FunctionNotice } from "./assets/function-notice.svg";
import { default as CryptoCurrencyLoading } from "./assets/crypto-currency-loading.svg";
import { default as Lock } from "./assets/lock.svg";
import { default as TxSign } from "./assets/tx-sign.svg";
import { default as Stake } from "./assets/stake.svg";
import { default as EthSidebar } from "./assets/ethereum-sidebar.svg";
import { default as CheckMark } from "./assets/checkmark.svg";
import { default as DocumentsFileText } from "./assets/documents-file-text.svg";
import { default as User } from "./assets/user.svg";
import { default as FAQ } from "./assets/faq.svg";

export * from "./assets/blockchains";
export * from "./assets/blockchains_themed";
export * from "./assets/audits";
export * from "./assets/socials";
export * from "./assets/transaction";
export * from "./assets/wallets";
export * from "./assets/partners";

export { default as Close } from "./assets/close.svg"; // ModalClose
export { default as Close_mobile } from "./assets/close_mobile.svg"; // MobileClose(Rectangle)

export const iconMapping = (iconKey: string) => {
  switch (iconKey) {
    case "faq":
      return FAQ;
    case "stake":
      return Stake;
    case "lock":
      return Lock;
    case "document-legal-low-hammer":
      return DocumentLegalLowHammer;
    case "documents-file-text":
      return DocumentsFileText;
    case "jewel-rings-wedding":
      return JewelRingsWedding;
    case "function-notice":
      return FunctionNotice;
    case "crypto-currency-loading":
      return CryptoCurrencyLoading;
    case "insurance-hands-heart":
      return InsuranceHandsHeart;
    case "thumps-up-circle":
      return ThumpsUpCircle;
    case "stader-input":
      return StaderInput;
    case "hand-coins-currency":
      return HandCoinsCurrency;
    case "money-coins-exchange":
      return MoneyCoinsExchange;
    case "hammer-legal":
      return HammerLegal;
    case "layout":
      return Layout;
    case "wheel":
      return Wheel;
    case "sd-stake":
      return SDStake;
    case "tx-sign":
      return TxSign;
    case "coins":
      return Coins;
    case "meat-steak":
      return MeatSteak;
    case "polygon":
      return PolygonShape;
    case "eth":
      return eth;
    case "bnb":
      return bnb;
    case "eth-sidebar":
      return EthSidebar;
    case "user":
      return User;
  }
};

export {
  DocumentLegalLowHammer,
  InsuranceHandsHeart,
  ThumpsUpCircle,
  HandCoinsCurrency,
  MoneyCoinsExchange,
  HammerLegal,
  Layout,
  Wheel,
  StaderInput,
  SDStake,
  Coins,
  MeatSteak,
  PolygonShape,
  CheckMark,
};

export { default as MessageQuestionCheckmark } from "./assets/message-question-checkmark.svg";
export { default as DocumentsFileCopy } from "./assets/documents-file-copy.svg";
export { default as Sun } from "./assets/sun.svg";
export { default as MoonNightMode } from "./assets/moon-night-mode.svg";
export { default as Arrow } from "./assets/arrow.svg";
export { default as ChervonRight } from "./assets/chervon-right.svg";
export { default as LinkShear } from "./assets/link-shear.svg";
export { default as RightArrow } from "./assets/right-arrow.svg";
export { default as VerifiedUser } from "./assets/verified-user.svg";
export { default as SpaceId } from "./assets/spaceId.svg";
export { default as Unstoppable } from "./assets/unstoppable.svg";
export { default as Book } from "./assets/book.svg";
