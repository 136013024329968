import { useCallback, useEffect, useState } from "react";
import { useInterval, useIdle } from "react-use";
import { SD } from "../constants/constants";
import axios from "axios";

type UserTokenHoldingType = {
  balanceHolding: string | undefined;
  boostedRewardsHolding: string | undefined;
  isLoading: boolean;
};

const POLLING_INTERVAL = 10000;

const TOKEN = process.env.NEXT_PUBLIC_TOKEN;
const enabled = TOKEN === SD;

const getTokenHoldings = (address: string) => {
  return axios.get(
    `https://universe.staderlabs.com/sd/balance/?walletAdresss=${address}`
  );
};

const UserTokenHoldings = (address: string | null) => {
  const isIdle = useIdle(150e3);

  const [data, setData] = useState<UserTokenHoldingType>({
    boostedRewardsHolding: undefined,
    balanceHolding: undefined,
    isLoading: true,
  });

  const fetchTokenHoldings = useCallback(async () => {
    if (address) {
      const response = await getTokenHoldings(address);

      setData({
        balanceHolding: response?.data?.value.toFixed(8),
        boostedRewardsHolding: response?.data?.boostedValue.toFixed(8),
        isLoading: false,
      });
    }
  }, [address]);

  useEffect(() => {
    if (enabled) {
      fetchTokenHoldings();
    }
  }, [fetchTokenHoldings]);

  useInterval(() => {
    if (!isIdle && enabled) {
      fetchTokenHoldings();
    }
  }, POLLING_INTERVAL);

  return data;
};

export default UserTokenHoldings;
