import { NetworkConfigType } from "../types";

// ABI
import ERC20TOKENABI from "./contracts/mainnet/ERC20.json";
import TokenXABI from "./contracts/mainnet/TokenX.json";
import TokenStakeManagerABI from "./contracts/mainnet/Staking.json";
import RewardsABI from "./contracts/mainnet/Rewards.json";

const providerKey = process.env.NEXT_PUBLIC_ALCHEMY_KEY || "";

export const MainNetConfig: NetworkConfigType = {
  chainId: "0x1",
  supportedChainIDs: [1],
  providerKey,
  chainName: "Ethereum Mainnet",
  nativeCurrency: {
    name: "Stader",
    symbol: "SD",
    xsymbol: "SD",
    decimals: 18,
  },
  rpcUrls: {
    default: `https://eth-mainnet.g.alchemy.com/v2`,
  },
  blockExplorerUrls: ["https://etherscan.io"],
  contractAddresses: {
    erc20: "0x30d20208d987713f46dfd34ef128bb16c404d10f", // Token
    xtoken: {
      token: "0xF601B85eBe17895a56e20800F76b2B3Bf884bb9A",
      stakingContract: "0x62699594e2e53e36B3ab8D37d990c7eB4f26f8A9",
      rewardsContract: "0xdbbD789363FB2799DcF75Bef47d489ab3eB916CA",
    },
  },
  abis: {
    erc20: ERC20TOKENABI,
    xtoken: {
      token: TokenXABI,
      stakingContract: TokenStakeManagerABI,
      rewardsContract: RewardsABI,
    },
  },
};
