import { WALLET_KEYS } from "../constants/common";
import { useSelector } from "react-redux";

export const useEmbed = () => {
  const { connectorID } = useSelector((state: any) => state.wallet);

  const isSafeApp = connectorID === WALLET_KEYS.SAFE;
  const isEmbed = connectorID === WALLET_KEYS.LEDGER_LIVE;

  return { isEmbed, isSafeApp };
};
