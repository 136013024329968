import { config } from "../config/hedera";
import { Transaction, Client, TransactionReceipt } from "@hashgraph/sdk";
import BigNumber from "bignumber.js";

export const NATIVE_TOKEN_MULTIPLIER = 100000000;
export const precision = 4; //0.0001

export const getMaintainanceData = async () => {
  try {
    const resp: any = await fetch(config.underMaintainanceURL);
    const response: any = await resp.json();
    return response;
  } catch {
    return null;
  }
};

export const getTimeStamp = () => {
  return new Date().getTime() / 1000;
};

export const getTransactionURL = (transaction: any) => {
  let transactionIdURL =
    transaction.transactionId?.accountId +
    "-" +
    transaction.transactionId?.validStart;
  transactionIdURL = transactionIdURL.slice(0, -2) + "-000000000";
  return transactionIdURL;
};

export function toBase64(transaction: Transaction) {
  return Buffer.from(transaction.toBytes()).toString("base64");
}

export function fromBase64(transaction: string) {
  const x = Buffer.from(transaction, "base64");
  return Transaction.fromBytes(x);
}

export function nativeTokenFormatter(amount: number) {
  const bigNumAmount = new BigNumber(amount);
  const formattedAmount = bigNumAmount
    .dividedBy(new BigNumber(NATIVE_TOKEN_MULTIPLIER))
    .toFixed(precision, 3);
  return parseFloat(formattedAmount);
}

export async function getFormatedResponse(response: any, connectorID: string) {
  if (connectorID === "hashpack") {
    return response;
  } else {
    const res = {
      success: false,
      response: {
        transactionHash: response.transactionId.toString(),
      },
      error: "transaction failed, please try again.",
    };

    const client = Client.forName(config.network.name);
    const receipt: TransactionReceipt = await response.getReceipt(client);
    if (receipt.status.toString() === "SUCCESS") {
      res.success = true;
    }
    return res;
  }
}

export const getErrorData = (res: any) => {
  if (res?.error?.message) {
    if (
      res?.error?.message?.includes("reject") ||
      res?.error?.message?.includes("denied")
    ) {
      return {
        hasUserDenied: true,
        error: "user rejected transaction",
        hash: res?.response?.transactionHash || "",
      };
    } else {
      return {
        hasUserDenied: false,
        error: res?.error?.message,
        hash: res?.response?.transactionHash || "",
      };
    }
  } else {
    if (
      res?.error?.toLowerCase().includes("reject") ||
      res?.error?.includes("denied")
    ) {
      return {
        hasUserDenied: true,
        error: "user rejected transaction",
        hash: res?.response?.transactionHash || "",
      };
    } else {
      return {
        hasUserDenied: false,
        error: res?.error?.replace(/_/g, " ").toLowerCase(),
        hash: res?.response?.transactionHash || res?.hash || "",
      };
    }
  }
};

export const formatErrorData = (error: any) => {
  if (error.message) {
    if (error.message.includes("WalletConnect error")) {
      return true;
    }
  }
  return false;
};
