import { Connector, configureChains, createClient } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { WalletData } from "../../types/common";
import { getETHconfig, getETHChains } from "../ether";
import { getWallets } from "../helper/helper";

const NEXT_PUBLIC_NETWORK = process.env.NEXT_PUBLIC_NETWORK;

const buildClient: any = (
  network: string,
  providerKey: string,
  wallets: Array<WalletData>,
  isNativeChain?: boolean
) => {
  // eslint-disable-next-line no-console
  console.log(isNativeChain);

  const config = getETHconfig(network);

  const { chains, provider } = configureChains(getETHChains(network), [
    jsonRpcProvider({
      rpc: () => ({
        http: `${config.rpcUrls.default}/${
          NEXT_PUBLIC_NETWORK === "testnet" ? "" : providerKey
        }`,
      }),
    }),
  ]);

  const wagmiClient = createClient({
    autoConnect: true,
    connectors: getWallets(wallets, chains) as unknown as Connector<
      any,
      any,
      any
    >[],
    provider,
  });

  return { wagmiClient, config };
};

export default buildClient;
