import React, { useMemo } from "react";

import { Flex, Box } from "@chakra-ui/react";

import CopyIcon from "../../../CopyIcon";
import { AccountModalActionItem, TextPrimary } from "theme";

import { shortenAddress } from "../../../../utils/common";
import { walletIconMapping } from "../../../../constants/walletMenuOptions";
import { useEmbed } from "../../../../hooks/useEmbed";
import BaseImage from "../../../Image";

interface WalletAddressProps {
  walletAddress?: string;
  connectorId: string;
  onCopyAddress(): void;
  onCancel(isDisconnected?: boolean): void;
}
const WalletAddress = ({
  walletAddress,
  connectorId,
  onCopyAddress,
  onCancel,
}: WalletAddressProps) => {
  const handleDisconnect = () => {
    onCancel(true);
  };

  const { isEmbed, isSafeApp } = useEmbed();

  const shortenWalletAddress = useMemo(() => {
    return shortenAddress(walletAddress || "");
  }, [walletAddress]);

  return (
    <Flex
      justifyContent="space-between"
      p={{
        base: "1rem",
        md: "1.5rem 2rem",
      }}
      bg="backgroundPrimary"
      borderRadius="0.5rem"
      alignItems="center"
      width="100%"
    >
      <Flex alignItems="center">
        {connectorId && (
          <BaseImage
            src={walletIconMapping[connectorId.toLowerCase()]}
            alt="wallet"
            width={40}
            height={40}
          />
        )}
        <TextPrimary ml="1rem" fontWeight="600" fontSize="16px">
          {shortenWalletAddress}
        </TextPrimary>
        <Box as="button" h="24px" w="24px" cursor="pointer" ml="1rem">
          <CopyIcon address={walletAddress} onClick={onCopyAddress} />
        </Box>
      </Flex>
      {!(isEmbed || isSafeApp) && (
        <AccountModalActionItem onClick={handleDisconnect}>
          Disconnect
        </AccountModalActionItem>
      )}
    </Flex>
  );
};

export default WalletAddress;
