/* eslint-disable no-prototype-builtins */
import dynamic from "next/dynamic";
import { trackEvent as track } from "../utils/firebase";
import { emitEvent as emit } from "../utils/common";

import { createContext } from "react";
import { useSelector, useDispatch, Provider } from "react-redux";

import {
  updateStakeData,
  updateEventData,
  updateUserData,
  updateWalletData,
  EventStateProps,
  StakeStateProps,
  UserStateProps,
  WalletStateProps,
  initialEventState,
  initialStakeState,
  initialUserState,
  initialWalletState,
  store,
} from "store";
import { PROVIDERS } from "../constants/constants";
import _ from "lodash";

const token = process.env.NEXT_PUBLIC_TOKEN || "";

const StakeProvider: any = dynamic(
  () => import("../index").then((mod: any) => mod[PROVIDERS[token]]),
  {
    ssr: true,
  }
);

interface DappSDKContextProps
  extends EventStateProps,
    StakeStateProps,
    WalletStateProps,
    UserStateProps {
  emitEvent: (eventName: string, eventData: any) => void;
  trackEvent: (eventName: string, eventData: any) => void;
  setStakeData: (data: any) => void;
  setWalletData: (data: any) => void;
  setUserData: (data: any) => void;
  setEventData: (data: any) => void;
  setData: (data: any) => void;
}

export const DappSDKContext = createContext<DappSDKContextProps>({
  emitEvent: () => {},
  trackEvent: () => {},
  setStakeData: () => {},
  setWalletData: () => {},
  setUserData: () => {},
  setEventData: () => {},
  setData: () => {},
  ///// wallet slice////////
  ...initialWalletState,
  ////// event slice /////
  ...initialEventState,
  ////// user slice /////
  ...initialUserState,
  ////// stake slice /////
  ...initialStakeState,
});

const SDKProvider = ({ children }: any) => {
  const dispatch = useDispatch();
  const walletData = useSelector((state: any) => state.wallet);
  const stakeData = useSelector((state: any) => state.stake);
  const userData = useSelector((state: any) => state.user);
  const eventData = useSelector((state: any) => state.event);

  const emitEvent = (eventName: string, eventData: any) => {
    emit(eventName, eventData);
  };

  const trackEvent = (eventName: string, eventData: any) => {
    track(eventName, eventData);
  };

  const setStakeData = (data: StakeStateProps) =>
    dispatch(updateStakeData(data));

  const setWalletData = (data: WalletStateProps) =>
    dispatch(updateWalletData(data));

  const setUserData = (data: UserStateProps) => dispatch(updateUserData(data));

  const setEventData = (data: EventStateProps) =>
    dispatch(updateEventData(data));

  const setData = (
    data: StakeStateProps | WalletStateProps | UserStateProps | EventStateProps
  ) => {
    const wallet_data: any = {};
    const stake_data: any = {};
    const event_data: any = {};
    const user_data: any = {};

    for (const [key, value] of Object.entries(data)) {
      if (initialWalletState.hasOwnProperty(key)) {
        wallet_data[key] = value;
      } else if (initialEventState.hasOwnProperty(key)) {
        event_data[key] = value;
      } else if (initialStakeState.hasOwnProperty(key)) {
        stake_data[key] = value;
      } else if (initialUserState.hasOwnProperty(key)) {
        user_data[key] = value;
      }
    }
    if (!_.isEmpty(wallet_data)) {
      dispatch(updateWalletData(wallet_data));
    }
    if (!_.isEmpty(event_data)) {
      dispatch(updateEventData(event_data));
    }
    if (!_.isEmpty(stake_data)) {
      dispatch(updateStakeData(stake_data));
    }
    if (!_.isEmpty(user_data)) {
      dispatch(updateUserData(user_data));
    }
  };

  return (
    <DappSDKContext.Provider
      value={{
        emitEvent,
        trackEvent,
        setStakeData,
        setWalletData,
        setUserData,
        setEventData,
        setData,
        ...walletData,
        ...stakeData,
        ...userData,
        ...eventData,
      }}
    >
      {children}
    </DappSDKContext.Provider>
  );
};

export const DappSDKProvider = ({ children, wallets }: any) => {
  return (
    <Provider store={store}>
      <StakeProvider wallets={wallets} />
      <SDKProvider>{children}</SDKProvider>
    </Provider>
  );
};
