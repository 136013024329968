import { useEffect, useState } from "react";
import { useContractRead } from "wagmi";

import { ReferralUserType } from "../types/common";
import { useSelector } from "react-redux";

const DEFAULT_ADDRESS = "0x0000000000000000000000000000000000000000";

const useReferral = (referralId: string | undefined) => {
  const {
    walletAddress: address,
    isConnected,
    config,
  } = useSelector((state: any) => state.wallet);

  const [userType, setUserType] = useState<ReferralUserType>();
  const [isValidReferralId, setValidReferralId] = useState(true);

  useContractRead({
    address: config?.contractAddresses.referral,
    abi: config?.abis.referral,
    functionName: "referralIdToWallet",
    chainId: +config?.chainId,
    args: [referralId],
    enabled: !!referralId,
    structuralSharing: true,
    onSuccess(data) {
      if (data) {
        if ((data as unknown as string) === DEFAULT_ADDRESS)
          setValidReferralId(false);
      }
    },
  });

  const { data: kolsAddresses, isFetched: kolAddressesFetched } =
    useContractRead({
      address: config?.contractAddresses.referral,
      abi: config?.abis.referral,
      functionName: "getKOLs",
      chainId: +config?.chainId,
      enabled: !!referralId,
      structuralSharing: true,
    });

  const { data: currentReferrer, isFetched: currentReferrerFetched } =
    useContractRead({
      address: config?.contractAddresses.referral,
      abi: config?.abis.referral,
      functionName: "userReferredBy",
      chainId: +config?.chainId,
      args: [address],
      enabled: isConnected && !!referralId,
      structuralSharing: true,
      onSuccess(data) {
        if (data) {
          setUserType(ReferralUserType.REFERRED);
        }
      },
    });

  const isKOL =
    referralId &&
    kolAddressesFetched &&
    isConnected &&
    (kolsAddresses as string[]).some(
      (kol: string) => kol.toLowerCase() === address?.toLowerCase()
    );

  useEffect(() => {
    if (
      referralId &&
      isConnected &&
      kolAddressesFetched &&
      currentReferrerFetched
    ) {
      if (isKOL) {
        setUserType(ReferralUserType.KOL);
        return;
      }

      if (!isKOL && !currentReferrer) {
        setUserType(ReferralUserType.NEW);
        return;
      }
    }
  }, [
    currentReferrer,
    currentReferrerFetched,
    isConnected,
    isKOL,
    kolAddressesFetched,
    referralId,
  ]);

  return { userType, referralId, isValidReferralId };
};

export default useReferral;
