import { useEffect } from "react";
import {
  useTVL,
  useAPY,
  useExchangeRate,
  TOKENTYPE,
  useAllowance,
  useGasFees,
  useAssociate,
} from "@stader-labs/web-sdk"; // from "@stader-labs/web-sdk"
import { useDispatch, useSelector } from "react-redux";
import { updateUserData, updateStakeData } from "store";
import { useWithdrawals } from "../../hooks/useWithdrawals";

import {
  NON_APPROVAL_CHAINS_STAKE,
  NON_APPROVAL_CHAINS_UNSTAKE,
} from "../../constants/common";
import { nativeTokenFormatter } from "../utils";
import { config } from "../../config/hedera";

const TOKEN = process.env.NEXT_PUBLIC_TOKEN || "";
var count = 0;
const WebSDKServices = () => {
  const dispatch = useDispatch();
  const { isConnected, walletAddress: address } = useSelector(
    (state: any) => state.wallet
  );
  const { approveTokenLoading, approveTokenXLoading } = useSelector(
    (state: any) => state.stake
  );
  const { txn, tokenAmount, tokenXAmount } = useSelector(
    (state: any) => state.user
  );

  const gasFeesData = useGasFees();

  const { fetchAllowance } = useAllowance();

  const { fetchAssociatedTokens } = useAssociate();

  const tvlData = useTVL({});
  const apyData = useAPY({
    enabled: TOKEN !== "ETH",
  });
  const conversionData = useExchangeRate({});

  useWithdrawals();

  useEffect(() => {
    if (isConnected && address) {
      getAssociatedTokens();
    }
  }, [isConnected, address]);

  const getAssociatedTokens = async () => {
    let isTokenXAssociated = false;
    try {
      const associatedTokens: any = await fetchAssociatedTokens(address);
      associatedTokens.forEach((token: any) => {
        if (token.token_id === config.ids.tokenId) {
          isTokenXAssociated = true;
        }
      });

      dispatch(
        updateStakeData({
          tokenXAssociated: isTokenXAssociated,
        })
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      dispatch(
        updateStakeData({
          tokenXAssociated: false,
        })
      );
    }
  };

  useEffect(() => {
    if (tvlData && apyData) {
      dispatch(
        updateUserData({
          tvlData,
          apyData,
        })
      );
    }
    if (conversionData) {
      dispatch(
        updateUserData({
          conversionData,
        })
      );
    }
  }, [tvlData, apyData, conversionData]);

  const getAllowances = async () => {
    if (address) {
      try {
        const erc20Allowance = await fetchAllowance(address, TOKENTYPE.TOKEN);
        if (erc20Allowance) {
          dispatch(
            updateStakeData({
              tokenAllowence: nativeTokenFormatter(erc20Allowance).toString(),
            })
          );
        }
      } catch (e) {
        dispatch(
          updateStakeData({
            tokenAllowence: null,
          })
        );
      }

      try {
        const xTokenAllowance = await fetchAllowance(address, TOKENTYPE.TOKENX);

        if (xTokenAllowance) {
          dispatch(
            updateStakeData({
              tokenXAllowence: nativeTokenFormatter(xTokenAllowance).toString(),
            })
          );
        }
      } catch (e) {
        dispatch(
          updateStakeData({
            tokenXAllowence: null,
          })
        );
      }
    } else {
      dispatch(
        updateStakeData({
          tokenAllowence: null,
          tokenXAllowence: null,
        })
      );
    }
  };

  useEffect(() => {
    if (
      isConnected &&
      ![...NON_APPROVAL_CHAINS_STAKE, ...NON_APPROVAL_CHAINS_UNSTAKE].includes(
        TOKEN
      )
    ) {
      getAllowances();
      const interval = setInterval(() => {
        getAllowances();
        if (count > 1) {
          count = 0;
          clearInterval(interval);
        }
        count++;
      }, 5000);
      return;
    }
    dispatch(
      updateStakeData({
        tokenAllowence: null,
        tokenXAllowence: null,
      })
    );
  }, [
    isConnected,
    approveTokenLoading,
    approveTokenXLoading,
    txn.status,
    address,
    tokenAmount,
    tokenXAmount,
  ]);

  useEffect(() => {
    if (gasFeesData) {
      dispatch(updateStakeData({ gasFeesData }));
    }
  }, [gasFeesData]);

  return null;
};

export default WebSDKServices;
