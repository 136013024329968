import { Chain } from 'wagmi/chains';

import { TestNetConfig } from './testnet';
import { MainNetConfig } from './mainnet';

export const BNBChainConfig: Chain = {
  id: 56,
  name: 'Binance Smart Chain',
  network: 'bsc',
  nativeCurrency: {
    decimals: 18,
    name: 'Binance',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: {
      http: [
        'https://bsc-mainnet.nodereal.io/v1/8438d9c512534d20a2367b930e8863e3',
      ],
    },
    public: {
      http: [
        'https://bsc-mainnet.nodereal.io/v1/8438d9c512534d20a2367b930e8863e3',
      ],
    },
  },
  blockExplorers: {
    default: { name: 'BSCscan', url: 'https://bscscan.com' },
  },
  testnet: false,
};

export const BNBestnet: Chain = {
  id: 97,
  name: 'Binance Smart Chain - Testnet',
  network: 'bsc-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Binance',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: {
      http: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
    },
    public: {
      http: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
    },
  },
  blockExplorers: {
    default: {
      name: 'BSCscan',
      url: 'https://testnet.bscscan.com',
    },
  },
  testnet: true,
};

export const getBNBconfig = (network: string) =>
  network === 'mainnet' ? MainNetConfig : TestNetConfig;

export const getBNBChains = (network: string) => {
  return network === 'mainnet' ? [BNBChainConfig] : [BNBestnet];
};
