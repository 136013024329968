import React, { useEffect, useMemo, useState } from "react";
import { useAccount, useNetwork, useProvider, useSigner } from "wagmi";
import { Contract } from "ethers";
import { ExternalProvider } from "@ethersproject/providers";
import { Biconomy } from "@biconomy/mexa";
import {
  Box,
  Button,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { BiEditAlt, BiCheckShield } from "react-icons/bi";
import { RiErrorWarningFill } from "react-icons/ri";
import { iconMapping as SvgMapping } from "icons";

// Constants
import { ReferralUserType } from "../../types/common";
import { iconMapping, TXN_TYPE } from "./constants";

import { getChains } from "../../config";
import Icon from "../Icon";

// import useWalletSigner from '../../hooks/useWalletSigner';

const initialTxnObj = { hash: "", status: "" };

let biconomy: any;

const Referral = ({
  user,
  referralId,
  isValidReferralId,
  referralCallBack,
  network,
  token,
  config,
}: {
  user: ReferralUserType;
  referralId: string;
  isValidReferralId: boolean;
  referralCallBack: any;
  network: string;
  token: string;
  config: any;
}) => {
  const chainConfig = useMemo(() => {
    return getChains(token, network)[0];
  }, [token, network]);

  const { chain } = useNetwork();
  const { address, isConnected } = useAccount();
  const provider = useProvider();
  const { data: signer } = useSigner();

  const [txnObj, setTxnObj] = useState(initialTxnObj);
  const [isSigning, setIsSigning] = useState(false);
  const [bicoInitDone, setBicoInitDone] = useState(false);

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const handleClose = () => {
    onClose();
    referralCallBack && referralCallBack();
    setTxnObj(initialTxnObj);
  };

  useEffect(() => {
    if (
      isConnected &&
      signer &&
      provider &&
      config.contractAddresses.referral
    ) {
      const initBiconomy = async () => {
        const p = (signer?.provider as any).provider;

        biconomy = new Biconomy(p as ExternalProvider, {
          apiKey: process.env.NEXT_PUBLIC_BICONOMY_KEY as string,
          debug: true,
          contractAddresses: [
            config.contractAddresses.referral as unknown as string,
          ],
        });

        await biconomy.init();
        setBicoInitDone(true);

        biconomy.on("txHashGenerated", (data: any) => {
          setTxnObj({ hash: data.hash, status: TXN_TYPE.PENDING });
        });

        biconomy.on("txMined", (data: any) => {
          setTxnObj({ hash: data.hash, status: TXN_TYPE.SUCCESS });
        });

        biconomy.on("onError", () => {
          setTxnObj((prev) => ({ ...prev, status: TXN_TYPE.ERROR }));
        });
      };

      initBiconomy();
    } else if (biconomy && !isConnected) {
      biconomy = undefined;
    }
  }, [signer, provider, chain, isConnected, config.contractAddresses.referral]);

  const signReferralTxn = async () => {
    try {
      if (config.contractAddresses.referral) {
        setIsSigning(true);

        const bProvider = await biconomy.provider;

        const contractInstance = new Contract(
          config.contractAddresses.referral,
          config.abis.referral,
          biconomy.ethersProvider
        );

        biconomy.ethersProvider.on("debug", (data: any) => {
          if (
            data.error &&
            (data.error.message.toLowerCase().indexOf("denied") !== -1 ||
              data.error.message.toLowerCase().indexOf("rejected") !== -1)
          ) {
            setIsSigning(false);
          }
        });

        const { data, to } =
          await contractInstance.populateTransaction.storeUserInfo(referralId);

        const txParams = {
          data: data,
          to: to,
          from: address,
          signatureType: "PERSONAL_SIGN",
        };

        await bProvider.send({
          method: "eth_sendTransaction",
          params: [txParams],
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      setIsSigning(false);
    }
  };

  if (!isValidReferralId) {
    return (
      <Modal isOpen={isOpen} onClose={handleClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="backgroundSecondary" m="0.5rem" maxW="394px">
          <ModalHeader fontSize="1rem" fontWeight="500">
            Referral Code
          </ModalHeader>
          <ModalCloseButton
            _focus={{
              boxShadow: "none",
            }}
          />
          <ModalBody pt="0" mb="0.5rem" color="textPrimary">
            <Center flexDir="column">
              <Box color="#ECC94B" fontSize={60}>
                <RiErrorWarningFill />
              </Box>
              <Text mt="1.5rem" textAlign="center">
                Invalid Referral Code. Please check with the Referrer.
              </Text>
              <Button
                variant="outline"
                onClick={handleClose}
                mt="1rem"
                _hover={{ bg: "primary" }}
              >
                Close
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  if (!bicoInitDone) {
    return null;
  }

  const renderContent = () => {
    if (txnObj.status) {
      return (
        <ModalContent bg="backgroundSecondary" m="0.5rem" maxW="394px">
          <ModalHeader fontSize="1rem" fontWeight="500">
            Referral Code
          </ModalHeader>
          <ModalCloseButton
            _focus={{
              boxShadow: "none",
            }}
          />
          <ModalBody pt="0" mb="0.5rem" color="textPrimary">
            <Center flexDir="column" gap="1rem" textAlign="center">
              <Box>{iconMapping[txnObj.status]}</Box>
              {txnObj.status === TXN_TYPE.PENDING && (
                <>
                  <Text fontSize={16}>Transaction Submitted.</Text>
                  <Text fontSize={14}>
                    Waiting for confirmation. Please Wait!
                  </Text>
                  <Text
                    as="a"
                    href={`${config.blockExplorerUrls}/tx/${txnObj.hash}`}
                    target="_blank"
                    rel="noreferrer"
                    fontSize="14px"
                    color="primary"
                    fontWeight="500"
                    cursor="pointer"
                  >
                    View on {chainConfig.blockExplorers?.default.name}
                  </Text>
                </>
              )}
              {txnObj.status === TXN_TYPE.SUCCESS && (
                <>
                  <Text>Transaction successful</Text>
                  <Text
                    as="a"
                    href={`${config.blockExplorerUrls}/tx/${txnObj.hash}`}
                    target="_blank"
                    rel="noreferrer"
                    fontSize="14px"
                    color="primary"
                    fontWeight="500"
                    cursor="pointer"
                  >
                    View on {chainConfig.blockExplorers?.default.name}
                  </Text>
                  <Text fontWeight="500">
                    Congratulations! Referral code applied.
                  </Text>
                  <Text>
                    Now stake your BNB & get 20% extra staking rewards for 1st
                    month
                  </Text>
                </>
              )}
              {txnObj.status === TXN_TYPE.ERROR && (
                <>
                  <Text fontSize="16px">Transaction Failed</Text>
                  <Text
                    as="a"
                    href={`${config.blockExplorerUrls}/tx/${txnObj.hash}`}
                    target="_blank"
                    rel="noreferrer"
                    fontSize="14px"
                    color="primary"
                    fontWeight="500"
                    cursor="pointer"
                  >
                    View on {chainConfig.blockExplorers?.default.name}
                  </Text>
                </>
              )}
              <Button
                variant="outline"
                onClick={handleClose}
                _hover={{ bg: "primary" }}
              >
                Close
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      );
    }
    if (user === ReferralUserType.NEW) {
      return (
        <ModalContent bg="backgroundSecondary" m="0.5rem" maxW="530px">
          <ModalHeader fontSize="1rem" fontWeight="500">
            Apply Referral Code
          </ModalHeader>
          <ModalCloseButton
            _focus={{
              boxShadow: "none",
            }}
          />
          <ModalBody pt="0" mb="0.5rem" fontSize={12}>
            <Text>
              Sign the transaction to apply referral code{" "}
              <Text as="span" fontWeight="700">
                {referralId}
              </Text>{" "}
              and get{" "}
              <Text as="span" fontWeight="700">
                20% extra staking rewards
              </Text>{" "}
              for the 1st month
            </Text>
            <Flex py="2rem">
              <Flex flexDir="column" w="70%" gap="2rem" justify="center">
                <Flex gap="1rem" align="center">
                  <Box w="20px">
                    <BiEditAlt fontSize={20} />
                  </Box>
                  <Text>
                    Sign the transaction in your wallet <br />
                    This is one time and gasless interaction
                  </Text>
                </Flex>
                <Flex gap="1rem" align="center">
                  <Box w="20px">
                    <BiCheckShield fontSize={20} />
                  </Box>
                  <Text>
                    Proceed to stake your BNB and get 20% extra staking rewards
                    for the 1st month
                  </Text>
                </Flex>
                <Center>
                  <Button
                    size="sm"
                    p="1rem 1.5rem"
                    onClick={isSigning ? undefined : signReferralTxn}
                    bg="primary"
                    w="fit-content"
                  >
                    {isSigning ? (
                      <Spinner size="sm" color="black" />
                    ) : (
                      "Proceed"
                    )}
                  </Button>
                </Center>
              </Flex>
              <Flex justify="center" w="40%">
                <Icon
                  Icon={SvgMapping("tx-sign")}
                  width="139px"
                  height="224px"
                />
              </Flex>
            </Flex>
            <Text>
              Why is this transaction needed? Check{" "}
              <Text
                fontWeight="500"
                as="a"
                href="https://staderlabs.notion.site/Stader-Ambassador-Program-5cfd3d7c9ceb4a6ca7ff12936a01a75e"
                target="_blank"
                rel="noreferrer"
              >
                here
              </Text>
              .
            </Text>
          </ModalBody>
        </ModalContent>
      );
    }
    if (user === ReferralUserType.REFERRED) {
      return (
        <ModalContent bg="backgroundSecondary" m="0.5rem" maxW="394px">
          <ModalHeader fontSize="1rem" fontWeight="500">
            Referral Code
          </ModalHeader>
          <ModalCloseButton
            _focus={{
              boxShadow: "none",
            }}
          />
          <ModalBody pt="0" mb="0.5rem" color="textPrimary">
            <Center flexDir="column">
              <Box color="#ECC94B" fontSize={60}>
                <RiErrorWarningFill />
              </Box>
              <Text mt="1.5rem" textAlign="center">
                Referral code has already been applied. You cannot apply a
                second referral.
              </Text>
              <Button
                variant="outline"
                onClick={handleClose}
                mt="1rem"
                _hover={{ bg: "primary" }}
              >
                Close
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      );
    }
    return null;
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      {renderContent()}
    </Modal>
  );
};

export default Referral;
