export const EVENTS = {
  HANDLE_CONNECT: "handleConnect",
  HANDLE_DISCONNECT: "handleDisconnect",
  HANDLE_TRANSACTION: "handleTransaction",
  OPEN_WALLET_MODAL: "openWalletModal",
};

export const TRANSACTION_TYPE = {
  STAKE: "stake",
  UNSTAKE: "unstake",
  WITHDRAW: "withdraw",
  APPROVE_TOKEN: "approve_token",
  APPROVE_TOKEN_X: "approve_token_x",
  ASSOCIATE_TOKEN: "associate_token",
  ASSOCIATE_TOKEN_X: "associate_token_x",
  SWAP: "swap",
};
