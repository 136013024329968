import buildBNBWagmiClient from "./bnbx";
import buildETHWagmiClient from "./ethx";
import buildSDWagmiClient from "./sd";
import buildMATICWagmiClient from "./maticx";
import buildFantomWagmiClient from "./fantomx";
import { WalletData } from "../../types/common";

export const client: {
  [x: string]: (
    network: string,
    providerKey: string,
    wallets: Array<WalletData>,
    isNativeChain?: boolean
  ) => any;
} = {
  BNB: buildBNBWagmiClient,
  ETH: buildETHWagmiClient,
  POLYGON: buildMATICWagmiClient,
  SD: buildSDWagmiClient,
  FANTOM: buildFantomWagmiClient,
};
