import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Tabs: ComponentStyleConfig = {
  variants: {
    primary: {
      tablist: {
        color: "textSecondary",
        bg: "tabBackground",
        borderTopLeftRadius: "16px",
        borderTopRightRadius: "16px",
        w: {
          md: "fit-content",
          base: "100%",
        },
        fontSize: "16px",
        border: "none",
      },
      tab: {
        fontWeight: "600",
        fontSize: {
          md: "16px",
          base: "14px",
        },
        _selected: {
          color: "textPrimary",
          bg: "backgroundSecondary",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
        },
      },
    },
  },
  defaultProps: {
    variant: "primary",
  },
};

const TicketCard = {
  baseStyle: {
    bg: "backgroundSecondary",
    p: {
      base: "1.5rem 1rem",
      lg: "2rem",
    },
    borderBottomRadius: "24px",
    borderTopRightRadius: {
      base: 0,
      md: "24px",
    },
    fontSize: "14px",
    color: "textSecondary",
  },
};

const SidebarContainer = {
  baseStyle: {
    bg: { base: "backgroundSecondary", xl: "backgroundPrimary" },
    w: { base: "full", xl: 60 },
    h: { base: "full", xl: "90%" },
    pos: "fixed",
    p: "2rem 1.5rem 0 2.5rem",
    color: "textSecondary",
    fontWeight: "400",
    fontSize: "14px",
  },
};

const InfoCardBody = {
  baseStyle: {
    flexDir: "column",
    bg: "backgroundSecondary",
    p: { base: "1.5rem 1rem", xl: "0" },
    borderRadius: "16px",
    mt: { base: "1rem", xl: "2rem" },
  },
};

const TextPrimary = {
  baseStyle: {
    color: "textPrimary",
  },
};

const TextSecondary = {
  baseStyle: {
    color: "textSecondary",
  },
};

const AccountModalActionItem = {
  baseStyle: {
    fontWeight: {
      base: "700",
      md: "600",
    },
    fontSize: {
      base: "10px",
      md: "14px",
    },
    color: "primary",
    cursor: "pointer",
    _dark: {
      color: "textPrimary",
    },
  },
};

const AuditsContainer = {
  baseStyle: {
    display: "flex",
    ml: "4px",
    p: "4px 8px",
    borderRadius: "8px",
    bg: "backgroundSecondary",
    border: "1px solid",
    borderColor: "seperator",
    cursor: "pointer",
    gap: "8px",
    height: "32px",
    alignItems: "center",
  },
};

const IconBackgroundContainer = {
  baseStyle: {
    display: "flex",
    w: { base: "32px", lg: "48px" },
    h: { base: "32px", lg: "48px" },
    borderRadius: "50%",
    bg: "iconBackground",
    alignItems: "center",
    justifyContent: "center",
    color: "textSecondary",
  },
};

const HeaderContainer = {
  baseStyle: {
    display: "flex",
    ml: { base: 0, xl: 60 },
    p: { base: "1rem", xl: "0" },
    pt: { base: "1rem", xl: "2.5rem" },
    alignItems: "center",
    borderBottomWidth: "1px",
    justifyContent: { base: "space-between", xl: "flex-end" },
    border: "none",
  },
};

const Input = {
  variants: {
    primary: {
      field: {
        borderWidth: "1px",
        borderColor: "primary",
        fontSize: "1rem",
        _focus: {
          borderColor: "primary",
          borderWidth: "2px",
        },
        _hover: {
          borderColor: "primary",
          borderWidth: "2px",
        },
        _disabled: {
          opacity: 1,
        },
        bg: "transparent",
      },
    },
  },
  defaultProps: {
    variant: "primary",
  },
};

export const commonStyles = {
  components: {
    Tabs,
    TicketCard,
    Input,
    InfoCardBody,
    AuditsContainer,
    IconBackgroundContainer,
    HeaderContainer,
    SidebarContainer,
    AccountModalActionItem,
    TextPrimary,
    TextSecondary,
    Checkbox: {
      baseStyle: {
        control: {
          border: "1px !important",
          borderColor: "primary !important",
          bg: "white",
          _checked: {
            bg: "primary",
            borderColor: "primary",
            _hover: {
              bg: "primary",
              borderColor: "primary",
            },
          },
          _hover: {
            borderColor: "primary",
          },
        },
      },
      sizes: {
        md: {
          control: {
            w: "24px",
            h: "24px",
          },
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: "8px",
      },
      variants: {
        primary: {
          colorScheme: "primary",
          color:"white",
          bg: "primary",
          _hover: {
            border: "none",
            bg: "primary",
            boxShadow: "none",
            opacity: 1,
          },
          _focus: {
            border: "none",
            boxShadow: "none",
            outline: "none",
          },
          _active: {
            border: "none",
            boxShadow: "none",
            outline: "none",
          },
          _disabled: {
            pointerEvents: "none",
            color: "textInput",
            bg: "seperator",
            opacity: 1,
          },
        },
        icon: {
          bg: "backgroundSecondary",
          borderRadius: { base: "0.5rem", lg: "1rem" },
          border: "none",
          outline: "none",
          _focus: {
            border: "none",
            outline: "none",
          },
          _hover: {
            borderColor: "primary",
            boxShadow: "none",
          },
          h: { base: "32px", lg: "56px" },
          w: { base: "32px", lg: "56px" },
        },

        secondary: {
          color: "textPrimary",
          _light: {
            color: {
              base: "#FFFFFF",
              lg: "primary",
            },
          },
          h: { base: "32px", lg: "56px" },
          w: { base: "116px", lg: "240px" },
          fontSize: { base: "10px", lg: "1rem" },
          fontWeight: "600",
          textAlign: "center",
          bg: {
            base: "primary",
            lg: "backgroundSecondary",
          },
          _hover: {
            border: "1px",
            borderColor: "primary",
            boxShadow: "none",
            opacity: 1,
          },
        },
      },
      defaultProps: {
        variant: "primary",
      },
    },
    Table: {
      variants: {
        simple: {
          marginLeft: 0,
          tr: {
            td: {
              border: "none",
              paddingLeft: "0",
            },
            th: {
              border: "none",
              paddingLeft: "0",
            },
          },
        },
      },
    },
  },
};
