import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Center } from "@chakra-ui/react";

import { useSelector } from "react-redux";

import { TransactionType } from "../../constants/common";
import { TXN_TYPES } from "../../constants/transactions";
import {
  CLAIM_TITLE_MAPPINGS,
  UNSTAKE_TITLE_MAPPINGS,
} from "../../constants/transactionMessages";

import { getChains } from "../../config";

import { CloseIcon } from "../CloseIcon";
import { ModalDrawer } from "../ModalDrawer";
import SuccessErrorModalBodyTemplate from "../SuccessErrorModalBodyTemplate";
import ConfirmationBodyTemplate from "../UnstakeLimitExceedModal/ConfirmationBodyTemplate";
import LedgerSubscribeForm from "./LedgerSubscribeForm";
import { SD } from "../../constants/constants";

interface TransactionModalProps {
  isOpen: boolean;
  hash: string;
  error: string;
  transactionType: string;
  isTxnProcessing: boolean;
  hasUserDenied: boolean;
  daysToWaitForWithdraw?: string;
  closeAlert: () => void;
  handleTxView: () => void;
  token: string;
  network: string;
  chainId: number;
  isSafeApp?: boolean;
}

let timerId: any = undefined;

const TransactionModal = ({
  isOpen,
  hash,
  error,
  isTxnProcessing,
  hasUserDenied,
  transactionType,
  daysToWaitForWithdraw,
  token,
  network,
  chainId,
  closeAlert,
  handleTxView,
  isSafeApp = false,
}: TransactionModalProps) => {
  const [isTxnTakingMoreTime, setIsTxnTakingMoreTime] = useState(false);
  const { ledgerTxSuccessFormDetails } = useSelector(
    (state: any) => state.stake
  );

  const chainConfig = useMemo(() => {
    return getChains(token, network, chainId)[0];
  }, [token, network, chainId]);

  useEffect(() => {
    if (!isSafeApp && isTxnProcessing && hash) {
      timerId = setTimeout(() => {
        setIsTxnTakingMoreTime(true);
      }, 30000);
    }

    return () => clearTimeout(timerId);
  }, [isSafeApp, isTxnProcessing, hash]);

  const getSuccessMessage = useCallback(
    (transactionType: string) => {
      if (
        transactionType === TXN_TYPES.UNSTAKE &&
        UNSTAKE_TITLE_MAPPINGS[token?.toUpperCase()]
      ) {
        return UNSTAKE_TITLE_MAPPINGS[token?.toUpperCase()];
      } else if (
        transactionType === TXN_TYPES.CLAIM &&
        CLAIM_TITLE_MAPPINGS[token?.toUpperCase()]
      ) {
        return CLAIM_TITLE_MAPPINGS[token?.toUpperCase()];
      }
      return "Transaction Successful";
    },
    [token]
  );

  const renderContent = () => {
    if (error) {
      if (hasUserDenied) {
        return (
          <SuccessErrorModalBodyTemplate
            transactionType={TransactionType.WARNING}
            modalSubTitle="User denied transaction signature. Please try again"
            modalTitle="Transaction denied"
          />
        );
      }
      return (
        <SuccessErrorModalBodyTemplate
          transactionType={TransactionType.ERROR}
          modalTitle="Transaction Failed"
          modalSubTitle={hash ? "" : error}
          primaryBtnTxt={hash ? "View transaction" : ""}
          onSubmitPrimary={handleTxView}
        />
      );
    } else if (hash) {
      if (isTxnProcessing) {
        if (isTxnTakingMoreTime) {
          return (
            <SuccessErrorModalBodyTemplate
              transactionType={TransactionType.WARNING}
              modalTitle="Transaction in-progress"
              modalSubTitle="Your transaction is taking longer than expected"
              primaryBtnTxt={
                chainConfig.blockExplorers?.default.name || "View Transaction"
              }
              onSubmitPrimary={handleTxView}
            />
          );
        } else {
          return (
            <SuccessErrorModalBodyTemplate
              isSpinnerRequired={true}
              modalTitle="Transaction in progress"
            />
          );
        }
      } else {
        // Successful
        if (transactionType === TXN_TYPES.STAKE) {
          return (
            <ConfirmationBodyTemplate
              transactionType={TransactionType.SUCCESS}
              modalTitle="Transaction Successful"
              // modalSubTitle="You have gained access to"
              primaryBtnTxt="View transaction"
              onSubmitPrimary={handleTxView}
              token={token}
            />
          );
        } else {
          return (
            <SuccessErrorModalBodyTemplate
              transactionType={TransactionType.SUCCESS}
              modalTitle={getSuccessMessage(transactionType)}
              modalSubTitle={
                transactionType === TXN_TYPES.UNSTAKE &&
                daysToWaitForWithdraw &&
                token !== SD
                  ? `Unstaking will take ${daysToWaitForWithdraw} days. Please check request status in the withdraw section.`
                  : token === SD && transactionType === TXN_TYPES.UNSTAKE
                  ? "After the withdrawal request is processed and the unbonding period ends, you can claim your $SD from the claims tab"
                  : ""
              }
              primaryBtnTxt="View transaction"
              onSubmitPrimary={handleTxView}
            />
          );
        }
      }
    } else {
      return (
        <SuccessErrorModalBodyTemplate
          isSpinnerRequired={true}
          modalTitle="Please check your wallet"
        />
      );
    }
  };

  return (
    <ModalDrawer isOpen={isOpen} closeAlert={closeAlert}>
      {!error &&
      hash &&
      !isTxnProcessing &&
      ledgerTxSuccessFormDetails?.section_details?.title &&
      transactionType === TXN_TYPES.STAKE ? (
        <Center
          padding={{ base: "0 1rem 2.5rem", md: "2.5rem 1.5rem 1.5rem" }}
          fontWeight="600"
          flexDirection="column"
          width="100%"
        >
          {
            <>
              <CloseIcon onClose={closeAlert} />
              {renderContent()}
              <LedgerSubscribeForm
                ledgerTxSuccessFormDetails={ledgerTxSuccessFormDetails}
              />
            </>
          }
        </Center>
      ) : (
        <Center
          padding={{ base: "0 1rem 2.5rem", md: "2.5rem 3rem" }}
          fontWeight="600"
          flexDirection="column"
          width="100%"
        >
          {
            <>
              <CloseIcon onClose={closeAlert} />
              {renderContent()}
            </>
          }
        </Center>
      )}
    </ModalDrawer>
  );
};

export default TransactionModal;
