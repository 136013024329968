import { NetworkConfigType } from "../types";

// ABI
import ERC20TOKENABI from "./contracts/mainnet/ERC20.json";
import TokenXABI from "./contracts/mainnet/TokenXBal.json";
import TokenStakeManagerABI from "./contracts/mainnet/TokenXInteractionPool.json";
import KOLReferral from "./contracts/mainnet/KOLReferral.json";
import ETHTokenXABI from "./contracts/mainnet/ETHTokenXBal.json";
import ETHTokenStakeManagerABI from "./contracts/mainnet/ETHTokenXInteractionPool.json";

const providerKey = process.env.NEXT_PUBLIC_ALCHEMY_KEY_MAINNET || "";
const ETHProviderKey = process.env.NEXT_PUBLIC_ETH_ALCHEMY_KEY_MAINNET || "";

export const TestNetConfig: NetworkConfigType = {
  chainId: "0x89",
  supportedChainIDs: [137, 1],
  providerKey,
  chainName: "Polygon Mainnet",
  nativeCurrency: {
    name: "Polygon",
    symbol: "Matic",
    xsymbol: "MaticX",
    decimals: 18,
  },
  rpcUrls: {
    default: "https://polygon-mainnet.g.alchemy.com/v2",
  },
  blockExplorerUrls: ["https://polygonscan.com"],
  contractAddresses: {
    erc20: "0x0000000000000000000000000000000000001010",
    xtoken: {
      token: "0xfa68fb4628dff1028cfec22b4162fccd0d45efb6",
      stakingContract: "0xfd225C9e6601C9d38d8F98d8731BF59eFcF8C0E3",
    },
    referral: "0xa76B46b181B09cC25D02eA412e499E3afbA4c372",
  },
  abis: {
    erc20: ERC20TOKENABI,
    xtoken: {
      token: TokenXABI,
      stakingContract: TokenStakeManagerABI,
    },
    referral: KOLReferral,
  },
};

export const ETHTestNetConfig: NetworkConfigType = {
  chainId: "0x1",
  supportedChainIDs: [137, 1],
  providerKey: ETHProviderKey,
  chainName: "Ethereum Mainnet",
  nativeCurrency: {
    name: "Polygon",
    symbol: "Matic",
    xsymbol: "MaticX",
    decimals: 18,
  },
  rpcUrls: {
    default: "https://eth-mainnet.alchemyapi.io/v2",
  },
  blockExplorerUrls: ["https://etherscan.io"],
  contractAddresses: {
    erc20: "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
    xtoken: {
      token: "0xfa68fb4628dff1028cfec22b4162fccd0d45efb6",
      stakingContract: "0xD0E7baf4750710A0dcC91A0b11DC8f02504d7e7D",
    },
    referral: "0x5e3Ef299fDDf15eAa0432E6e66473ace8c13D908",
  },
  abis: {
    erc20: ERC20TOKENABI,
    xtoken: {
      token: ETHTokenXABI,
      stakingContract: ETHTokenStakeManagerABI,
    },
    referral: KOLReferral,
  },
};
