import { useEffect } from "react";
import { useChain } from "./useChain";
import { useSelector, useDispatch } from "react-redux";
import { updateStakeData } from "store";

import { useWithdraw } from "@stader-labs/web-sdk"; // from "@stader-labs/web-sdk"

export const useWithdrawals = () => {
  const dispatch = useDispatch();
  const { fetchWithdrawals } = useWithdraw();

  const { walletAddress, config } = useSelector((state: any) => state.wallet);
  const { isStaking, isUnstaking, tokenAmount, tokenXAmount } = useSelector(
    (state: any) => state.user
  );
  const { txn } = useSelector((state: any) => state.user);

  const { status } = useChain();

  const fetchClaimData = async () => {
    let claimData = [];

    if (walletAddress) {
      claimData = await fetchWithdrawals(walletAddress);
    }
    dispatch(
      updateStakeData({
        withdrawals: claimData,
      })
    );
  };

  useEffect(() => {
    fetchClaimData();
    setTimeout(() => {
      fetchClaimData();
    }, 4000);
  }, [
    walletAddress,
    txn.status,
    isStaking,
    isUnstaking,
    status,
    config,
    tokenAmount,
    tokenXAmount,
  ]);

  return null;
};
