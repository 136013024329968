import { getBNBChains, getBNBconfig } from "./bnb";
import { getETHconfig, getETHChains } from "./ether";
import { getSDconfig, getSDChains } from "./sd";
import { getPolygonChains, getPolygonConfig } from "./polygon";

import { BNB, ETH, POLYGON, SD } from "../constants/constants";

export const getConfig = (
  network: string,
  environment: string,
  chainId?: number
): any => {
  switch (network) {
    case SD:
      return getSDconfig(environment);
    case ETH:
      return getETHconfig(environment);
    case BNB:
      return getBNBconfig(environment);
    case POLYGON:
      return getPolygonConfig(environment, chainId);
    default:
      return getSDconfig(environment);
  }
};

export const getChains = (
  network: string,
  environment: string,
  chainId?: number
) => {
  switch (network) {
    case SD:
      return getSDChains(environment);
    case ETH:
      return getETHChains(environment);
    case BNB:
      return getBNBChains(environment);
    case POLYGON:
      return getPolygonChains(environment, chainId);
  }
  return getSDChains(environment);
};
