import { Connector, configureChains, createClient } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { getFantomconfig } from "../fantom";
import { getWallets } from "../helper/helper";
import { fantom, fantomTestnet } from "wagmi/chains";
import { WalletData } from "../../types/common";

const buildClient: any = (
  network: string,
  providerKey: string,
  wallets: Array<WalletData>
) => {
  // eslint-disable-next-line no-console
  console.log(providerKey);

  const config = getFantomconfig(network);

  const { chains, provider } = configureChains(
    [fantom, fantomTestnet],
    [
      jsonRpcProvider?.({
        rpc: () => ({
          http: `${config.rpcUrls.default}`,
        }),
      }),
    ]
  );

  const wagmiClient = createClient({
    autoConnect: true,
    connectors: getWallets(wallets, chains) as unknown as Connector<
      any,
      any,
      any
    >[],
    provider,
  });

  return { wagmiClient, config };
};

export default buildClient;
