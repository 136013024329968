import { useNetwork, useSwitchNetwork } from "wagmi";

export const useNetworkHook = () => {
  const { chain: activeChain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  return {
    switchNetwork,
    activeChain,
  };
};
