import { useState, useEffect } from "react";
import { useBalance } from "wagmi";
import { useInterval, useIdle } from "react-use";

// Utils
import { toFixedWithoutRounding } from "../utils/common";

// constants
import { POLYGON, SD } from "../constants/constants";
import { useSelector, useDispatch } from "react-redux";
import { updateUserData, clearUserData } from "store";

const POLLING_INTERVAL = 10000;

const initialData = {
  token: {
    symbol: "",
    balance: 0,
  },
  xtoken: {
    symbol: "",
    balance: 0,
  },
};

const blochChainToken = process.env.NEXT_PUBLIC_TOKEN || "";

const useTokenBalance = (config: any) => {
  const isIdle = useIdle(150e3);
  const { walletAddress, isConnected } = useSelector(
    (state: any) => state.wallet
  );
  const { txn } = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  const [tokenBalance, setTokenBalance] = useState(initialData);

  const tokenBalanceConst: any = {
    address: walletAddress,
    chainId: +config.chainId,
  };

  if (blochChainToken === POLYGON || blochChainToken === SD) {
    tokenBalanceConst["token"] = config.contractAddresses.erc20;
  }

  const {
    data: token,
    isLoading: isTokenLoading,
    refetch: tokenRefetch,
  } = useBalance({
    ...tokenBalanceConst,
    enabled: walletAddress,
  });

  const {
    data: xToken,
    isLoading: isXTokenLoading,
    refetch: xtokenRefetch,
  } = useBalance({
    address: walletAddress,
    token: config.contractAddresses.xtoken.token,
    chainId: +config.chainId,
    enabled: walletAddress && blochChainToken !== SD,
  });

  useEffect(() => {
    if (!isTokenLoading && !isXTokenLoading && isConnected && walletAddress) {
      if (blochChainToken !== SD) {
        dispatch(
          updateUserData({
            tokenAmount: +toFixedWithoutRounding(token?.formatted || "0", 8),
            tokenSymbol: config.nativeCurrency.symbol,
            tokenXAmount:
              blochChainToken !== SD
                ? +toFixedWithoutRounding(xToken?.formatted || "0", 8)
                : +toFixedWithoutRounding(token?.formatted || "0", 8), // TODO remove else case
            tokenXSymbol: config.nativeCurrency.xsymbol,
          })
        );
      } else {
        dispatch(
          updateUserData({
            tokenAmount: +toFixedWithoutRounding(token?.formatted || "0", 8),
            tokenSymbol: config.nativeCurrency.symbol,
            tokenXSymbol: config.nativeCurrency.xsymbol,
          })
        );
      }
    } else if (!isConnected) {
      dispatch(clearUserData());
    }
  }, [
    isTokenLoading,
    isXTokenLoading,
    token,
    xToken,
    isConnected,
    walletAddress,
    config.nativeCurrency.symbol,
    config.nativeCurrency.xsymbol,
  ]);

  useEffect(() => {
    (async function fetchBalance() {
      const [tokenBal, xtokenBal] = await Promise.all([
        tokenRefetch(),
        blochChainToken !== SD && xtokenRefetch(),
      ]);
      setTokenBalance((prev) => ({
        token: {
          ...prev.token,
          balance: +toFixedWithoutRounding(tokenBal.data?.formatted || "0", 8),
        },
        xtoken:
          xtokenBal && xtokenBal?.data
            ? {
                ...prev.xtoken,
                balance: +toFixedWithoutRounding(
                  xtokenBal?.data?.formatted || "0",
                  8
                ),
              }
            : initialData.xtoken,
      }));
    })();
  }, [tokenRefetch, txn.status, xtokenRefetch]);

  useInterval(() => {
    if (!isIdle && isConnected) {
      (async function fetchBalance() {
        const [tokenBal, xtokenBal] = await Promise.all([
          tokenRefetch(),
          blochChainToken !== SD && xtokenRefetch(),
        ]);
        setTokenBalance((prev) => ({
          token: {
            ...prev.token,
            balance: +toFixedWithoutRounding(
              tokenBal.data?.formatted || "0",
              8
            ),
          },
          xtoken:
            xtokenBal && xtokenBal?.data
              ? {
                  ...prev.xtoken,
                  balance: +toFixedWithoutRounding(
                    xtokenBal?.data?.formatted || "0",
                    8
                  ),
                }
              : initialData.xtoken,
        }));
      })();
    }
  }, POLLING_INTERVAL);

  return tokenBalance;
};

export default useTokenBalance;
