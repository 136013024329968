import { Success, Warning, Error as ErrorIcon } from "icons";
import {
  IIndexable,
  IIndexableTransaction,
  TransactionType,
} from "../types/common";

export const SD = "SD";
export const BNB = "BNB";
export const ETH = "ETH";
export const POLYGON = "POLYGON";
export const FANTOM = "FANTOM";
export const HEDERA = "HEDERA";

interface CalculatorToken {
  [key: string]: string;
}

export const SCANNERLINK: CalculatorToken = {
  SD: "/address/",
  BNB: "/address/",
  ETH: "/address/",
  POLYGON: "/address/",
  FANTOM: "/address/",
  HEDERA: "/account/",
};

export const REWARDSCALCULATORMAP: CalculatorToken = {
  SD,
  BNB,
  ETH,
  POLYGON: "Matic",
  FANTOM: "FTM",
};

export const dappName: CalculatorToken = {
  SD,
  BNB,
  ETH,
  POLYGON: "Polygon",
  FANTOM: "Fantom",
};

const getWithdrawalsDays = () => {
  const TOKEN = process.env.NEXT_PUBLIC_TOKEN;
  switch (TOKEN) {
    case BNB:
      return "7-15";
    case ETH:
      return "7-10";
    case HEDERA:
      return "1";
    case POLYGON:
      return "2-3";
  }
  return "15";
};

export const xTokenMapping: IIndexable = {
  bnbx: "BNBx",
  maticx: "MaticX",
};

export const STADER_URL = "https://staderlabs.com/";
export const AUDIT_TOOLTIP_TEXT = "Click to know more on audits";
export const TVL_TOOLTIP_TEXT = "Total SD Staked";
export const TERMS_URL = "https://staderlabs.com/terms-of-service/";
export const PRIVACY_POLICY_URL = "https://staderlabs.com/privacy-policy/";
export const TOAST_DURATION = 5000;
export const DAYS_TO_WAIT_FOR_WITHDRAW = getWithdrawalsDays();

export const WAGMIPROVIDER = "WagmiProvider";
export const HASHGRAPHPROVIDER = "HashgraphProvider";
export const NULLROVIDER = "NullProvider";

export const PROVIDERS: IIndexable = {
  POLYGON: WAGMIPROVIDER,
  FANTOM: WAGMIPROVIDER,
  BNB: WAGMIPROVIDER,
  ETH: WAGMIPROVIDER,
  HEDERA: HASHGRAPHPROVIDER,
  SD: WAGMIPROVIDER,
};

export const TransactionIconMapping: IIndexableTransaction = {
  [TransactionType.SUCCESS]: {
    icon: Success,
  },
  [TransactionType.ERROR]: {
    icon: ErrorIcon,
  },
  [TransactionType.WARNING]: {
    icon: Warning,
  },
};
