import {
  Box,
  Center,
  Text,
  Button,
  InputGroup,
  InputRightElement,
  Input,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { SyntheticEvent, useEffect, useState } from "react";
import { chainSubscribeKeyMapping, validateEmail } from "../../utils/common";
import { subscribeEmail } from "./service";
import Icon from "../../components/Icon";
import { Book } from "icons";

const LedgerSubscribeForm = ({ ledgerTxSuccessFormDetails }: any) => {
  const { section_details, subscribe_form } = ledgerTxSuccessFormDetails;

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isSubscribing, setIsSubscribing] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (email) {
      const isValidEmail = validateEmail(email);

      if (isValidEmail) {
        setError("");
      } else {
        setError("Please enter correct email ID");
      }
    }
  }, [email]);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    const token = process.env.NEXT_PUBLIC_TOKEN;
    if (!validateEmail(email)) {
      setError("Please enter correct email ID");
      return;
    }
    if (token && chainSubscribeKeyMapping[token]) {
      setIsSubscribing(true);

      const data = {
        email,
        networks: [chainSubscribeKeyMapping[token]],
      };

      const response = await subscribeEmail(data);

      if ((response as any).error) {
        toast({
          description: "Failed to subscribe",
          status: "error",
          duration: 2000,
          position: "top",
          isClosable: true,
        });
        setIsSubscribing(false);
        setEmail("");
        return;
      }

      toast({
        description: "Email subscribed successfully",
        status: "success",
        duration: 2000,
        position: "top",
        isClosable: true,
      });
      setIsSubscribing(false);
      setEmail("");
    }
  };
  return (
    <Box mt={{ base: "32px", md: "64px" }}>
      <Center gap="8px" alignItems={{ base: "flex-start", md: "center" }}>
        <Icon height="24px" width="24px" Icon={Book} color="textSecondary" />
        <Text
          color="textPrimary"
          fontSize={{ base: "14px", md: "16px" }}
          lineHeight={{ base: "20px", md: "24px" }}
          fontWeight="500"
        >
          {section_details?.title}
        </Text>
      </Center>
      <Box mt="16px" width="100%">
        <form onSubmit={handleSubmit}>
          <InputGroup
            size="lg"
            mt="0.5rem"
            mb="0"
            w={{ base: "100%", md: "512px" }}
          >
            <Input
              bg="white"
              _dark={{
                bg: "#142528",
              }}
              type="email"
              required
              placeholder={subscribe_form?.placeholder || "Email address"}
              value={email}
              fontSize="1rem"
              fontWeight="500"
              h={{ base: "58px", md: "72px" }}
              pl="2rem"
              borderRadius="12px"
              borderColor="seperator"
              onChange={(e) => setEmail(e.target.value)}
              _placeholder={{ color: "textTertiary" }}
              _hover={{
                borderWidth: "1px",
                borderColor: "seperator",
              }}
              _focus={{
                borderWidth: "1px",
                borderColor: "seperator",
              }}
              color="textTertiary"
            />

            <InputRightElement
              h={{ base: "58px", md: "72px" }}
              w="121px"
              mr="16px"
              zIndex="0"
            >
              <Button
                type="submit"
                p="12px 1.5rem"
                h="40px"
                bg="inherit"
                border="1px"
                borderColor="primary"
                _hover={{
                  bg: "inherit",
                }}
              >
                {/* Subscribe */}
                {isSubscribing ? (
                  <Spinner size="md" color="primary" />
                ) : (
                  <Text color="primary">
                    {subscribe_form?.submit_text || "Subscribe"}
                  </Text>
                )}
              </Button>
            </InputRightElement>
          </InputGroup>
          {error && (
            <Text textStyle="p14" color="#e53e3e" mt="0.5rem">
              {error}
            </Text>
          )}
        </form>
      </Box>
    </Box>
  );
};

export default LedgerSubscribeForm;
