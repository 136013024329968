import { useEffect } from "react";
import { useRouter } from "next/router";
import { Userpilot } from "userpilot";
import { useDappSDKProvider } from "dapp-sdk-v2";
import useChainvine from "@hooks/useChainvine";

const userPilotKey = process.env.NEXT_PUBLIC_USERPILOT_KEY || "";

const UserPilot = () => {
  useChainvine();
  Userpilot.initialize(userPilotKey);

  const { walletAddress, tokenAmount, firebaseEventsData } =
    useDappSDKProvider();

  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (url === router.asPath) {
        Userpilot.reload();
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    if (walletAddress) {
      if ((window as any).userpilotSettings.userid !== walletAddress) {
        Userpilot.identify(walletAddress, {
          ...firebaseEventsData,
        });
      }
    } else {
      Userpilot.anonymous();
    }
  }, [walletAddress, tokenAmount, firebaseEventsData]);

  return null;
};

export default UserPilot;
