import { BladeSigner } from "@bladelabs/blade-web3.js";
import { AccountId, ContractExecuteTransaction } from "@hashgraph/sdk";
import { config } from "../../config/hedera";
import { MessageTypes } from "hashconnect";
import { errors } from "../constants";

export class BladeService {
  private accountId: AccountId | undefined;
  private signer: BladeSigner | undefined = undefined;
  private isSessionCreated: boolean = false;
  public isExtensionInstalled: boolean = false;
  private checkCount = 0;

  setSigner() {
    this.signer = new BladeSigner();
    this.isSessionCreated = false;
  }

  getWalletData(): MessageTypes.ApprovePairing {
    const network = config.network.name;
    if (!this.signer) {
      throw new Error(errors.BLADEWALLLET_SIGNER_NOT_INIT);
    } else if (this.accountId && this.isSessionCreated) {
      //TODO: result is null. needs to be fixed
      const accountIds = [this.accountId.toString()];
      return {
        network,
        accountIds,
        metadata: {
          name: `Blade Wallet (${network})`,
          description: "Blade Wallet",
          icon: "",
        },
        topic: "",
      };
    } else {
      return {
        network,
        accountIds: [],
        metadata: {
          name: `Blade Wallet (${network})`,
          description: "Blade Wallet",
          icon: "",
        },
        topic: "",
      };
    }
  }

  async checkExtensionInstalled() {
    this.checkCount++;

    if (!(window as any).bladeConnect) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        if (this.checkCount > 10) {
          resolve(false);
        } else {
          setTimeout(async () => {
            resolve(this.checkExtensionInstalled());
          }, 500);
        }
      });
    }
    this.isExtensionInstalled = true;
    return Promise.resolve(true);
  }

  async loadWallet(): Promise<AccountId> {
    if (!this.signer) {
      throw new Error(errors.BLADEWALLLET_SIGNER_NOT_INIT);
    }
    // eslint-disable-next-line no-useless-catch
    try {
      /// Blade Signer is now ready for use.
      if (!this.isSessionCreated) {
        /// Create session with WalletExtension.
        await this.signer.createSession({
          network: config.network.bladeWallet,
        });
        this.isSessionCreated = true;
      }

      this.accountId = this.signer.getAccountId();
      return this.accountId;
    } catch (err) {
      throw err;
    }
  }

  async getBalance() {
    if (!this.signer || !this.isSessionCreated) {
      throw new Error(errors.BLADEWALLLET_SIGNER_NOT_INIT);
    }
    return this.signer.getAccountBalance();
  }

  async sendTransaction(transaction: ContractExecuteTransaction) {
    if (!this.signer) {
      throw new Error(errors.BLADEWALLLET_SIGNER_NOT_INIT);
    }
    if (!this.accountId) {
      throw new Error("Account must be set");
    }

    return await this.signer.call(transaction);
  }
}
