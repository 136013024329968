export const WALLET_MODAL = "wallet_modal";
export const COPY_ADDRESS = "Copy_Address";
export const BLOCKCHAIN_EXPLORER = "Blockchain_explorer";
export const DISCONNECT_WALLET = "Disconnect_wallet";

export const CONNECT_WALLET = "Connect_Wallet";
export const READ_TERMS_OF_SERVICES = "Read_Terms_of_services";
export const ACCEPT_TOS_CHECKBOX = "Accpet_TOS_Checkbox";

export const WALLET_CONNECTION_FAILED = "wallet_connection_failed";
export const WALLET_CONNECTED_SUCCESSFULLY = "wallet_connected_successfully";

export const STAKE_CTA = "stake_cta";
export const STAKE_SUCCESSFUL = "stake_successful";
export const STAKE_FAILED = "stake_failed";
export const MAX_STAKE = "max_stake";

export const ADD_LIQUID_TOKEN = "add_liquid_token";

export const UNSTAKE_CTA = "unstake_cta";
export const UNSTAKE_SUCCESSFUL = "unstake_successful";
export const UNSTAKE_FAILED = "unstake_failed";
export const MAX_UNSTAKE = "max_unstake";

export const WITHDRAW_CTA = "Withdraw_cta";
export const WITHDRAWAL_SUCCESSFUL = "withdrawal_successful";
export const WITHDRAWAL_FAILED = "withdrawal_failed";

export const TOKEN_APPROVE_CTA = "token_approve_cta";
export const TOKEN_ASSOCIATE_CTA = "token_associate_cta";

export const TOKEN_APPROVE_SUCCESSFUL = "token_approve_successful";
export const TOKEN_APPROVE_FAILED = "token_approve_failed";

export const TOKEN_ASSOCIATE_FAILED = "token_associate_failed";
export const TOKENX_ASSOCIATE_FAILED = "tokenx_associate_failed";

export const TOKENX_APPROVE_CTA = "tokenx_approve_cta";
export const TOKENX_APPROVE_SUCCESSFUL = "tokenx_approve_successful";
export const TOKENX_APPROVE_FAILED = "tokenx_approve_failed";
