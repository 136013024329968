import { UserRejectedRequestError } from "wagmi";

export function getConnectors(data: any) {
  return data.map((item: any) => {
    return {
      name: item.name,
      id: item.id,
      inInstalled: true,
    };
  });
}

export function isErrorRejectedByUser(error: any) {
  const errorMessage = error?.error || error?.message || "";
  const isrejectedError = [
    "metamask tx signature: user denied transaction signature.",
    "tx signature: user denied transaction signature.",
    "user rejected",
    "user rejected the transaction",
    "User canceled",
  ].some((errMessage) => errorMessage.toLowerCase().includes(errMessage));
  return error instanceof UserRejectedRequestError || isrejectedError;
}
