// import React from "react";
import { Text, Box } from "@chakra-ui/react";

import Icon from "../../components/Icon";
import FooterButtons from "../SuccessErrorModalBodyTemplate/components/FooterButtons";

import { TransactionType } from "../../constants/common";
import { TextSecondary } from "theme";
import { TransactionIconMapping } from "../../constants/constants";

interface ConfirmationBodyTemplateProps {
  modalTitle: string;
  token: string;
  modalSubTitle?: string;
  primaryBtnTxt?: string; // view transactions
  secondaryBtnTxt?: string; // close or cancel
  onSubmitPrimary?: () => void;
  onSubmitSecondary?: () => void;
  transactionType?: TransactionType;
}

const ConfirmationBodyTemplate = ({
  modalTitle,
  modalSubTitle,
  primaryBtnTxt,
  secondaryBtnTxt,
  token,
  onSubmitPrimary,
  onSubmitSecondary,
  transactionType,
}: ConfirmationBodyTemplateProps) => {
  return (
    <>
      {transactionType && (
        <Box mb="1rem" height="48px">
          <Icon
            Icon={TransactionIconMapping[transactionType].icon}
            width="48px"
            height="48px"
          />
        </Box>
      )}
      <Text
        // mt={{ base: "0", md: "2rem" }}
        fontWeight="600"
        fontSize={{
          base: "20px",
          md: "24px",
        }}
        lineHeight={{ base: "24px", md: "28px" }}
      >
        {modalTitle}
      </Text>

      {modalSubTitle && (
        <TextSecondary
          mt="0.5rem"
          fontSize={{
            base: "12px",
            md: "14px",
          }}
          fontWeight="400"
          textAlign="center"
          lineHeight={{ base: "18px", md: "20px" }}
          maxW="400px"
        >
          {modalSubTitle}
        </TextSecondary>
      )}

      {primaryBtnTxt || secondaryBtnTxt ? (
        <Box mt="1.5rem">
          <FooterButtons
            secondaryBtnTxt={secondaryBtnTxt}
            onSubmitSecondary={onSubmitSecondary}
            primaryBtnTxt={primaryBtnTxt}
            onSubmitPrimary={onSubmitPrimary}
          />
        </Box>
      ) : null}
    </>
  );
};

export default ConfirmationBodyTemplate;
