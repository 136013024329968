import { HashConnectTypes } from "hashconnect";
import { config } from "../../config/hedera";

const SAVE_KEY = `hashConnectData/${config.network.name}`;

export type WalletExtensionType = "hashpack" | "blade" | "";

export interface SaveData {
  topic: string;
  pairingString: string;
  privateKey: string;
  pairedWalletData: HashConnectTypes.WalletMetadata | null;
  pairedAccounts: string[];
  network?: string;
  id?: string;
  accountIds?: string[];
  walletExtensionType: WalletExtensionType;
}

export const INITIAL_SAVE_DATA: SaveData = {
  topic: "",
  pairingString: "",
  privateKey: "",
  pairedAccounts: [],
  pairedWalletData: null,
  walletExtensionType: "",
};

export const StorageService = {
  loadLocalData() {
    let foundData = localStorage.getItem(SAVE_KEY);

    if (foundData) {
      const saveData = JSON.parse(foundData);
      return saveData;
    } else {
      return null;
    }
  },

  saveData(saveObj: any) {
    let dataToSave = JSON.stringify(saveObj);
    localStorage.setItem(SAVE_KEY, dataToSave);
  },

  remove() {
    localStorage.removeItem(SAVE_KEY);
  },

  saveTerms(isAccepted: any) {
    localStorage.setItem("terms_accepted", `${isAccepted}`);
  },

  getTerms() {
    return localStorage.getItem("terms_accepted") === "true";
  },

  isBanxaTermsAccepted() {
    return typeof window !== "undefined"
      ? localStorage.getItem("banxa_terms_accepted") === "true"
      : false;
  },

  setBanxaTermsAccepted(isAccepted: any) {
    localStorage.setItem("banxa_terms_accepted", `${isAccepted}`);
  },
};
