import { FirebaseApp, initializeApp, getApps } from "firebase/app";
import { firebaseConfig } from "../config/firebase";
import {
  logEvent,
  getAnalytics,
  EventParams,
  AnalyticsCallOptions,
} from "firebase/analytics";

import { STADER_VERSION, VERSION } from "../constants/analytics";
// Utils
import { sentryErrorLog } from "./sentryLog";
import { BNB, ETH, POLYGON, SD, HEDERA, FANTOM } from "../constants/constants";
import { store } from "store";
import {
  WALLET_CONNECTED_SUCCESSFULLY,
  WALLET_CONNECTION_FAILED,
} from "../constants/firebase";
import { walletLabelMapping } from "../constants/walletMenuOptions";
let _fbApp: FirebaseApp | undefined = undefined;

const tokenx = process.env.NEXT_PUBLIC_XTOKEN || "";

const getFirebaseConfig = (chain: string) => {
  switch (chain) {
    case SD:
      return firebaseConfig.sdConfig;
    case ETH:
      return firebaseConfig.ethConfig;
    case BNB:
      return firebaseConfig.bnbConfig;
    case POLYGON:
      return firebaseConfig.polygonConfig;
    case HEDERA:
      return firebaseConfig.hederaConfig;
    case FANTOM:
      return firebaseConfig.fantomConfig;
  }
};

const getDappName = (chain: string) => {
  switch (chain) {
    case SD:
      return "SD";
    case ETH:
      return "Ethereum";
    case BNB:
      return "BNB";
    case POLYGON:
      return "Polygon";
    case FANTOM:
      return "Fantom";
    case HEDERA:
      return "Hedera";
  }
};

if (typeof window !== "undefined" && getApps().length <= 0) {
  _fbApp = initializeApp(
    getFirebaseConfig(process.env.NEXT_PUBLIC_TOKEN || ""),
    {
      automaticDataCollectionEnabled: true,
    }
  );
}

const getChainId = (config: any) => {
  if (config && config.chainId) {
    return +config.chainId;
  }
  if (config && config.ids) {
    return config.ids.chainId;
  }
  return "";
};

const checkWalletName = (isConnected: boolean, wallet_name: string) => {
  if (!isConnected && wallet_name === walletLabelMapping.ledgerLive) {
    return "";
  }
  return wallet_name;
};

export const trackEvent = (
  eventName: string,
  eventParams: EventParams = {},
  options?: AnalyticsCallOptions
) => {
  const { user, event, wallet } = store.getState();
  const { firebaseEventsData } = event;
  const { config, isConnected } = wallet;

  let userData = {
    tokenx,
    chainId: getChainId(config),
    ...firebaseEventsData,
    token_balance: user.tokenAmount,
    liquidtoken_balance: user.tokenXAmount,
    wallet_name: checkWalletName(isConnected, firebaseEventsData.wallet_name),
  };
  if (
    eventName === WALLET_CONNECTED_SUCCESSFULLY ||
    eventName === WALLET_CONNECTION_FAILED
  ) {
    userData = {
      ...userData,
      ...eventParams,
    };
  }
  if (user.isEmbed) {
    userData.wallet_name = walletLabelMapping.ledgerLive;
  }
  try {
    const analytics = getAnalytics(_fbApp);
    logEvent(
      analytics,
      eventName,
      {
        ...eventParams,
        dapp: getDappName(process.env.NEXT_PUBLIC_TOKEN || ""),
        [STADER_VERSION]: VERSION,
        ...userData,
      },
      options
    );

    (window as any).dataLayer?.push({
      event: eventName,
      ...eventParams,
      dapp: getDappName(process.env.NEXT_PUBLIC_TOKEN || ""),
      [STADER_VERSION]: VERSION,
      ...userData,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Error Logging Firebase ", e);
    sentryErrorLog(e);
  }

  try {
    (window as any).rudderanalytics.track(eventName, {
      ...eventParams,
      dapp: getDappName(process.env.NEXT_PUBLIC_TOKEN || ""),
      [STADER_VERSION]: VERSION,
      ...userData,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Error Logging rudderstack ", e);
    sentryErrorLog(e);
  }
};
