import { useMemo, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useSelector, useDispatch } from "react-redux";
import { useRouter } from "next/router";

import { walletLabelMapping } from "../constants/walletMenuOptions";
import { useEmbed } from "./useEmbed";
import { updateEventData } from "store";
import { VID } from "../constants/common";

export const useFirebaseEvent = (dapp?: string) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [cookies] = useCookies([VID]);
  const { walletAddress, walletName } = useSelector(
    (state: any) => state.wallet
  );

  const isEmbed = useEmbed();

  const firebaseEventsData = useMemo(() => {
    const vidObj = cookies[VID] ? { vid: cookies[VID] } : {};

    const {
      query: { utm_source, slug, referrer_id },
    } = router;

    const x: any = {
      wallet_address: walletAddress || "",
      wallet_name: walletName
        ? walletLabelMapping[walletName.toLowerCase()]
        : isEmbed
        ? "Ledger Live"
        : "",
      utm_source,
      slug,
      referrer_id,
      ...vidObj,
    };
    if (dapp) {
      x.dapp = dapp;
    }
    return x;
  }, [cookies, router, walletAddress, walletName, isEmbed, dapp]);

  useEffect(() => {
    dispatch(updateEventData({ firebaseEventsData }));
  }, [firebaseEventsData]);

  return firebaseEventsData;
};
