import { useEffect, useState } from "react";
import { store as reduxStore } from "store";

const useStoreData = () => {
  const { user, wallet } = reduxStore.getState();

  const [store, setStore] = useState<any>({ user, wallet });
  useEffect(() => {
    const unsubscribe = reduxStore.subscribe(() => {
      const newState = reduxStore.getState();
      setStore(newState);
    });

    return () => unsubscribe();
  }, []);

  return store;
};

export default useStoreData;
