import axios from "axios";
import { doc, getDoc, getFirestore } from "firebase/firestore";

export type APYData = {
  baseAPY: number;
  staderBoost: number;
};

export const getAPYData = async () => {
  const db = getFirestore();

  const snapshot = await getDoc(doc(db, "binance", "details"));

  const response = snapshot.data() as APYData;

  const data = {
    baseAPY: response.baseAPY,
    staderBoost: response.staderBoost,
  };

  return data;
};

export const logMagicStakeEvent = async (vid: string) => {
  try {
    const response = await axios.get(
      `https://magic.lol/4bbad3f1/brokers/pixel?action=7&vid=${vid}`
    );
    return response;
  } catch (error) {
    return { error };
  }
};
