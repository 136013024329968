import { HederaNetwork } from "@bladelabs/blade-web3.js/lib/src/models/blade";
import { commonConfig } from "./commonConfig";

export const mainnetConfig = {
  network: {
    name: "mainnet",
    url: "https://mainnet-public.mirrornode.hedera.com/",
    bladeWallet: HederaNetwork.Mainnet,
  },
  hbar_buy_url: "https://purchase.banxa.com/?coinType=HBAR",
  extension_url:
    "https://chrome.google.com/webstore/detail/hashpack/gjagmgiddbbciopjhllkdnddhcglnemk",
  blade_extension_url:
    "https://chrome.google.com/webstore/detail/blade-new-hedera-wallet/abogmiocnneedmmepnohnhlijcjpcifd",
  ids: {
    chainId: 295,
    tokenId: "0.0.834116",
    oldStakingContractId: "0.0.1027588",
    stakingContractId: "0.0.1412503",
    rewardsContractId: "0.0.1412524",
    oldUndelegationContractId: "0.0.1027587",
    undelegationContractId: "0.0.1412465",
  },
  comingSoon: false,
  minDeposit: 1.01 * 10 ** 8,
  maxDeposit: 999999999.9999 * 10 ** 8,
  firebaseConfig: {
    apiKey: "AIzaSyCpAi4CmKEmaonFu6uePMH9eThL5EF4c8w",
    authDomain: "stader-hedera-analytics-v0.firebaseapp.com",
    projectId: "stader-hedera-analytics-v0",
    storageBucket: "stader-hedera-analytics-v0.appspot.com",
    messagingSenderId: "874435674431",
    appId: "1:874435674431:web:93c5708fd5e132b524138a",
    measurementId: "G-93GW5D8632",
  },
  bannerText: "Current staking rewards are ~400k HBAR/epoch.",
  isLive: true,
  firebaseConfigDocument: "hederaV3",
  firebaseDefiDocument: "hedera",
  underMaintainanceURL:
    "https://hedera-api.staderlabs.com/protocol-staking-state",
  tvlURL: "https://hedera-api.staderlabs.com/tvl",
  ...commonConfig,
};
