import { NetworkConfigType } from '../types';

// ABI
import ERC20TOKENABI from './contracts/testnet/ERC20.json';
import TokenXABI from './contracts/testnet/TokenXBal.json';
import TokenStakeManagerABI from './contracts/testnet/TokenXInteractionPool.json';
import KOLReferral from './contracts/testnet/KOLReferral.json';

const providerKey = process.env.NEXT_PUBLIC_ALCHEMY_KEY || '';

export const TestNetConfig: NetworkConfigType = {
  chainId: '0x61',
  supportedChainIDs: [97],
  providerKey,
  chainName: 'Smart Chain - Testnet',
  nativeCurrency: {
    name: 'Binance',
    symbol: 'BNB',
    xsymbol: 'BNBx',
    decimals: 18,
  },
  rpcUrls: {
    default: `https://bsc-testnet.nodereal.io/v1`,
  },
  blockExplorerUrls: ['https://testnet.bscscan.com'],
  contractAddresses: {
    erc20: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    xtoken: {
      token: '0xec016C2a4d4d14EE1727BaA2110eA4fC57ef6d9F',
      stakingContract: '0xfd6E22Ea01D32F6903Be88949980eBacd0B5e708',
    },
    referral: '0x50C15cb832dcBFB9ee4b95d101608b664F06D7f7',
  },
  abis: {
    erc20: ERC20TOKENABI,
    xtoken: {
      token: TokenXABI,
      stakingContract: TokenStakeManagerABI,
    },
    referral: KOLReferral,
  },
};
