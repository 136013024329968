import React from "react";
import { Flex, Button, Show } from "@chakra-ui/react";

import { Close, Close_mobile } from "icons";

import Icon from "../../components/Icon";

interface CloseIconProps {
  onClose: () => void;
}

const CloseIcon = ({ onClose }: CloseIconProps) => {
  return (
    <Flex
      w="100%"
      textAlign="right"
      mb="2rem"
      justifyContent={{ base: "center", md: "flex-end" }}
    >
      <Button
        variant="unstyled"
        display="flex"
        p={{ base: "1.5rem 0", md: 0 }}
        height="24px"
        onClick={onClose}
        _focus={{
          boxShadow: "none",
        }}
      >
        <Show above="md">
          <Icon Icon={Close} height="24px" width="24px" />
        </Show>
        <Show below="md">
          <Icon Icon={Close_mobile} height="4px" width="40px" />
        </Show>
      </Button>
    </Flex>
  );
};

export default CloseIcon;
