import { configureStore } from "@reduxjs/toolkit";
import userReducer, {
  updateUserData,
  clearUserData,
  resetTxnData,
  updateTxnData,
  updateTransactionStatus,
  updateTxnHash,
  UserStateProps,
  initialUserState,
} from "./slices/userSlice";
import walletReducer, {
  updateWalletData,
  clearWalletData,
  WalletStateProps,
  initialWalletState,
} from "./slices/walletSlice";
import eventReducer, {
  updateEventData,
  EventStateProps,
  initialEventState,
} from "./slices/eventSlice";

import stakeReducer, {
  updateStakeData,
  clearStakeData,
  StakeStateProps,
  initialStakeState,
} from "./slices/stakeSlice";

export const store: any = configureStore({
  reducer: {
    user: userReducer,
    wallet: walletReducer,
    event: eventReducer,
    stake: stakeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: false,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export {
  updateUserData,
  clearUserData,
  resetTxnData,
  updateTxnData,
  updateTransactionStatus,
  updateTxnHash,
  updateWalletData,
  clearWalletData,
  updateStakeData,
  clearStakeData,
  updateEventData,
  initialEventState,
  initialStakeState,
  initialUserState,
  initialWalletState,
};
export type {
  EventStateProps,
  StakeStateProps,
  UserStateProps,
  WalletStateProps,
};
