import { useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { store } from "store";

export const useChain = () => {
  const { isWrongChain, switchNetwork } = useSelector(
    (state: any) => state.wallet
  );

  const toast = useToast();

  const handleSwitch = async () => {
    const { wallet } = store.getState();

    if (switchNetwork && wallet?.config) {
      try {
        switchNetwork(+wallet.config.chainId);
      } catch (error) {
        toast({
          description: `Error switching network. Try switching in the wallet app first.`,
          status: "error",
          duration: 5000,
          position: "top",
        });
      }
    }
  };

  return { status: !isWrongChain, switchChain: handleSwitch };
};
