import React from "react";
import { Flex, Button } from "@chakra-ui/react";

interface FooterButtonProps {
  primaryBtnTxt?: string; // view transactions
  secondaryBtnTxt?: string; // close or cancel
  onSubmitPrimary?: () => void;
  onSubmitSecondary?: () => void;
  isPrimaryDisabled?: boolean;
}

const FooterButtons = ({
  primaryBtnTxt,
  secondaryBtnTxt,
  onSubmitPrimary,
  onSubmitSecondary,
  isPrimaryDisabled = false,
}: FooterButtonProps) => {
  return (
    <Flex
      flexDirection={{
        base: "column-reverse",
        md: "row",
      }}
      alignItems="center"
    >
      {secondaryBtnTxt && onSubmitSecondary && (
        <Button
          variant="unstyled"
          p="12px 45px"
          bg="backgroundSecondary"
          color="primary"
          _dark={{
            color: "white",
          }}
          border="1px"
          borderColor="seperator"
          onClick={onSubmitSecondary}
          mr={{
            base: "0rem",
            md: "1rem",
          }}
          mt={{
            base: "1rem",
            md: "0rem",
          }}
          borderRadius="0.5rem"
          lineHeight="0"
          _hover={{
            borderColor: "primary",
          }}
          fontSize="16px"
          w="fit-content"
          h="48px"
        >
          {secondaryBtnTxt}
        </Button>
      )}
      {primaryBtnTxt && onSubmitPrimary && (
        <Button
          p="12px 45px"
          bg="primary"
          color="white"
          _dark={
            {
              // color: "#142528",
              // bg: "white",
            }
          }
          borderRadius="0.5rem"
          onClick={onSubmitPrimary}
          fontSize="16px"
          h="48px"
          isDisabled={isPrimaryDisabled}
        >
          {primaryBtnTxt}
        </Button>
      )}
    </Flex>
  );
};

export default FooterButtons;
