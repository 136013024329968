import { useCallback, useState, useEffect } from "react";
import { Box, Button, Spinner, useMediaQuery } from "@chakra-ui/react";

import AccountModal from "../AccountModal";
import WalletInfo from "../WalletInfo";

import {
  ACCEPT_TOS_CHECKBOX,
  BLOCKCHAIN_EXPLORER,
  CONNECT_WALLET,
  COPY_ADDRESS,
  DISCONNECT_WALLET,
  READ_TERMS_OF_SERVICES,
  WALLET_MODAL,
} from "../../constants/firebase";
import { useSelector, useDispatch } from "react-redux";
// import { useFirebaseEvent } from "../../hooks/useFirebaseEvent";
import useDomainResolution from "../../hooks/useDomainResolution";
import { EVENTS } from "../../constants/events";
import { updateStakeData, updateWalletData } from "store";
import WalletConnectModal from "../WalletConnectModal";
import { TERMS_URL } from "../../constants/common";
import { trackEvent } from "../../utils/firebase";
import WalletMessageModal from "../WalletMessageModal";
import { useChain } from "../../hooks/useChain";
import { emitEvent } from "../../utils/common";
import { SCANNERLINK } from "../../constants/constants";

const token = process.env.NEXT_PUBLIC_TOKEN || "";

const HeaderWallletConnect = () => {
  const [isMobileOrTablet] = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const { firebaseEventsData } = useSelector((state: any) => state.event);
  const {
    isLoading,
    isConnected,
    connectorID,
    walletAddress,
    connectors,
    walletModal,
    isWrongChain,
  } = useSelector((state: any) => state.wallet);
  const { blockExplorerURL, modalType, isWalletModalOpen } = useSelector(
    (state: any) => state.stake
  );

  const { isEmbed, isSafeApp } = useSelector((state: any) => state.user);
  // const firebaseEventsData = useFirebaseEvent();

  const domain = useDomainResolution();

  const { switchChain } = useChain();

  const [isOpenAccountModal, setIsOpenAccountModal] = useState(false);

  const tractFirebaseEvent = useCallback(
    (eventName: string, eventParams = {}) => {
      trackEvent(eventName, { ...firebaseEventsData, ...eventParams });
    },
    [firebaseEventsData]
  );

  const handleConnectWallet = useCallback(() => {
    tractFirebaseEvent(CONNECT_WALLET, { wallet_cta: "top right" });

    dispatch(
      updateStakeData({
        modalType: "CONNECT_WALLET",
        isWalletModalOpen: isMobileOrTablet ? true : false,
      })
    );
  }, [dispatch, isMobileOrTablet, tractFirebaseEvent]);

  const onCloseAccountModal = useCallback((isWalletDisconnect: boolean) => {
    setIsOpenAccountModal(false);
    if (isWalletDisconnect) {
      tractFirebaseEvent(DISCONNECT_WALLET);
      emitEvent(EVENTS.HANDLE_DISCONNECT);
    }
  }, []);

  const handleCloseModal = () => {
    dispatch(
      updateStakeData({
        modalType: "",
      })
    );
  };

  const onToggleChangeTermsAndCondition = useCallback((isChecked: boolean) => {
    trackEvent(ACCEPT_TOS_CHECKBOX, {
      checked: isChecked,
    });
  }, []);

  const onClickTermsPage = useCallback(() => {
    trackEvent(READ_TERMS_OF_SERVICES, {
      ...firebaseEventsData,
    });
  }, [firebaseEventsData]);

  const onWalletConnect = useCallback(
    (id: string) => {
      const connector = connectors.find(
        (connector: any) => connector.id === id
      );
      if (connector) {
        emitEvent("handleConnect", connector);
      }
    },
    [connectors]
  );

  useEffect(() => {
    const elements = [
      document.querySelector("html"),
      document.querySelector(" body"),
    ];
    if (!isWalletModalOpen) {
      elements.forEach((element) => element?.classList.add("enableScroll"));
    } else {
      elements.forEach((element) => element?.classList.remove("enableScroll"));
    }
  }, [isWalletModalOpen]);

  const closeWalletMessageModal = () => {
    dispatch(
      updateWalletData({
        walletModal: {
          isVisible: false,
          error: "",
        },
      })
    );
  };

  // const openWalletMessageModalWithError = useCallback(
  //   (error: string) => {
  //     setWalletData((prev) => ({
  //       ...prev,
  //       isVisible: true,
  //       error: error,
  //     }));
  //   },
  //   [setWalletData]
  // );

  // const openWalletMessageModal = useCallback(
  //   (isWrongChain: boolean = false) => {
  //     setWalletData((prev) => ({
  //       ...prev,
  //       isVisible: true,
  //       isWrongChain: isWrongChain,
  //     }));
  //   },
  //   [setWalletData]
  // );

  return (
    <>
      <Box ml="1rem">
        {isLoading && (
          <Button
            variant="secondary"
            onClick={() => {}}
            borderRadius="0.5rem"
            disabled
          >
            <Spinner size="md" color="primary" />
          </Button>
        )}
        {!isLoading &&
          (isConnected ? (
            <WalletInfo
              walletAddress={walletAddress}
              connectorId={connectorID ? connectorID.toLowerCase() : ""}
              onCopyAddress={() => tractFirebaseEvent(COPY_ADDRESS)}
              onClick={(isAccountModalVisible) => {
                setIsOpenAccountModal(isAccountModalVisible);
                tractFirebaseEvent(WALLET_MODAL);
              }}
              domain={domain}
            />
          ) : (
            <>
              <Button
                variant="secondary"
                onClick={handleConnectWallet}
                borderRadius="0.5rem"
              >
                Connect wallet
              </Button>
              <WalletConnectModal
                isOpen={
                  modalType === "CONNECT_WALLET" &&
                  !isConnected &&
                  !isWalletModalOpen
                }
                closeModal={handleCloseModal}
                onTermsSelectChange={onToggleChangeTermsAndCondition}
                onTermsLinkClick={onClickTermsPage}
                termsPageLink={TERMS_URL}
                onConnectWallet={onWalletConnect}
                connectorsList={connectors.map(
                  (connector: any) => connector.id
                )}
              />
            </>
          ))}
      </Box>

      {walletModal.isVisible && (
        <WalletMessageModal
          isOpen={walletModal.isVisible}
          closeModal={closeWalletMessageModal}
          error={walletModal.error}
          isWrongChain={isWrongChain}
          onSubmitPrimary={switchChain}
          isEmbed={isEmbed || isSafeApp}
        />
      )}

      {isOpenAccountModal && (
        <AccountModal
          isOpen={isOpenAccountModal}
          onCancel={onCloseAccountModal}
          onCopyAddress={() =>
            tractFirebaseEvent(COPY_ADDRESS, { type: "wallet_modal" })
          }
          scannerLink={`${blockExplorerURL}${SCANNERLINK[token]}${walletAddress}`}
          onClickScanner={() => tractFirebaseEvent(BLOCKCHAIN_EXPLORER)}
          walletAddress={walletAddress}
          domain={domain}
          connectorId={connectorID ? connectorID.toLowerCase() : ""}
        />
      )}
    </>
  );
};

export default HeaderWallletConnect;
