import { useContext } from "react";

// Context
import { DappSDKContext } from "../providers/DappSDKProvider";

export const useDappSDKProvider = () => {
  const data = useContext(DappSDKContext);

  return {
    ...data,
  };
};
