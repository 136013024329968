import { HederaNetwork } from "@bladelabs/blade-web3.js/lib/src/models/blade";
import { commonConfig } from "./commonConfig";
export const testnetConfig = {
  network: {
    name: "testnet",
    url: "https://testnet.mirrornode.hedera.com/",
    bladeWallet: HederaNetwork.Testnet,
  },
  hbar_buy_url: "https://purchase.banxa.com/?coinType=HBAR",
  extension_url:
    "https://chrome.google.com/webstore/detail/hashpack/gjagmgiddbbciopjhllkdnddhcglnemk",
  blade_extension_url:
    "https://chrome.google.com/webstore/detail/blade-new-hedera-wallet/abogmiocnneedmmepnohnhlijcjpcifd",
  ids: {
    chainId: 295,
    tokenId: "0.0.48609243",
    oldStakingContractId: "0.0.1027588",
    stakingContractId: "0.0.48609256",
    rewardsContractId: "0.0.48609257",
    undelegationContractId: "0.0.48609248",
    oldUndelegationContractId: "0.0.1027587",
  },
  comingSoon: false,
  minDeposit: 0.01 * 10 ** 8,
  maxDeposit: 999999999.9999 * 10 ** 8,
  firebaseConfig: {
    apiKey: "AIzaSyCpAi4CmKEmaonFu6uePMH9eThL5EF4c8w",
    authDomain: "stader-hedera-analytics-v0.firebaseapp.com",
    projectId: "stader-hedera-analytics-v0",
    storageBucket: "stader-hedera-analytics-v0.appspot.com",
    messagingSenderId: "874435674431",
    appId: "1:874435674431:web:84598ddd6a649ee124138a",
    measurementId: "G-E5202G3DHL",
  },
  bannerText: "Testnet - Rewards till August 7 are 600k HBAR/Day",
  isLive: true,
  firebaseConfigDocument: "hederaV3",
  firebaseDefiDocument: "hedera",
  underMaintainanceURL:
    "https://hedera-api.staderlabs.com/protocol-staking-state",
  tvlURL: "https://hedera-api.staderlabs.com/tvl",
  ...commonConfig,
};
