import { createSlice } from "@reduxjs/toolkit";

interface connectorsProps {
  id: string;
  name: string;
}

export interface WalletStateProps {
  isLoading: boolean;
  walletAddress: string | null;
  walletName: string | null;
  walletType: string | null;
  isConnected: boolean;
  isWrongChain: boolean;
  connectors: connectorsProps[];
  connectorID: string | null;
  provider: any;
  switchNetwork: any;
  activeConnector: any;
  config: any;
  walletModal: {
    isVisible: boolean;
    error: string | null;
  };
  isChecked: boolean;
  connectKitObj: any;
}

export const initialWalletState: WalletStateProps = {
  isLoading: true,
  walletAddress: null,
  walletName: null,
  walletType: null,
  isConnected: false,
  isWrongChain: false,
  connectors: [],
  provider: null,
  connectorID: null,
  switchNetwork: null,
  activeConnector: null,
  config: null,
  walletModal: {
    isVisible: false,
    error: null,
  },
  isChecked: false,
  connectKitObj: null,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState: initialWalletState,
  reducers: {
    updateWalletData: (state: any, action: any) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
    clearWalletData: (state: any) => {
      for (const [key, value] of Object.entries(initialWalletState)) {
        if (!["connectors", "isLoading", "connectKitObj"].includes(key)) {
          state[key] = value;
        }
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateWalletData, clearWalletData }: any = walletSlice.actions;
export default walletSlice.reducer;
