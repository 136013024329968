import axios from "axios";

interface subscribeToEmailProps {
  email: string;
  networks: string[];
}

export const subscribeEmail = async (data: subscribeToEmailProps) => {
  return axios.post("https://common.staderlabs.com/subscriptions/save", data);
};
