import React, { useCallback } from "react";
import { Box, useColorModeValue, useToast } from "@chakra-ui/react";

import { DocumentsFileCopy } from "icons";

import Icon from "../../components/Icon";
import { copyToClipboard } from "../../utils/common";

interface CopyIconProps {
  address?: string;
  onClick?(): void;
}

const CopyIcon = ({ address, onClick }: CopyIconProps) => {
  const toast = useToast();
  const copyIconColor = useColorModeValue("primary", "textPrimary");

  const handleAddressCopy = useCallback(() => {
    if (!address) return;
    copyToClipboard(address);
    onClick && onClick();
    toast({
      description: "Address Copied",
      status: "success",
      duration: 2000,
      position: "top",
      isClosable: false,
    });
  }, [address, onClick, toast]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        handleAddressCopy();
      }}
    >
      <Box w={{ base: "16px", lg: "24px" }} h={{ base: "16px", lg: "24px" }}>
        <Icon
          width="100%"
          height="100%"
          color={copyIconColor}
          Icon={DocumentsFileCopy}
        />
      </Box>
    </div>
  );
};

export default CopyIcon;
