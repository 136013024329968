import { formatEther } from "@ethersproject/units";
import {
  Accel,
  Accomplice,
  BlockchainPartner,
  Certora,
  CodeArena,
  CoinbaseVenture,
  Consensys,
  Forta,
  GoldenTree,
  Halborn,
  HuobiVentures,
  Immunebytes,
  Immunefi,
  JumpCapital,
  Pantera,
  Peckshield,
  Proof,
  Sigmaprime,
  TrueVentures,
} from "icons";
import { resolve } from "url";

import { IIndexable } from "@customtypes/common";

export const shortenAddress = (
  address: string,
  width: undefined | number = undefined,
  startingAddressLetters = 10,
  endingAddressLetters = 4
) => {
  let startAddressLetters = startingAddressLetters;
  let endAddressLetters = endingAddressLetters;
  if (width) {
    if (width < 235) {
      startAddressLetters = 6;
      endAddressLetters = 3;
    }
  }
  return `${address.substring(0, startAddressLetters)}...${address.substring(
    address.length - endAddressLetters
  )}`;
};

export const copyToClipboard = (value: any) =>
  navigator.clipboard.writeText(value);

export const toFixedWithoutRounding = (value: string, decimals: number) =>
  (value.match(new RegExp(`^-?\\d+(?:.\\d{0,${decimals}})?`)) as string[])[0];

export const isNumber = (str: string) => {
  if (str.trim() === "") {
    return false;
  }
  return !isNaN(+str);
};

export const formatEtherNumber = (value: string | undefined, decimals = 4) => {
  if (!value) {
    return -1;
  }
  const number = toFixedWithoutRounding(
    formatEther(value.toString()),
    decimals
  );

  return +number;
};

export const isFloat = (n: number) => {
  return Number(n) === n && n % 1 !== 0;
};

export const toStringPrecision = (num: number) => {
  let res = "";

  if (Math.abs(num) < 1.0) {
    const e = parseInt(num.toString().split("e-")[1]);
    if (e) {
      num *= Math.pow(10, e - 1);
      res = "0." + new Array(e).join("0") + num.toString().substring(2);
    }
  } else {
    let e = parseInt(num.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      num /= Math.pow(10, e);
      res = num + new Array(e + 1).join("0");
    }
  }
  res = res.includes(".") ? Number(res).toFixed(8) : res;
  return res || num.toString();
};

export const validateEmail = (email: string) => {
  const emailReg = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  return emailReg.test(email);
};

export const numberToCurrency = (value: string) => {
  return Math.abs(Number(value)) >= 1.0e9
    ? +(Math.abs(Number(value)) / 1.0e9).toFixed(2) + "B"
    : Math.abs(Number(value)) >= 1.0e6
    ? +(Math.abs(Number(value)) / 1.0e6).toFixed(2) + "M"
    : Math.abs(Number(value)) >= 1.0e3
    ? +(Math.abs(Number(value)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(value)).toString();
};

export const currencyToNumber = (value: string) => {
  if (!value) {
    return 0;
  }
  const c = value.slice(-1).toUpperCase();
  switch (c) {
    case "K":
      return +value.slice(0, -1) * 10e3;
    case "M":
      return +value.slice(0, -1) * 10e6;
    case "B":
      return +value.slice(0, -1) * 10e9;

    default:
      +value;
  }
};

export const chainSubscribeKeyMapping: IIndexable = {
  BNB: "BNB",
  ETH: "Ethereum",
  SD: "SD",
  POLYGON: "Polygon",
  HEDERA: "Hedera",
  FANTOM: "Fantom",
  NEAR: "Near",
  TERRA: "Terra 2.0",
};

export const getPartnersIcon = (key: string) => {
  switch (key) {
    case "pantera":
      return Pantera;
    case "coinbase_venture":
      return CoinbaseVenture;
    case "jump_capital":
      return JumpCapital;
    case "blockchain_partner":
      return BlockchainPartner;
    case "accel":
      return Accel;
    case "true_ventures":
      return TrueVentures;
    case "proof":
      return Proof;
    case "accomplice":
      return Accomplice;
    case "golden_tree":
      return GoldenTree;
    case "huobi_ventures":
      return HuobiVentures;
  }
};

export const getAuditsIcon = (key: string) => {
  switch (key) {
    case "halborn":
      return Halborn;
    case "peckshield":
      return Peckshield;
    case "immunefi":
      return Immunefi;
    case "forta":
      return Forta;
    case "certora":
      return Certora;
    case "immunebytes":
      return Immunebytes;
    case "consensys":
      return Consensys;
    case "sigmaprime":
      return Sigmaprime;
    case "codearena":
      return CodeArena;
  }
};

export const getQueryString = (obj: any) => {
  return new URLSearchParams(obj).toString();
};

export const getBannerImage = (TOKEN: string) => {
  switch (TOKEN) {
    case chainSubscribeKeyMapping.BNB:
      return "/assets/images/banner_marketing/banner_bnb.webp";
    case chainSubscribeKeyMapping.Polygon:
      return "/assets/images/banner_marketing/banner_polygon.webp";
    case "ETH":
      return "/assets/images/banner_marketing/banner_eth.webp";
    default:
      return "/assets/images/banner_marketing/banner_polygon.webp";
  }
};

export const prepareNavData = (_navData: any, tvl: any = undefined) => {
  let networkWithoutOrder;
  const networkWithOrder = _navData.navItems
    .find((item: any) => item.option_id === "networks")
    .details.filter((item: any) => +item.order > 0)
    .sort((a: any, b: any) => a.order - b.order);

  const _networkWithoutOrder = _navData.navItems
    .find((item: any) => item.option_id === "networks")
    .details.filter((item: any) => !item.order);

  if (tvl) {
    networkWithoutOrder = _networkWithoutOrder.sort(
      (a: any, b: any) =>
        (tvl as any)?.[b.dataKey].usd - (tvl as any)?.[a.dataKey].usd
    );
  } else {
    networkWithoutOrder = _networkWithoutOrder;
  }

  const networks = [...networkWithOrder, ...networkWithoutOrder];

  const formattedNavItems = _navData.navItems.map((item: any) => {
    if (item.option_id === "networks") {
      return { ...item, details: networks };
    }
    return item;
  });
  const navData = { ..._navData, navItems: formattedNavItems };

  return { navData, networks };
};

export const truncate = (num: number, places: number) => {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
};

export const getBaseUrl = () => {
  const subRoute = process.env.NEXT_PUBLIC_SUB_ROUTE || "";
  let baseUrl = `${subRoute ? subRoute + "/" : "/"}`;

  if (typeof document !== "undefined") {
    const { pathname } = window.location;
    const ipfsMatch = /.*\/Qm\w{44}\//.exec(pathname);

    baseUrl = ipfsMatch ? ipfsMatch[0] : baseUrl;
  }

  return baseUrl;
};

export const getLandingUrl = () => {
  let baseUrl = "/";

  if (typeof document !== "undefined") {
    const { pathname } = window.location;
    const ipfsMatch = /.*\/Qm\w{44}\//.exec(pathname);

    baseUrl = ipfsMatch ? ipfsMatch[0] : baseUrl;
  }

  return baseUrl;
};

export const getUrlWithSubRoute = (href = "/") => {
  let baseUrl = href || "";

  if (baseUrl.startsWith("/")) {
    baseUrl = resolve(getBaseUrl(), baseUrl);
  }

  return baseUrl;
};

export const documentBaseURILoaded = () => {
  let result = false;

  if (typeof document !== "undefined") {
    result = !!document.baseURI;
  }
  return result;
};
