import React from "react";

import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { Icon, Spinner } from "@chakra-ui/react";

import { IIndexable } from "../../constants/common";

export const enum TXN_TYPE {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export const iconMapping: IIndexable = {
  PENDING: <Spinner size="xl" color="primary" />,
  SUCCESS: <Icon as={AiFillCheckCircle} color="#38A169" fontSize="5rem" />,
  ERROR: <Icon as={AiFillCloseCircle} color="#E53E3E" fontSize="5rem" />,
};
