import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useToast, Text } from "@chakra-ui/react";

import {
  useConnect,
  useNetwork,
  useAccount,
  useDisconnect,
  useSwitchNetwork,
  useSigner,
} from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import {
  updateWalletData,
  clearWalletData,
  clearUserData,
  resetTxnData,
  updateTxnData,
  updateTransactionStatus,
  updateTxnHash,
  updateStakeData,
  clearStakeData,
  updateUserData,
  store,
} from "store";
import { EVENTS, TRANSACTION_TYPE } from "../constants/events";
import useTokenBalance from "../hooks/useTokenBalance";
import { useEmbed } from "../hooks/useEmbed";
import { TXN_STATUSES, TXN_TYPES } from "../constants/transactions";
import { APPROVAL_AMOUNT } from "../constants/contract";
import { trackEvent } from "../utils/firebase";
import {
  MAX_STAKE,
  MAX_UNSTAKE,
  STAKE_CTA,
  STAKE_FAILED,
  STAKE_SUCCESSFUL,
  UNSTAKE_CTA,
  UNSTAKE_FAILED,
  UNSTAKE_SUCCESSFUL,
  WALLET_CONNECTED_SUCCESSFULLY,
  WALLET_CONNECTION_FAILED,
  WITHDRAWAL_FAILED,
  WITHDRAWAL_SUCCESSFUL,
  WITHDRAW_CTA,
} from "../constants/firebase";
import {
  TOKENTYPE,
  useStake,
  useUnstake,
  useWithdraw,
  useAllowance,
} from "@stader-labs/web-sdk";
import { isErrorRejectedByUser } from "./utils";
import Icon from "../components/Icon";
import { ErrorToast, SuccessCheck } from "icons";
import {
  BNB,
  DAYS_TO_WAIT_FOR_WITHDRAW,
  POLYGON,
  SD,
} from "../constants/constants";
import TransactionModal from "../components/TransactionModal";
import { logMagicStakeEvent } from "./bnb";
import { useCookies } from "react-cookie";
import { VID } from "../constants/common";
import { walletLabelMapping } from "../constants/walletMenuOptions";
import Referral from "../components/Referral";
import useReferral from "../hooks/useReferral";
import useAutoConnect from "../hooks/useAutoConnect";
import { useRouter } from "next/router";
import { ReferralUserType } from "../types/common";
import { getNativeChain } from "../utils/common";
import { TransactionResponse } from "@ethersproject/providers";
import { useModal } from "connectkit";
import useQueryReferral from "../hooks/useQueryReferral";

const token = process.env.NEXT_PUBLIC_TOKEN || "";
const network = process.env.NEXT_PUBLIC_NETWORK || "0";

interface ServicesProps {
  config: any;
}

export interface TxnErrorType {
  hasUserDenied: boolean;
  promptError: string;
}

const initialTxnError = {
  hasUserDenied: false,
  promptError: "",
};

const Services = ({ config }: ServicesProps) => {
  const toast = useToast();
  const [cookies] = useCookies([VID]);

  const { withdraw: withdrawToken } = useWithdraw();
  const { approve: approveToken } = useAllowance();
  const { stake: stakeToken } = useStake();
  const { unstake: unstakeToken } = useUnstake();
  const stakeReferralId = useQueryReferral();

  const { isConnected, address, connector: activeConnector } = useAccount();

  const { emitter } = useSelector((state: any) => state.event);
  const { isNativeChain, txn } = useSelector((state: any) => state.user);

  const { stakeAmount, unstakeAmount, txnLoader, isWalletModalOpen } =
    useSelector((state: any) => state.stake);
  const { isChecked } = useSelector((state: any) => state.wallet);

  const { open, setOpen } = useModal();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isConnected) {
      setOpen(false);
    }
  }, [isConnected, setOpen]);

  useEffect(() => {
    if (isWalletModalOpen) setOpen(true);
  }, [isWalletModalOpen, setOpen]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        dispatch(
          updateStakeData({
            isWalletModalOpen: false,
            modalType: "",
          })
        );
      }, 2000);
    }
  }, [open, dispatch]);

  useEffect(() => {
    if (open) {
      const connectorSection = document.querySelectorAll(
        '#__CONNECTKIT__ div[role="dialog"] > div:last-child > div:last-child > div:last-child > div > div > div > div:first-child'
      );

      const connectorSectionElement = Array.from(connectorSection)[0];
      if (isChecked) {
        connectorSectionElement?.classList.add("enable");
      } else {
        connectorSectionElement?.classList.remove("enable");
      }
    } else {
      dispatch(
        updateWalletData({
          isChecked: false,
        })
      );
    }
  }, [open, isChecked, dispatch]);

  useEffect(() => {
    const elements = [
      document.querySelector("html"),
      document.querySelector(" body"),
    ];
    if (!open) {
      elements.forEach((element) => element?.classList.add("enableScroll"));
    } else {
      elements.forEach((element) => element?.classList.remove("enableScroll"));
    }
  }, [open]);

  const { switchNetwork } = useSwitchNetwork();

  const { isEmbed, isSafeApp } = useEmbed();

  const { connect, connectors } = useConnect({
    onError(error, data) {
      trackEvent(WALLET_CONNECTION_FAILED, {
        wallet_name: data?.connector
          ? walletLabelMapping[data.connector.id.toLowerCase()]
          : "",
        reason: error.message,
      });

      dispatch(
        updateWalletData({
          walletModal: {
            isVisible: true,
            error: error.message,
          },
        })
      );

      dispatch(
        updateStakeData({
          modalType: "",
          isWalletModalOpen: false,
        })
      );
    },
    onSuccess(data) {
      trackEvent(WALLET_CONNECTED_SUCCESSFULLY, {
        wallet_name: data?.connector
          ? walletLabelMapping[data.connector.id.toLowerCase()]
          : "",
        wallet_address: data?.account,
      });
    },
    onSettled() {
      dispatch(
        updateWalletData({
          isVisible: true,
          error: null,
        })
      );
    },
  });
  const { disconnect } = useDisconnect();

  const { chain } = useNetwork();
  useTokenBalance(config);
  const { data: signer } = useSigner();

  const [txError, setTxError] = useState<TxnErrorType>(initialTxnError);
  const transactionWaitRef = useRef(false);

  useAutoConnect();

  useEffect(() => {
    dispatch(
      updateUserData({
        isEmbed,
        isSafeApp,
      })
    );
  }, [dispatch, isEmbed, isSafeApp]);

  const iframeChain = useMemo(() => {
    if ((isEmbed || isSafeApp) && chain) {
      return chain.id;
    }
    return null;
  }, [isEmbed, isSafeApp, chain]);

  useEffect(() => {
    if (iframeChain && config.supportedChainIDs.includes(iframeChain)) {
      setTimeout(() => {
        dispatch(
          updateUserData({
            isNativeChain: getNativeChain(token, iframeChain),
          })
        );
      }, 2000);
    }
  }, [config.supportedChainIDs, dispatch, iframeChain]);

  useEffect(() => {
    if (activeConnector && isConnected && chain) {
      dispatch(
        updateWalletData({
          walletAddress: address,
          walletName: activeConnector.name,
          connectorID: activeConnector.id,
          isConnected: isConnected,
          // isWrongChain: checkWrongChain(activeConnector.chains, chain),
          switchNetwork: switchNetwork,
          activeConnector: activeConnector,
          isWrongChain: chain.id !== +config.chainId,
          walletModal: {
            isVisible: chain.id !== +config.chainId,
            error: null,
          },
        })
      );
    }
  }, [
    activeConnector,
    address,
    isConnected,
    chain,
    config,
    isNativeChain,
    isEmbed,
    dispatch,
    switchNetwork,
  ]);

  useEffect(() => {
    if (isConnected && activeConnector) {
      trackEvent(WALLET_CONNECTED_SUCCESSFULLY, {
        wallet_name: activeConnector
          ? walletLabelMapping[activeConnector?.id.toLowerCase()]
          : "",
        wallet_address: address,
      });
    }
  }, [isConnected, activeConnector]);

  const initiateNewTxn = useCallback(() => {
    transactionWaitRef.current = false;
    setTxError(initialTxnError);
    dispatch(updateStakeData({ txnLoader: true }));
    dispatch(resetTxnData());
  }, [dispatch]);

  const resetTxns = useCallback(() => {
    dispatch(updateStakeData({ txnLoader: false }));
  }, [dispatch]);

  const updateTransactionDetails = useCallback(
    (
      txn: TransactionResponse,
      type: string,
      amount: string | null,
      token: string,
      id: number | null = null
    ) => {
      dispatch(
        updateTxnData({
          txn: {
            original: txn,
            hash: txn.hash,
            status: TXN_STATUSES.PENDING,
            amount,
            token,
            type,
            id,
          },
        })
      );
    },
    [dispatch]
  );

  const setTxnError = useCallback(
    (error: string, hasUserDenied = false, custmError?: string) => {
      setTxError({
        hasUserDenied: hasUserDenied,
        promptError: error,
      });
      if (!txnLoader) {
        let errorMsg = hasUserDenied ? "User rejected request" : error;
        if (custmError) {
          errorMsg = custmError;
        }
        toast({
          description: (
            <Text fontWeight={600} fontSize="16px">
              {errorMsg}
            </Text>
          ),
          status: "error",
          icon: <Icon Icon={ErrorToast} width="24px" height="24px" />,
          duration: 5000,
          position: "top",
          isClosable: false,
        });
      }
    },
    [toast, txnLoader]
  );

  interface handleClaimProps {
    id: number;
    amount: string;
  }
  const handleClaim = async ({ id, amount }: handleClaimProps) => {
    trackEvent(WITHDRAW_CTA, {
      withdraw_amount: amount,
    });

    initiateNewTxn();
    if (address && signer) {
      withdrawToken(address, signer, id, {
        topic: "",
        isNativeChain: true,
        connectorID: "",
      })
        .then((response: TransactionResponse) => {
          updateTransactionDetails(
            response,
            TXN_TYPES.CLAIM,
            amount,
            config.nativeCurrency.xsymbol,
            id
          );
        })
        .catch((error: any) => {
          trackEvent(WITHDRAWAL_FAILED, {
            reason: isErrorRejectedByUser(error)
              ? "user rejected"
              : error?.error || error?.message,
          });
          setTxnError(
            error?.error || error?.message,
            isErrorRejectedByUser(error)
          );
        });
    }
  };

  const handleApproveToken = (errorMessages: any) => {
    initiateNewTxn();
    if (address && signer) {
      dispatch(updateStakeData({ approveTokenLoading: true }));

      approveToken(address, signer, APPROVAL_AMOUNT, TOKENTYPE.TOKEN, {
        topic: "",
        isNativeChain: true,
        connectorID: "",
      })
        .then((result: TransactionResponse) => {
          updateTransactionDetails(
            result,
            TXN_TYPES.ERC20_APPROVE,
            null,
            config.nativeCurrency.symbol
          );
        })
        .catch((error: any) => {
          setTxnError(
            error?.error || error?.message,
            isErrorRejectedByUser(error),
            errorMessages["stakeDenied"] || token !== SD
              ? "Staking denied"
              : "Delegation denied"
          );
          resetTxns();
        })
        .finally(() => {
          dispatch(updateStakeData({ approveTokenLoading: false }));
        });
    }
  };

  const handleApproveTokenX = async (errorMessages: any) => {
    initiateNewTxn();
    if (address && signer) {
      dispatch(updateStakeData({ approveTokenXLoading: true }));
      approveToken(address, signer, APPROVAL_AMOUNT, TOKENTYPE.TOKENX, {
        topic: "",
        isNativeChain: true,
        connectorID: "",
      })
        .then((result: TransactionResponse) => {
          updateTransactionDetails(
            result,
            TXN_TYPES.XTOKEN_APPROVE,
            null,
            config.nativeCurrency.symbol
          );
        })
        .catch((error: any) => {
          setTxnError(
            error.error || error.message,
            isErrorRejectedByUser(error),
            errorMessages["unstakeDenied"] || token !== SD
              ? "Unstaking denied"
              : "Witdraw denined"
          );
          resetTxns();
        })
        .finally(() => {
          dispatch(updateStakeData({ approveTokenXLoading: false }));
        });
    }
  };

  const handleStake = () => {
    const { user, stake } = store.getState();
    trackEvent(STAKE_CTA, {
      stake_amount_entered: parseFloat(stake.stakeAmount),
    });
    if (+stakeAmount === user.tokenAmount) {
      trackEvent(MAX_STAKE, {
        stake_amount_entered: parseFloat(stakeAmount),
      });
    }
    initiateNewTxn();
    if (address && signer) {
      dispatch(updateStakeData({ isStaking: true }));
      stakeToken(
        address,
        signer,
        stake.stakeAmount,
        {
          topic: "",
          isNativeChain: token === SD ? false : user.isNativeChain,
          connectorID: "",
        },
        stakeReferralId
      )
        .then((result: TransactionResponse) => {
          updateTransactionDetails(
            result,
            TXN_TYPES.STAKE,
            stakeAmount,
            config.nativeCurrency.symbol
          );
          dispatch(updateStakeData({ stakeAmount: "" }));
        })
        .catch((error: any) => {
          trackEvent(STAKE_FAILED, {
            stake_amount_entered: parseFloat(stakeAmount),
            reason: isErrorRejectedByUser(error)
              ? "user rejected"
              : error?.error || error?.message,
          });
          setTxnError(
            error?.error || error?.message,
            isErrorRejectedByUser(error)
          );
        })
        .finally(() => {
          dispatch(updateStakeData({ isStaking: false }));
        });
    }
  };

  const handleUnStake = () => {
    const { user, stake } = store.getState();
    let isMaxTransaction = false;
    trackEvent(UNSTAKE_CTA, {
      unstake_amount_entered: parseFloat(stake.unstakeAmount),
    });
    if (+unstakeAmount === +user.tokenXAmount) {
      trackEvent(MAX_UNSTAKE, {
        stake_amount_entered: parseFloat(stake.unstakeAmount),
      });
      isMaxTransaction = true;
    }
    initiateNewTxn();
    if (address && signer) {
      dispatch(updateStakeData({ isUnstaking: true }));
      unstakeToken(
        address,
        signer,
        stake.unstakeAmount,
        {
          topic: "",
          isNativeChain: user.isNativeChain,
          connectorID: "",
        },
        stakeReferralId,
        undefined,
        isMaxTransaction
      )
        .then((result: TransactionResponse) => {
          updateTransactionDetails(
            result,
            TXN_TYPES.UNSTAKE,
            unstakeAmount,
            config.nativeCurrency.symbol
          );
          dispatch(updateStakeData({ unstakeAmount: "" }));
        })
        .catch((error: any) => {
          dispatch(updateStakeData({ isUnstaking: false }));
          trackEvent(UNSTAKE_FAILED, {
            unstake_amount_entered: parseFloat(unstakeAmount),
            reson: isErrorRejectedByUser(error)
              ? "user rejected"
              : error?.error || error?.message,
          });
          setTxnError(
            error.error || error.message,
            isErrorRejectedByUser(error)
          );
        })
        .finally(() => {
          dispatch(updateStakeData({ isUnstaking: false }));
        });
    }
  };

  const handleTransactions = (transaction: any) => {
    switch (transaction.type) {
      case TRANSACTION_TYPE.STAKE:
        return handleStake();
      case TRANSACTION_TYPE.UNSTAKE:
        return handleUnStake();
      case TRANSACTION_TYPE.WITHDRAW:
        return handleClaim(transaction);
      case TRANSACTION_TYPE.APPROVE_TOKEN:
        return handleApproveToken(transaction.errorMessages);
      case TRANSACTION_TYPE.APPROVE_TOKEN_X:
        return handleApproveTokenX(transaction.errorMessages);
    }
  };

  const attachEventListners = (event: any) => {
    switch (event.name) {
      case EVENTS.HANDLE_CONNECT:
        if (event.data) {
          connectors.forEach((item: any) => {
            if (item.id === event.data.id) {
              return connect({ connector: item });
            }
          });
        }
        break;

      case EVENTS.HANDLE_DISCONNECT:
        dispatch(clearWalletData());
        dispatch(clearUserData());
        dispatch(clearStakeData());
        disconnect();
        break;

      case EVENTS.HANDLE_TRANSACTION:
        if (event.data) {
          handleTransactions(event.data);
        }
        break;
    }
  };

  useEffect(() => {
    attachEventListners(emitter);
  }, [emitter]);

  const getStakeUnstakeFirebaseMethodName = useCallback(
    (isSuccessful: boolean, type: string) => {
      switch (type) {
        case TXN_TYPES.UNSTAKE:
          return isSuccessful ? UNSTAKE_SUCCESSFUL : UNSTAKE_FAILED;
        case TXN_TYPES.STAKE:
          return isSuccessful ? STAKE_SUCCESSFUL : STAKE_FAILED;
        case TXN_TYPES.CLAIM:
          return isSuccessful ? WITHDRAWAL_SUCCESSFUL : WITHDRAWAL_FAILED;
      }
      return "";
    },
    []
  );

  const isValidMagicEvent = (vid: string, type: string, amount: string) => {
    if (
      token === BNB &&
      vid &&
      type === TXN_TYPES.STAKE &&
      Number(amount) >= 0.5
    ) {
      return true;
    }

    if (
      token === POLYGON &&
      vid &&
      type === TXN_TYPES.STAKE &&
      Number(amount) >= 150
    ) {
      return true;
    }

    return false;
  };

  const logStakeUnstakeEvent = useCallback(
    (isSuccessful: boolean, type: string, hash: string = "", vid: string) => {
      let primaryStakedValue = "withdraw_amount";
      if (type !== TXN_TYPES.CLAIM) {
        primaryStakedValue = isSuccessful
          ? `token_${type === TXN_TYPES.UNSTAKE ? "unstaked" : "staked"}_now`
          : `${
              type === TXN_TYPES.UNSTAKE ? "stake" : "unstake"
            }_amount_entered`;
      }

      const amount = txn.amount;

      if (isValidMagicEvent(vid, type, amount)) {
        logMagicStakeEvent(vid);
      }

      trackEvent(getStakeUnstakeFirebaseMethodName(isSuccessful, type), {
        [primaryStakedValue]: parseFloat(Number(amount).toString()),
        hash: hash,
      });
    },
    [getStakeUnstakeFirebaseMethodName, txn.amount]
  );

  const handleTxViewOnEtherScan = () => {
    const url = `${config.blockExplorerUrls}/tx/${txn.hash}`;
    window.open(url, "_blank");
  };

  const isApprovalTransaction = (transactionType: string) => {
    return [TXN_TYPES.ERC20_APPROVE, TXN_TYPES.XTOKEN_APPROVE].includes(
      transactionType
    );
  };

  const updateTransactionDetailsWithResults = useCallback(
    (status: string) => {
      dispatch(updateTransactionStatus({ status }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      txn.original &&
      txn.status === TXN_STATUSES.PENDING &&
      !transactionWaitRef.current
    ) {
      transactionWaitRef.current = true;
      txn.original
        .wait(1)
        .then((confirmation: any) => {
          if (confirmation) {
            let hash: string;

            if (isSafeApp) {
              hash = confirmation.logs[0].transactionHash;
              dispatch(
                updateTxnHash({
                  hash,
                })
              );
            } else {
              hash = txn.hash;
            }

            const type = txn.type;
            if (confirmation.blockNumber && confirmation.status) {
              updateTransactionDetailsWithResults(TXN_STATUSES.SUCCESS);
              !isApprovalTransaction(type) &&
                logStakeUnstakeEvent(true, type, hash, cookies[VID]);
              if (isApprovalTransaction(type)) {
                resetTxns();
                const _type =
                  TXN_TYPES.ERC20_APPROVE === type
                    ? token !== SD
                      ? "Staking"
                      : "Delegation"
                    : token !== SD
                    ? "Unstaking"
                    : "withdraw";
                toast({
                  description: (
                    <Text fontWeight={600} fontSize="16px">
                      {_type} approved
                    </Text>
                  ),
                  status: "success",
                  icon: <Icon Icon={SuccessCheck} width="24px" height="24px" />,
                  duration: 5000,
                  position: "top",
                  isClosable: false,
                });
              }
              if (!txnLoader && !isApprovalTransaction(type)) {
                toast({
                  description: `${type} Transaction Successful`,
                  status: "success",
                  duration: 5000,
                  position: "top",
                });
              }
            } else {
              logStakeUnstakeEvent(false, type, hash, "");
              updateTransactionDetailsWithResults(TXN_STATUSES.ERROR);
              if (!txnLoader && !isApprovalTransaction(type)) {
                toast({
                  description: `${type} Transaction failed`,
                  status: "error",
                  duration: 5000,
                  position: "top",
                });
              }
            }
          }
        })
        .catch((error: any) => {
          setTxnError(error?.error || error?.message);
        });
    }
  }, [
    cookies,
    dispatch,
    isSafeApp,
    logStakeUnstakeEvent,
    resetTxns,
    setTxnError,
    toast,
    txn.hash,
    txn.original,
    txn.status,
    txn.type,
    txnLoader,
    updateTransactionDetailsWithResults,
  ]);

  const router = useRouter();

  const {
    query: { referralId },
  } = router;

  const { userType, isValidReferralId } = useReferral(referralId as string);

  const removeReferralQueryParam = () => {
    const { pathname, query } = router;
    delete router.query.referralId;
    router.replace({ pathname, query }, undefined, { shallow: true });
  };

  return (
    <>
      {isConnected &&
        referralId &&
        userType &&
        userType !== ReferralUserType.KOL && (
          <Referral
            user={userType}
            referralId={referralId as string}
            isValidReferralId={isValidReferralId}
            referralCallBack={removeReferralQueryParam}
            network={network}
            token={token}
            config={config}
          />
        )}
      {txnLoader && (
        <TransactionModal
          isOpen={txnLoader}
          hash={txn.hash}
          isTxnProcessing={txn.status === TXN_STATUSES.PENDING}
          error={txError.promptError}
          daysToWaitForWithdraw={DAYS_TO_WAIT_FOR_WITHDRAW}
          hasUserDenied={txError.hasUserDenied}
          closeAlert={resetTxns}
          handleTxView={handleTxViewOnEtherScan}
          transactionType={txn.type}
          token={token}
          network={network}
          chainId={config.chainId}
          isSafeApp={isSafeApp}
        />
      )}
    </>
  );
};

export default Services;
