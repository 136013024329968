import React from 'react';
import { Flex } from '@chakra-ui/react';

import { TextPrimary } from 'theme';

const AccountModalHeader = () => {
  return (
    <Flex justifyContent="center" alignItems="center">
      <TextPrimary fontWeight="600" fontSize="24px">
        Wallet
      </TextPrimary>
    </Flex>
  );
};

export default AccountModalHeader;
