import { NetworkConfigType } from '../types';

// ABI
import ERC20TOKENABI from './contracts/mainnet/ERC20.json';
import TokenXABI from './contracts/mainnet/TokenXBal.json';

const providerKey = process.env.NEXT_PUBLIC_ALCHEMY_KEY || '';

export const MainNetConfig: NetworkConfigType = {
  chainId: '0xfa',
  supportedChainIDs: [250],
  providerKey,
  chainName: 'Fantom Opera',
  nativeCurrency: {
    name: 'Fantom',
    symbol: 'FTM',
    xsymbol: 'sFTMX',
    decimals: 18,
  },
  rpcUrls: {
    default: `https://rpc.ankr.com/fantom`,
  },
  blockExplorerUrls: ['https://ftmscan.com'],
  contractAddresses: {
    erc20: '0xB458BfC855ab504a8a327720FcEF98886065529b',
    xtoken: {
      token: '0xd7028092c830b5C8FcE061Af2E593413EbbC1fc1',
      stakingContract: '0xd7028092c830b5C8FcE061Af2E593413EbbC1fc1',
    },
  },
  abis: {
    erc20: ERC20TOKENABI,
    xtoken: {
      token: TokenXABI,
      stakingContract: ERC20TOKENABI,
    },
  },
};
