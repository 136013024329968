import { Connector, configureChains, createClient } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { WalletData } from "../../types/common";
import { getWallets } from "../helper/helper";
import { getSDChains, getSDconfig } from "../sd";

const buildClient: any = (
  network: string,
  providerKey: string,
  wallets: Array<WalletData>,
  isNativeChain?: boolean
) => {
  // eslint-disable-next-line no-console
  console.log(isNativeChain);

  const config = getSDconfig(network);

  const { chains, provider } = configureChains(getSDChains(network), [
    jsonRpcProvider({
      rpc: () => ({
        http: `${config.rpcUrls.default}/${providerKey}`,
      }),
    }),
  ]);

  const wagmiClient = createClient({
    autoConnect: true,
    connectors: getWallets(wallets, chains) as unknown as Connector<
      any,
      any,
      any
    >[],
    provider,
  });

  return { wagmiClient, config };
};

export default buildClient;
