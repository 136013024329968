import { Text, Box, Button, Flex } from "@chakra-ui/react";

import {
  walletIconMapping,
  walletLabelMapping,
} from "../../../../constants/walletMenuOptions";
import { WALLET_KEYS } from "../../../../constants/common";

import BaseImage from "../../../Image";

interface WalletListProps {
  isChecked: boolean;
  connectorsList: Array<string>;
  onConnectWallet(id: string): void;
}

const HIDDEN_WALLETS = [WALLET_KEYS.LEDGER_LIVE, WALLET_KEYS.SAFE];

const WalletList = ({
  isChecked,
  onConnectWallet,
  connectorsList,
}: WalletListProps) => {
  return (
    <Box maxH="480px" overflow="auto" mt="1rem" className="scroll-container">
      <Box
        pointerEvents={!isChecked ? "none" : "inherit"}
        opacity={isChecked ? "1" : "0.5"}
        mt="1rem"
      >
        <Flex flexWrap="wrap" gap="1rem">
          {connectorsList &&
            connectorsList
              .filter((wallet) => !HIDDEN_WALLETS.includes(wallet))
              .map((id: string) => (
                <Box
                  as={Button}
                  key={id}
                  bg="backgroundPrimary"
                  onClick={() => onConnectWallet(id)}
                  width={{ base: "100%", md: "224px" }}
                  outline="none"
                  p="0"
                  h="72px"
                  _hover={{
                    background: "primary",
                    // color: "brand.textLight",
                  }}
                >
                  <Flex
                    width="100%"
                    gap="0.5rem"
                    alignItems="center"
                    p="1rem 1.5rem"
                  >
                    <Box height="40px" borderRadius="50%" overflow="hidden">
                      <BaseImage
                        src={walletIconMapping[id.toLowerCase()]}
                        alt={`${id} Logo`}
                        width={40}
                        height={40}
                      />
                    </Box>
                    <Text color="textPrimary" fontSize="16px" fontWeight="600">
                      {walletLabelMapping[id.toLowerCase()]}
                    </Text>
                  </Flex>
                </Box>
              ))}
        </Flex>
      </Box>
    </Box>
  );
};

export default WalletList;
