import { Chain } from "wagmi/chains";

import { TestNetConfig, ETHTestNetConfig } from "./testnet";
import { MainNetConfig, ETHMainNetConfig } from "./mainnet";

export const PolygonChainConfig: Chain = {
  id: 137,
  name: "Polygon Mainnet",
  network: "polygon",
  nativeCurrency: {
    decimals: 18,
    name: "Polygon",
    symbol: "Matic",
  },
  rpcUrls: {
    default: {
      http: ["https://polygon-mainnet.g.alchemy.com/v2"],
    },
    public: {
      http: ["https://polygon-mainnet.g.alchemy.com/v2"],
    },
  },
  blockExplorers: {
    default: { name: "Polygonscan", url: "https://polygonscan.com" },
  },
  testnet: false,
};

export const PolygonTestnet: Chain = {
  id: 137,
  name: "Polygon Mainnet",
  network: "polygon",
  nativeCurrency: {
    decimals: 18,
    name: "Polygon",
    symbol: "Matic",
  },
  rpcUrls: {
    default: {
      http: ["https://polygon-mainnet.g.alchemy.com/v2"],
    },
    public: {
      http: ["https://polygon-mainnet.g.alchemy.com/v2"],
    },
  },
  blockExplorers: {
    default: { name: "Polygonscan", url: "https://polygonscan.com" },
  },
  testnet: true,
};

export const ETHPolygonChainConfig: Chain = {
  id: 1,
  name: "Ethereum Mainnet",
  network: "polygon",
  nativeCurrency: {
    decimals: 18,
    name: "Polygon",
    symbol: "Matic",
  },
  rpcUrls: {
    default: {
      http: ["https://eth-mainnet.alchemyapi.io/v2"],
    },
    public: {
      http: ["https://eth-mainnet.alchemyapi.io/v2"],
    },
  },
  blockExplorers: {
    default: { name: "Etherscan", url: "https://etherscan.io" },
  },
  testnet: false,
};

export const ETHPolygonTestnet: Chain = {
  id: 1,
  name: "Ethereum Mainnet",
  network: "polygon",
  nativeCurrency: {
    decimals: 18,
    name: "Polygon",
    symbol: "Matic",
  },
  rpcUrls: {
    default: {
      http: ["https://eth-mainnet.alchemyapi.io/v2"],
    },
    public: {
      http: ["https://eth-mainnet.alchemyapi.io/v2"],
    },
  },
  blockExplorers: {
    default: { name: "Etherscan", url: "https://etherscan.io" },
  },
  testnet: true,
};

// export const getPolygonETHConfig = (network: string) =>
//   network === 'mainnet' ? ETHMainNetConfig : ETHTestNetConfig;

// export const getPolygonETHChains = (network: string) => {
//   return network === 'mainnet' ? [ETHPolygonChainConfig] : [ETHPolygonTestnet];
// };

export const getPolygonConfig = (network: string, chainId?: number) => {
  if (network === "mainnet") {
    return chainId === 1 ? ETHMainNetConfig : MainNetConfig;
  }
  return chainId === 1 ? ETHTestNetConfig : TestNetConfig;
};

export const getPolygonChains = (network: string, chainId?: number) => {
  if (network === "mainnet") {
    return chainId === 1 ? [ETHPolygonChainConfig] : [PolygonChainConfig];
  }
  return chainId === 1 ? [ETHPolygonTestnet] : [PolygonTestnet];
};
