// @ts-nocheck
import { Chain } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

interface WalletConfigType {
  chains?: Chain[];
  options?: ConnectorOptions;
}

export type InjectedConnectorOptions = {
  name?: string | ((detectedName: string | string[]) => string);
  shimDisconnect?: boolean;
};

export type ConnectorOptions = Pick<InjectedConnectorOptions, "shimDisconnect">;

export function okexConnector(config: WalletConfigType): InjectedConnector {
  let provider: Window["okexchain"] = undefined;

  const connector = new InjectedConnector({
    ...config,
    options: {
      name: "OKX Wallet",
      shimDisconnect: true,
      ...config?.options,
    },
  });

  function getReady(okex?: any) {
    if (!okex) return;
    return okex;
  }

  function findProvider(okex?: any) {
    if (okex?.providers) return okex.providers.find(getReady);
    return getReady(okex);
  }

  async function getProvider() {
    if (typeof window !== "undefined") {
      provider = findProvider(window.okexchain);
    }
    return provider;
  }

  (connector as any).getProvider = getProvider;
  (connector as any).ready =
    typeof window != "undefined" && !!findProvider(window.okexchain);
  (connector as any).id = "okex";

  return connector;
}

export function trustWalletConnector(
  config: WalletConfigType
): InjectedConnector {
  let provider: Window["trustwallet"] = undefined;

  const connector = new InjectedConnector({
    ...config,
    options: {
      name: "Trust Wallet",
      shimDisconnect: true,
      ...config?.options,
    },
  });

  function getReady(trustwallet?: any) {
    if (!trustwallet) return;

    return trustwallet;
  }

  function findProvider(trustwallet?: any) {
    if (trustwallet?.providers) return trustwallet.providers.find(getReady);
    return getReady(trustwallet);
  }

  async function getProvider() {
    if (typeof window !== "undefined") {
      provider = findProvider(window.trustwallet);
    }
    return provider;
  }

  (connector as any).getProvider = getProvider;
  (connector as any).ready =
    typeof window != "undefined" && !!findProvider(window.trustwallet);
  (connector as any).id = "trustwallet";

  return connector;
}

export function safePalWalletConnector(
  config: WalletConfigType
): InjectedConnector {
  let provider: Window["safepalProvider"] = undefined;

  const connector = new InjectedConnector({
    ...config,
    options: {
      name: "SafePal Wallet",
      shimDisconnect: true,
      ...config?.options,
    },
  });

  function getReady(safepal?: any) {
    if (!safepal) return;

    return safepal;
  }

  function findProvider(safepal?: any) {
    if (safepal?.providers) return safepal.providers.find(getReady);
    return getReady(safepal);
  }

  async function getProvider() {
    if (typeof window !== "undefined") {
      provider = findProvider(window.safepalProvider);
    }
    return provider;
  }

  (connector as any).getProvider = getProvider;
  (connector as any).ready =
    typeof window != "undefined" && !!findProvider(window.safepalProvider);
  (connector as any).id = "safepal";

  return connector;
}

export function rabbyWalletConnector(
  config: WalletConfigType
): InjectedConnector {
  let provider: Window["ethereum"] = undefined;

  const connector = new InjectedConnector({
    ...config,
    options: {
      name: "Rabby Wallet",
      shimDisconnect: true,
      ...config?.options,
    },
  });

  function getReady(rabbywallet?: any) {
    if (!rabbywallet) return;

    return rabbywallet;
  }

  function findProvider(rabbywallet?: any) {
    if (rabbywallet?.providers) return rabbywallet.providers.find(getReady);
    return getReady(rabbywallet);
  }

  async function getProvider() {
    if (typeof window !== "undefined") {
      provider =
        window.ethereum && (window.ethereum as any).isRabby
          ? findProvider(window.ethereum)
          : undefined;
    }
    return provider;
  }

  (connector as any).getProvider = getProvider;
  (connector as any).ready =
    typeof window != "undefined" &&
    window.ethereum &&
    (window.ethereum as any).isRabby &&
    !!findProvider(window.ethereum);
  (connector as any).id = "rabbywallet";

  return connector;
}

export function mathWalletConnector(
  config: WalletConfigType
): InjectedConnector {
  let provider: Window["ethereum"] = undefined;

  const connector = new InjectedConnector({
    ...config,
    options: {
      name: "Math Wallet",
      shimDisconnect: true,
      ...config?.options,
    },
  });

  function getReady(mathwallet?: any) {
    if (!mathwallet) return;

    return mathwallet;
  }

  function findProvider(mathwallet?: any) {
    if (mathwallet?.providers) return mathwallet.providers.find(getReady);
    return getReady(mathwallet);
  }

  async function getProvider() {
    if (typeof window !== "undefined") {
      provider =
        window.ethereum && (window.ethereum as any).isMathWallet
          ? findProvider(window.ethereum)
          : undefined;
    }
    return provider;
  }

  (connector as any).getProvider = getProvider;
  (connector as any).ready =
    typeof window != "undefined" &&
    window.ethereum &&
    (window.ethereum as any).isMathWallet &&
    !!findProvider(window.ethereum);
  (connector as any).id = "mathwallet";

  return connector;
}

export function coins98WalletConnector(
  config: WalletConfigType
): InjectedConnector {
  let provider: Window["coin98"]["provider"];

  const connector = new InjectedConnector({
    ...config,
    options: {
      name: "Coins98 Wallet",
      shimDisconnect: true,
      ...config?.options,
    },
  });

  function getReady(coins98?: any) {
    if (!coins98) return;

    return coins98;
  }

  function findProvider(coins98?: any) {
    if (coins98?.providers) return coins98.providers.find(getReady);
    return getReady(coins98);
  }

  async function getProvider() {
    if (typeof window !== "undefined") {
      // TODO: Fallback to ethereum#initialized event for async injection
      // https://github.com/MetaMask/detect-provider#synchronous-and-asynchronous-injection=
      provider = findProvider(window.coin98?.provider);
    }
    return provider;
  }

  (connector as any).getProvider = getProvider;
  (connector as any).ready =
    typeof window != "undefined" && findProvider(window.coin98?.provider);
  (connector as any).id = "coin98";

  return connector;
}

export function bitKeepConnector(config: WalletConfigType): InjectedConnector {
  let provider: Window["bitkeep"]["ethereum"];

  const connector = new InjectedConnector({
    ...config,
    options: {
      name: "Bitget Wallet",
      shimDisconnect: true,
      ...config?.options,
    },
  });

  function getReady(bitkeep?: any) {
    if (!bitkeep) return;

    return bitkeep;
  }

  function findProvider(bitkeep?: any) {
    if (bitkeep?.providers) return bitkeep.providers.find(getReady);
    return getReady(bitkeep);
  }

  async function getProvider() {
    if (typeof window !== "undefined") {
      provider = findProvider(window.window.bitkeep?.ethereum);
    }
    return provider;
  }

  (connector as any).getProvider = getProvider;
  (connector as any).ready =
    typeof window != "undefined" && !!findProvider(window.bitkeep?.ethereum);
  (connector as any).id = "bitkeep";

  return connector;
}

export function bitGetConnector(config: WalletConfigType): InjectedConnector {
  let provider: Window["bitkeep"]["ethereum"];

  const connector = new InjectedConnector({
    ...config,
    options: {
      name: "Bitget Wallet",
      shimDisconnect: true,
      ...config?.options,
    },
  });

  function getReady(bitkeep?: any) {
    if (!bitkeep) return;

    return bitkeep;
  }

  function findProvider(bitkeep?: any) {
    if (bitkeep?.providers) return bitkeep.providers.find(getReady);
    return getReady(bitkeep);
  }

  async function getProvider() {
    if (typeof window !== "undefined") {
      provider = findProvider(window.window.bitkeep?.ethereum);
    }
    return provider;
  }

  (connector as any).getProvider = getProvider;
  (connector as any).ready =
    typeof window != "undefined" && !!findProvider(window.bitkeep?.ethereum);
  (connector as any).id = "bitget";

  return connector;
}

export function coinHubConnector(config: WalletConfigType): InjectedConnector {
  let provider: Window["ethereum"];

  const connector = new InjectedConnector({
    ...config,
    options: {
      name: "Coinhub Wallet",
      shimDisconnect: true,
      ...config?.options,
    },
  });

  function getReady(coinhub?: any) {
    if (!coinhub) return;

    return coinhub;
  }

  function findProvider(coinhub?: any) {
    if (coinhub?.providers) return coinhub.providers.find(getReady);
    return getReady(coinhub);
  }

  async function getProvider() {
    if (typeof window !== "undefined") {
      provider = findProvider(window.ethereum);
    }
    return provider;
  }

  (connector as any).getProvider = getProvider;
  (connector as any).ready =
    typeof window != "undefined" && !!findProvider(window.ethereum);
  (connector as any).id = "coinhub";

  return connector;
}

export function naboxConnector(config: WalletConfigType): InjectedConnector {
  let provider: Window["NaboxWallet"];

  const connector = new InjectedConnector({
    ...config,
    options: {
      name: "Nabox Wallet",
      shimDisconnect: true,
      ...config?.options,
    },
  });

  function getReady(nabox?: any) {
    if (!nabox) return;

    return nabox;
  }

  function findProvider(nabox?: any) {
    if (nabox?.providers) return nabox.providers.find(getReady);
    return getReady(nabox);
  }

  async function getProvider() {
    if (typeof window !== "undefined") {
      provider = findProvider(window.NaboxWallet);
    }
    return provider;
  }

  (connector as any).getProvider = getProvider;
  (connector as any).ready =
    typeof window != "undefined" && findProvider(window.NaboxWallet);
  (connector as any).id = "nabox";

  return connector;
}

export function binanceWalletConnector(
  config: WalletConfigType
): InjectedConnector {
  let provider: Window["BinanceChain"];

  const connector = new InjectedConnector({
    ...config,
    options: {
      name: "Binance Wallet",
      shimDisconnect: true,
      ...config?.options,
    },
  });

  function getReady(binance?: any) {
    if (!binance) return;

    return binance;
  }

  function findProvider(binance?: any) {
    if (binance?.providers) return binance.providers.find(getReady);
    return getReady(binance);
  }

  async function getProvider() {
    if (typeof window !== "undefined") {
      // TODO: Fallback to ethereum#initialized event for async injection
      // https://github.com/MetaMask/detect-provider#synchronous-and-asynchronous-injection=
      provider = findProvider(window.BinanceChain);
    }
    return provider;
  }

  (connector as any).getProvider = getProvider;
  (connector as any).ready =
    typeof window != "undefined" && findProvider(window.BinanceChain);
  (connector as any).id = "binance";

  return connector;
}

export function frontierConnector(config: WalletConfigType): InjectedConnector {
  let provider: Window["frontier"]["ethereum"];

  const connector = new InjectedConnector({
    ...config,
    options: {
      name: "Frontier Wallet",
      shimDisconnect: true,
      ...config?.options,
    },
  });

  function getReady(frontier?: any) {
    if (!frontier) return;

    return frontier;
  }

  function findProvider(frontier?: any) {
    if (frontier?.providers) return frontier.providers.find(getReady);
    return getReady(frontier);
  }

  async function getProvider() {
    if (typeof window !== "undefined") {
      provider = findProvider(window.frontier?.ethereum);
    }
    return provider;
  }

  (connector as any).getProvider = getProvider;
  (connector as any).ready =
    typeof window != "undefined" && !!findProvider(window.frontier?.ethereum);
  (connector as any).id = "frontier";

  return connector;
}

export function imtokenConnector(
  projectId: string,
  chains?: Chain[]
): WalletConnectConnector {
  const connector = new WalletConnectConnector({
    chains,
    options: {
      projectId: projectId,
    },
  });

  connector.id = "imtoken";
  connector.name = "Imtoken Wallet";
  return connector;
}

export function haloWalletConnector(
  config: WalletConfigType
): InjectedConnector {
  let provider: Window["haloEthereum"] = undefined;

  const connector = new InjectedConnector({
    ...config,
    options: {
      name: "Halo Wallet",
      shimDisconnect: true,
      ...config?.options,
    },
  });

  function getReady(halo?: any) {
    if (!halo) return;

    return halo;
  }

  function findProvider(halo?: any) {
    if (halo?.providers) return halo.providers.find(getReady);
    return getReady(halo);
  }

  async function getProvider() {
    if (typeof window !== "undefined") {
      provider = findProvider(
        (window.ethereum as any)?.isKuCoinWallet
          ? window.ethereum
          : window.haloEthereum
      );
    }
    return provider;
  }

  (connector as any).getProvider = getProvider;
  (connector as any).ready =
    typeof window != "undefined" &&
    !!findProvider(
      (window.ethereum as any)?.isKuCoinWallet
        ? window.ethereum
        : window.haloEthereum
    );
  (connector as any).id = "halo";

  return connector;
}

export function phantomWalletConnector(
  config: WalletConfigType
): InjectedConnector {
  let provider: Window["phantom"]["ethereum"] = undefined;

  const connector = new InjectedConnector({
    ...config,
    options: {
      name: "Phantom Wallet",
      shimDisconnect: true,
      ...config?.options,
    },
  });

  function getReady(phantom?: any) {
    if (!phantom) return;

    return phantom;
  }

  function findProvider(phantom?: any) {
    if (phantom?.providers) return phantom.providers.find(getReady);
    return getReady(phantom);
  }

  async function getProvider() {
    if (typeof window !== "undefined") {
      provider = findProvider(window.phantom?.ethereum);
    }
    return provider;
  }

  (connector as any).getProvider = getProvider;
  (connector as any).ready =
    typeof window != "undefined" && !!findProvider(window.phantom?.ethereum);
  (connector as any).id = "phantom";

  return connector;
}

export function exodusWalletConnector(
  config: WalletConfigType
): InjectedConnector {
  let provider: Window["exodus"]["ethereum"] = undefined;

  const connector = new InjectedConnector({
    ...config,
    options: {
      name: "Phantom Wallet",
      shimDisconnect: true,
      ...config?.options,
    },
  });

  function getReady(exodus?: any) {
    if (!exodus) return;

    return exodus;
  }

  function findProvider(exodus?: any) {
    if (exodus?.providers) return exodus.providers.find(getReady);
    return getReady(exodus);
  }

  async function getProvider() {
    if (typeof window !== "undefined") {
      provider = findProvider(window.exodus?.ethereum);
    }
    return provider;
  }

  (connector as any).getProvider = getProvider;
  (connector as any).ready =
    typeof window != "undefined" && !!findProvider(window.exodus?.ethereum);
  (connector as any).id = "exodus";

  return connector;
}
