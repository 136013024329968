import { NetworkConfigType } from "../types";

// ABI
import ERC20TOKENABI from "./contracts/testnet/ERC20.json";
import TokenXABI from "./contracts/testnet/TokenX.json";

const providerKey = process.env.NEXT_PUBLIC_ALCHEMY_KEY || "";

export const TestNetConfig: NetworkConfigType = {
  chainId: "0x5",
  supportedChainIDs: [5],
  providerKey,
  chainName: "Ethereum Testnet",
  nativeCurrency: {
    name: "Stader",
    symbol: "SD",
    xsymbol: "SD",
    decimals: 18,
  },
  rpcUrls: {
    default: `https://eth-goerli.g.alchemy.com/v2/hjyB9RgJj-d6ofClhQGO6v5njFNyuKU5`,
  },
  blockExplorerUrls: ["https://goerli.etherscan.io"],
  contractAddresses: {
    erc20: "0x0406f539f24Be69baa8b88ED6eABEdb7b3cfdc60", // Token
    xtoken: {
      token: "0x0406f539f24Be69baa8b88ED6eABEdb7b3cfdc60",
    },
  },
  abis: {
    erc20: ERC20TOKENABI,
    xtoken: {
      token: TokenXABI,
    },
  },
};
