import { useEffect, useMemo } from "react";
import { WagmiConfig } from "wagmi";
import { AppProvider } from "@stader-labs/web-sdk";

import Services from "./Services";
import { useDispatch, useSelector } from "react-redux";
import { updateWalletData, updateStakeData } from "store";
import { getConnectors } from "./utils";
import { client } from "../config/clients/index";
import { getTokenForWebSDK } from "../utils/common";
import { Token, WalletData } from "../types/common";
import WebSDKServices from "./WebSDKServices";
import { useFirebaseEvent } from "../hooks/useFirebaseEvent";
import { ConnectKitProvider } from "connectkit";
import { Box, Checkbox, Text } from "@chakra-ui/react";
import { TERMS_URL } from "../constants/constants";
import { WALLET_KEYS } from "../constants/common";

const token = process.env.NEXT_PUBLIC_TOKEN || "";
const providerKey = process.env.NEXT_PUBLIC_ALCHEMY_KEY || "";
const network = process.env.NEXT_PUBLIC_NETWORK || "0";

interface ProviderType {
  wallets: Array<WalletData>;
}

export const WagmiProvider = ({ wallets }: ProviderType) => {
  const { isNativeChain, isEmbed, chainID, theme } = useSelector(
    (state: any) => state.user
  );

  const { connectKitObj } = useSelector((state: any) => state.wallet);
  const dispatch = useDispatch();
  const { isChecked } = useSelector((state: any) => state.wallet);
  const toggle = () => {
    dispatch(
      updateWalletData({
        isChecked: !isChecked,
      })
    );
  };

  const { wagmiClient, config } = useMemo(() => {
    const updateWalletData = connectKitObj
      ? wallets
      : wallets.filter((data) => data.key !== WALLET_KEYS.LEDGER);
    return client[token]?.(
      network,
      providerKey as string,
      updateWalletData,
      isNativeChain
    );
  }, [
    isNativeChain,
    network,
    providerKey,
    wallets,
    token,
    isEmbed,
    chainID,
    connectKitObj,
  ]);

  useFirebaseEvent();

  useEffect(() => {
    if (wagmiClient) {
      dispatch(
        updateWalletData({
          connectors: getConnectors(wagmiClient.connectors),
        })
      );
    }
  }, [wagmiClient]);

  useEffect(() => {
    dispatch(
      updateStakeData({
        blockExplorerURL: config.blockExplorerUrls[0],
      })
    );
    dispatch(
      updateWalletData({
        config,
      })
    );
  }, [config]);

  return (
    <WagmiConfig client={wagmiClient}>
      <ConnectKitProvider
        mode={theme}
        options={{
          disclaimer: (
            <Box w="320px">
              <Box
                p={{ base: "0.5rem", lg: "1rem" }}
                marginBottom="0.5rem"
                borderRadius="0.5rem"
              >
                <Checkbox onChange={toggle} isChecked={isChecked}>
                  <Text fontSize="14px">
                    I have read and accept{" "}
                    <Text
                      as="a"
                      href={TERMS_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      color="primary !important"
                      fontWeight="500 !important"
                      borderBottom="1px dashed !important"
                      borderColor="primary !important"
                    >
                      Terms of Service.
                    </Text>
                  </Text>
                </Checkbox>
              </Box>
              <Text fontSize="10px !important" marginBottom="0.5rem">
                Please note: To ensure smooth transactions, it&apos;s crucial
                that you switch your wallet to the correct network. Failure to
                do so may lead to issues. Check our guide or contact support for
                help.
              </Text>
            </Box>
          ),
          hideTooltips: true,
          hideQuestionMarkCTA: true,
          hideNoWalletCTA: true,
          embedGoogleFonts: true,
          enforceSupportedChains: false,
        }}
      >
        <AppProvider
          token={getTokenForWebSDK(token as unknown as Token)}
          chainId={config?.supportedChainIDs[isNativeChain ? 0 : 1]}
        >
          <Services config={config} />
          <WebSDKServices />
        </AppProvider>
      </ConnectKitProvider>
    </WagmiConfig>
  );
};
