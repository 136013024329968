import { Chain } from 'wagmi/chains';

// import { TestNetConfig } from './testnet';
import { MainNetConfig } from './mainnet';

export const FTMChainConfig: Chain = {
  id: 250,
  name: 'Fantom Opera',
  network: 'fantom',
  nativeCurrency: {
    name: 'Fantom',
    symbol: 'FTM',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.ankr.com/fantom'],
    },
    public: {
      http: ['https://rpc.ankr.com/fantom'],
    },
  },
  blockExplorers: {
    default: { name: 'FTMscan', url: 'https://ftmscan.com' },
  },
  testnet: false,
};

// export const BNBestnet: Chain = {
//   id: 97,
//   name: 'Binance Smart Chain - Testnet',
//   network: 'bsc-testnet',
//   nativeCurrency: {
//     decimals: 18,
//     name: 'Binance',
//     symbol: 'BNB',
//   },
//   rpcUrls: {
//     default: {
//       http: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
//     },
//     public: {
//       http: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
//     },
//   },
//   blockExplorers: {
//     default: {
//       name: 'BSCscan',
//       url: 'https://testnet.bscscan.com',
//     },
//   },
//   testnet: true,
// };

export const getFantomconfig = (network: string) =>
  network === 'mainnet' ? MainNetConfig : MainNetConfig;

export const getFantomChains = (network: string) => {
  return network === 'mainnet' ? [FTMChainConfig] : [FTMChainConfig];
};
