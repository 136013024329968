import { useEffect, useState } from "react";

// Constants
import { BNB } from "../constants/constants";
import { useSelector } from "react-redux";

const token = process.env.NEXT_PUBLIC_TOKEN || "";

type DomainDetails = {
  domain: string;
  isSpaceId: boolean;
};

const useDomainResolution = () => {
  const { walletAddress: address } = useSelector((state: any) => state.wallet);
  const [domainData, setDomainData] = useState<DomainDetails>();

  useEffect(() => {
    if (address) {
      (async function fetchDomainName() {
        try {
          if (token === BNB) {
            const spaceIdResponse = await fetch(
              `https://open-platform.nodereal.io/8438d9c512534d20a2367b930e8863e3/spaceid/domain/names/byBinds`,
              {
                method: "POST",
                body: JSON.stringify([address]),
              }
            );
            const spaceIdJson = await spaceIdResponse.json();

            if (spaceIdJson[address] && spaceIdJson[address].length > 0) {
              setDomainData({
                domain: spaceIdJson[address][0] + ".bnb",
                isSpaceId: true,
              });
              return;
            }
          }

          const udResponse = await fetch(
            `https://resolve.unstoppabledomains.com/reverse/${address.toLowerCase()}`,
            {
              method: "GET",
              headers: {
                Authorization: "Bearer f8ae74dd-e512-4ef8-aeae-ee2ebe637433",
              },
            }
          );
          const json = await udResponse.json();

          if (json.meta?.domain) {
            setDomainData({
              domain: json.meta.domain,
              isSpaceId: false,
            });
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [address]);

  return domainData;
};

export default useDomainResolution;
