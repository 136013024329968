import { Box, BoxProps, Flex, useStyleConfig } from "@chakra-ui/react";
import React from "react";

interface ContainerProps extends BoxProps {
  variant?: string;
}

interface LinkProps {
  href?: string;
}

export const TicketCard = (props: ContainerProps) => {
  const { ...rest } = props;
  const styles = useStyleConfig("TicketCard");
  return <Box __css={styles} {...rest} />;
};

export const InfoCardBody = (props: ContainerProps) => {
  const { ...rest } = props;
  const styles = useStyleConfig("InfoCardBody");
  return <Flex __css={styles} {...rest} />;
};

export const AuditsContainer = (props: ContainerProps) => {
  const { ...rest } = props;
  const styles = useStyleConfig("AuditsContainer");
  return <Box __css={styles} {...rest} />;
};

export const IconBackgroundContainer = (props: ContainerProps) => {
  const { ...rest } = props;
  const styles = useStyleConfig("IconBackgroundContainer");
  return <Box __css={styles} {...rest} />;
};

export const HeaderContainer = (props: ContainerProps) => {
  const { ...rest } = props;
  const styles = useStyleConfig("HeaderContainer");
  return <Box __css={styles} {...rest} />;
};

export const SidebarContainer = (props: ContainerProps) => {
  const { ...rest } = props;
  const styles = useStyleConfig("SidebarContainer");
  return <Box __css={styles} {...rest} />;
};

export const AccountModalActionItem = (props: ContainerProps & LinkProps) => {
  const { ...rest } = props;
  const styles = useStyleConfig("AccountModalActionItem");
  return <Box as="a" __css={styles} {...rest} />;
};

export const TextPrimary = (props: BoxProps) => {
  const { ...rest } = props;
  const styles = useStyleConfig("TextPrimary");
  return <Box as="p" __css={styles} {...rest} />;
};

export const TextSecondary = (props: BoxProps) => {
  const { ...rest } = props;
  const styles = useStyleConfig("TextSecondary");
  return <Box as="p" __css={styles} {...rest} />;
};
