import { Box, Text, Button } from "@chakra-ui/react";

import { store, updateUserData } from "store";

import useStoreData from "../../hooks/useStoreData";
import { EVENTS } from "../../constants/events";
import { emitEvent } from "../../utils/common";

export const Network = () => {
  const { user, wallet } = useStoreData();

  const handleClick = () => {
    store.dispatch(updateUserData({ data: user.tokenAmount + 1 }));
  };

  const handleConnect = (connector: any) => {
    emitEvent(EVENTS.HANDLE_CONNECT, connector);
  };

  const handleDisconnect = () => {
    emitEvent(EVENTS.HANDLE_DISCONNECT);
  };

  return (
    <Box border="1px" p="20px" borderRadius="8px" maxW="50%">
      {wallet?.connectors.map((item: any, index: number) => {
        return (
          <Button key={index} onClick={() => handleConnect(item)}>
            {item.name}
          </Button>
        );
      })}

      <Text>{JSON.stringify(user, null, 2)}</Text>

      <br />
      <Button mt="20px" onClick={handleClick}>
        increment
      </Button>
      <Text>Token amount {user.tokenAmount}</Text>

      <br />
      <Button mt="20px" onClick={handleDisconnect}>
        Disconnect
      </Button>
    </Box>
  );
};
