import { Chain } from "wagmi";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
// import { LedgerConnector } from "wagmi/connectors/ledger";
import { SafeConnector } from "wagmi/connectors/safe";
import { IFrameEthereumConnector } from "@ledgerhq/ledger-live-wagmi-connector";

import {
  binanceWalletConnector,
  bitKeepConnector,
  coinHubConnector,
  coins98WalletConnector,
  mathWalletConnector,
  naboxConnector,
  okexConnector,
  safePalWalletConnector,
  trustWalletConnector,
  frontierConnector,
  haloWalletConnector,
  phantomWalletConnector,
  exodusWalletConnector,
  rabbyWalletConnector,
  bitGetConnector,
  imtokenConnector,
} from "../../config/connectors";
import { WALLET_KEYS } from "../../constants/common";

const WC_PROJECT_ID = process.env.NEXT_PUBLIC_WC_PROJECT_ID || "";

const getWallet = (wallet: string, chains: Chain[] = []) => {
  switch (wallet) {
    case WALLET_KEYS.LEDGER_LIVE:
      return new IFrameEthereumConnector({
        chains,
        options: {},
      });
    case WALLET_KEYS.COINBASEWALLET:
      return new CoinbaseWalletConnector({
        options: {
          appName: "Stader Labs",
        },
      });
    case WALLET_KEYS.METAMASK:
      return new MetaMaskConnector({
        chains,
        options: {
          shimDisconnect: true,
        },
      });
    case WALLET_KEYS.WALLETCONNECT:
      return new WalletConnectConnector({
        chains,
        options: {
          projectId: WC_PROJECT_ID as string,
          // rpc: {
          //   1: `https://eth-mainnet.alchemyapi.io/v2/${process.env.NEXT_PUBLIC_ALCHEMY_KEY}`,
          //   56: `https://bsc-mainnet.nodereal.io/v1/${process.env.NEXT_PUBLIC_ALCHEMY_KEY}`,
          //   137: `https://polygon-mainnet.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_KEY}`,
          // },
        },
      });
    case WALLET_KEYS.SAFE:
      return new SafeConnector({
        chains,
        options: {
          allowedDomains: [/gnosis-safe.io$/, /app.safe.global$/],
          debug: false,
        },
      });
    case WALLET_KEYS.OKX:
      return okexConnector({
        chains,
        options: {
          shimDisconnect: true,
        },
      });
    case WALLET_KEYS.TRUSTWALLET:
      return trustWalletConnector({
        chains,
        options: {
          shimDisconnect: true,
        },
      });
    case WALLET_KEYS.SAFEPAL:
      return safePalWalletConnector({
        chains,
        options: {
          shimDisconnect: true,
        },
      });
    case WALLET_KEYS.HALO:
      return haloWalletConnector({
        chains,
        options: {
          shimDisconnect: true,
        },
      });
    case WALLET_KEYS.PHANTOM:
      return phantomWalletConnector({
        chains,
        options: {
          shimDisconnect: true,
        },
      });
    case WALLET_KEYS.EXODUS:
      return exodusWalletConnector({
        chains,
        options: {
          shimDisconnect: true,
        },
      });
    case WALLET_KEYS.MATHWALLET:
      return mathWalletConnector({
        chains,
        options: {
          shimDisconnect: true,
        },
      });
    case WALLET_KEYS.RABBY: {
      return rabbyWalletConnector({
        chains,
        options: {
          shimDisconnect: true,
        },
      });
    }
    case WALLET_KEYS.COIN98:
      return coins98WalletConnector({
        chains,
        options: {
          shimDisconnect: true,
        },
      });
    // TODO remove bitkeep
    case WALLET_KEYS.BITKEEP:
      return bitKeepConnector({
        chains,
        options: {
          shimDisconnect: true,
        },
      });
    case WALLET_KEYS.BITGET:
      return bitGetConnector({
        chains,
        options: {
          shimDisconnect: true,
        },
      });
    case WALLET_KEYS.COINHUB:
      return coinHubConnector({
        chains,
        options: {
          shimDisconnect: true,
        },
      });
    case WALLET_KEYS.NABOX:
      return naboxConnector({
        chains,
        options: {
          shimDisconnect: true,
        },
      });
    case WALLET_KEYS.BINANCE:
      return binanceWalletConnector({
        chains,
        options: {
          shimDisconnect: true,
        },
      });
    case WALLET_KEYS.FRONTIER:
      return frontierConnector({
        chains,
        options: {
          shimDisconnect: true,
        },
      });
    case WALLET_KEYS.IMTOKEN:
      return imtokenConnector(WC_PROJECT_ID, chains);
  }

  return new MetaMaskConnector({
    chains,
    options: {
      shimDisconnect: true,
    },
  });
};

export const getWallets: any = (
  wallets: Array<{
    key: string;
    name: string;
  }>,
  chains: Chain[]
) => {
  return wallets.map((wallet) => getWallet(wallet.key, chains));
};
