import { Connector, configureChains, createClient } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { getPolygonConfig } from "../polygon";
import { getWallets } from "../helper/helper";
import { mainnet, polygon } from "wagmi/chains";
import { WalletData } from "../../types/common";

const buildClient: any = (
  network: string,
  providerKey: string,
  wallets: Array<WalletData>,
  isNativeChain?: boolean
) => {
  const config = getPolygonConfig(network, isNativeChain ? 137 : 1);
  const { chains, provider } = configureChains(
    [mainnet, polygon],
    [
      jsonRpcProvider?.({
        rpc: () => ({
          http: `${config.rpcUrls.default}/${providerKey}`,
        }),
      }),
    ]
  );

  const wagmiClient = createClient({
    autoConnect: true,
    connectors: getWallets(wallets, chains) as unknown as Connector<
      any,
      any,
      any
    >[],
    provider,
  });

  return { wagmiClient, config };
};

export default buildClient;
