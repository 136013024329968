import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";

import { ModalDrawer } from "../ModalDrawer";

import AccountModalHeader from "./components/AccountModalHeader";
import ScannerLink from "./components/ScannerLink";
import WalletAddress from "./components/WalletAddress";

import { SpaceId, Unstoppable } from "icons";
import Icon from "../../components/Icon";

interface AccountModalProps {
  isOpen: boolean;
  scannerLink: string;
  walletAddress?: string;
  domain?: { domain: string; isSpaceId: boolean };
  connectorId: string;
  onCancel(isDisconnected?: boolean): void;
  onClickScanner(): void;
  onCopyAddress(): void;
}

const AccountModal = ({
  isOpen,
  scannerLink,
  walletAddress,
  domain,
  connectorId,
  onCancel,
  onClickScanner,
  onCopyAddress,
}: AccountModalProps) => {
  return (
    <ModalDrawer
      isOpen={isOpen}
      closeAlert={onCancel}
      header={<AccountModalHeader />}
      headerProps={{
        fontSize: "1rem",
        p: "0",
      }}
      contentProps={{
        maxW: "600px",
        p: { base: "2.5rem 1rem 1rem", md: "2.5rem 3rem" },
      }}
      bodyProps={{
        mt: "2rem",
        p: "0",
      }}
    >
      {domain ? (
        <Accordion w="100%" allowToggle>
          <AccordionItem
            mt="1rem"
            borderRadius="0.5rem"
            border="none"
            bg="backgroundPrimary"
          >
            <AccordionButton _focus={{ boxShadow: "none" }} padding="1rem">
              <Flex gap="0.5rem" flex={1}>
                <Flex borderRadius="50%" overflow="hidden" p="2px">
                  <Icon
                    width="24px"
                    height="24px"
                    Icon={domain.isSpaceId ? SpaceId : Unstoppable}
                  />
                </Flex>
                <Flex gap="1rem" align="center">
                  <Text>{domain.domain}</Text>
                </Flex>
              </Flex>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel
              p="0"
              bg="backgroundPrimary"
              borderRadius="0 0 8px 8px"
            >
              <WalletAddress
                onCancel={onCancel}
                onCopyAddress={onCopyAddress}
                walletAddress={walletAddress}
                connectorId={connectorId}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ) : (
        <WalletAddress
          onCancel={onCancel}
          onCopyAddress={onCopyAddress}
          walletAddress={walletAddress}
          connectorId={connectorId}
        />
      )}
      <Box mt="2rem">
        <ScannerLink
          scannerLink={scannerLink}
          onClickScanner={onClickScanner}
        />
      </Box>
    </ModalDrawer>
  );
};

export default AccountModal;
