import { AccountBalanceQuery, Client, Hbar } from "@hashgraph/sdk";

import {
  INITIAL_SAVE_DATA,
  SaveData,
  StorageService,
  WalletExtensionType,
} from "./storage";

import { useDispatch, useSelector } from "react-redux";

import { walletLabelMapping } from "../../constants/walletMenuOptions";
import { useEffect, useMemo, useState } from "react";
import { config } from "../../config/hedera";
import {
  updateWalletData,
  clearWalletData,
  clearUserData,
  updateStakeData,
  clearStakeData,
  updateUserData,
} from "store";
import { EVENTS, TRANSACTION_TYPE } from "../../constants/events";
import { NATIVE_TOKEN_MULTIPLIER } from "../constants";
import {
  formatErrorData,
  getErrorData,
  getFormatedResponse,
  getMaintainanceData,
} from "../utils";

import {
  TOKENTYPE,
  useStake,
  useUnstake,
  useWithdraw,
  useAllowance,
  useAssociate,
  useLimits,
} from "@stader-labs/web-sdk"; // from "@stader-labs/web-sdk"

import { trackEvent } from "../../utils/firebase";
import {
  MAX_STAKE,
  STAKE_CTA,
  STAKE_FAILED,
  TOKENX_APPROVE_FAILED,
  TOKEN_APPROVE_CTA,
  TOKEN_APPROVE_FAILED,
  UNSTAKE_CTA,
  UNSTAKE_FAILED,
  WALLET_CONNECTED_SUCCESSFULLY,
  WITHDRAWAL_FAILED,
  WALLET_CONNECTION_FAILED,
  TOKEN_ASSOCIATE_CTA,
  TOKEN_ASSOCIATE_FAILED,
  TOKENX_ASSOCIATE_FAILED,
} from "../../constants/firebase";
import { TXN_TYPES } from "../../constants/transactions";
import TransactionModal from "../../components/TransactionModal";
import { DAYS_TO_WAIT_FOR_WITHDRAW } from "../../constants/constants";
import { sentryErrorLog } from "../../utils/sentryLog";
import { useToast, Text } from "@chakra-ui/react";
import Icon from "../../components/Icon";
import { ErrorToast } from "icons";
import useQueryReferral from "../../hooks/useQueryReferral";

const BLADEWALLET = "blade";
const HASHPACKWALLET = "hashpack";

const token = process.env.NEXT_PUBLIC_TOKEN || "";
const network = process.env.NEXT_PUBLIC_NETWORK || "mainnet";
const wallets = [HASHPACKWALLET, BLADEWALLET];

const APP_CONFIG: any = {
  name: "Stader HBAR staking",
  description:
    "Liquid staking with Stader. Stake HBAR with Stader to earn rewards while keeping full control of your staked tokens. Start earning rewards in just a few clicks",
  icon: "https://hedera.staderlabs.com/static/stader_logo.svg",
};

const debug = true;

const metadata = APP_CONFIG;

enum ConnectType {
  CHROME_EXTENSION,
  BLADE_WALLET,
  INSTALL_EXTENSION,
  HASHPACK_WALLET,
}
const WalletStatus = {
  WALLET_CONNECTED: "WALLET_CONNECTED",
  INITIALIZING: "INITIALIZING",
  WALLET_NOT_CONNECTED: "WALLET_NOT_CONNECTED",
};
interface ServiceProviderProps {
  bladeService: any;
  hashConnect: any;
}

const ServiceProvider = ({
  bladeService,
  hashConnect,
}: ServiceProviderProps) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { withdraw: withdrawToken } = useWithdraw();
  const { approve: approveToken } = useAllowance();
  const { stake: stakeToken } = useStake();
  const { unstake: unstakeToken } = useUnstake();
  const { associateToken } = useAssociate();
  const { fetchMinMaxLimit } = useLimits();
  const stakeReferralId = useQueryReferral();

  const { emitter } = useSelector((state: any) => state.event);
  const { stakeAmount, unstakeAmount, txnLoader, tokenXAllowence } =
    useSelector((state: any) => state.stake);
  const { walletAddress, connectorID } = useSelector(
    (state: any) => state.wallet
  );
  const { tokenAmount, tokenXAmount, isNativeChain } = useSelector(
    (state: any) => state.user
  );

  const [saveData, setSaveInfo] = useState(INITIAL_SAVE_DATA);
  const [, setNetworkError] = useState<boolean>(false);

  const [connectedAccountType, setConnectedAccountType] =
    useState<WalletExtensionType>("");

  const [statusData, setStatusData] = useState<string>(
    WalletStatus.INITIALIZING
  );

  const [isBladeExtensionInstalled, setBladeExtensionInstalled] =
    useState<boolean>(false);
  const [IsExtensionInstalled, setIsExtensionInstalled] =
    useState<boolean>(false);
  const [, setInstalledExtensions] = useState<any>();
  const [persistantData, setPersistantData] = useState<any>({});

  const signer = useMemo(
    () => (connectorID === BLADEWALLET ? bladeService : hashConnect),
    [connectorID, bladeService, hashConnect]
  );

  const fetchLimits = async () => {
    try {
      const data: any = await fetchMinMaxLimit();
      dispatch(updateStakeData({ limits: data }));
    } catch (err) {
      sentryErrorLog(err);
    }
  };

  useEffect(() => {
    dispatch(
      updateUserData({
        tokenSymbol: "HBAR",
        tokenXSymbol: "HBARX",
      })
    );
    fetchLimits();
  }, []);

  useEffect(() => {
    const connectors = [
      {
        name: "hashPack Wallet",
        id: HASHPACKWALLET,
        isInstalled: IsExtensionInstalled,
      },
      {
        name: "Blade Wallet",
        id: BLADEWALLET,
        isInstalled: isBladeExtensionInstalled,
      },
    ];
    dispatch(
      updateWalletData({
        connectors,
      })
    );
    dispatch(
      updateStakeData({
        blockExplorerURL: config.blockExplorerURL,
      })
    );
  }, [wallets, isBladeExtensionInstalled, IsExtensionInstalled]);

  const connect = async (type: ConnectType) => {
    try {
      if (type === ConnectType.BLADE_WALLET && !isBladeExtensionInstalled) {
        window.open(config.blade_extension_url, "_blank");
      } else if (type === ConnectType.BLADE_WALLET) {
        const account = await bladeService.loadWallet();
        const walletAddress = account.toString();
        setStatusData(WalletStatus.WALLET_CONNECTED);
        setConnectedAccountType(BLADEWALLET);
        dispatch(
          updateWalletData({
            connectorID: BLADEWALLET,
            walletName: BLADEWALLET,
            isConnected: true,
            walletAddress,
          })
        );
        const balance: any = await bladeService.getBalance();
        let x: any = { ...balance };
        x.hbars = Hbar.fromString(x.hbars);
        formatBladeBalance(x);
        const walletData = bladeService.getWalletData();
        saveDataInLocalStorage(walletData, BLADEWALLET);
      } else if (IsExtensionInstalled) {
        setConnectedAccountType(HASHPACKWALLET);
        hashConnect.connectToLocalWallet(saveData?.pairingString);
      } else {
        window.open(config.extension_url, "_blank");
      }
    } catch (error: any) {
      trackEvent(WALLET_CONNECTION_FAILED, {
        wallet_name:
          walletLabelMapping[
            type === ConnectType.BLADE_WALLET ? BLADEWALLET : HASHPACKWALLET
          ],
        reason: error.message,
      });
    }
  };

  const initializeHashConnect = async () => {
    const saveData = INITIAL_SAVE_DATA;

    const localData: any = StorageService.loadLocalData();
    await bladeService.checkExtensionInstalled();
    setBladeExtensionInstalled(bladeService.isExtensionInstalled);
    bladeService.setSigner();
    try {
      if (!localData) {
        //first init and store the private for later
        let initData = await hashConnect.initialize(
          metadata ?? APP_CONFIG,
          network,
          debug
        );
        saveData.privateKey = initData.privateKey;

        saveData.topic = initData.topic;
        //generate a pairing string, which you can display and generate a QR code from
        saveData.pairingString = initData.pairingString;
      } else {
        if (localData.walletExtensionType == HASHPACKWALLET) {
          await hashConnect.init(metadata ?? APP_CONFIG, localData?.privateKey);
          setInstalledExtensions(localData?.pairedWalletData);
          setIsExtensionInstalled(true);
          await hashConnect.connect(
            localData?.topic,
            localData?.pairedWalletData ?? metadata
          );
        } else {
          bladeService.setSigner();
          connect(ConnectType.BLADE_WALLET);
        }
      }
    } catch (error) {
      setNetworkError(true);
    } finally {
      if (localData) {
        setSaveInfo({ ...saveData, ...localData });
        setConnectedAccountType(localData.walletExtensionType);
        //   dispatch(setSelectedAccount(localData.accountIds[0]));
        if (localData.accountIds) {
          dispatch(
            updateWalletData({
              walletAddress: localData.accountIds[0],
            })
          );
          await getAccounts(localData.accountIds[0]);
        }
      } else {
        setSaveInfo({ ...saveData, ...localData });
      }
      if (statusData === WalletStatus.INITIALIZING) {
        setStatusData(WalletStatus.WALLET_NOT_CONNECTED);
      }
    }
    return localData;
  };

  const saveDataInLocalStorage = async (
    data: any,
    extensionType?: WalletExtensionType
  ) => {
    const { metadata, ...restData } = data;
    const saveObj: SaveData = {
      ...saveData,
      pairedWalletData: metadata,
      pairedAccounts: restData.accountIds,
      walletExtensionType: extensionType || connectedAccountType,
      ...restData,
    };
    setSaveInfo(saveObj);
    StorageService.saveData(saveObj);
    if (
      saveObj !== undefined &&
      saveObj.accountIds &&
      saveObj.accountIds[0] !== undefined
    ) {
      trackEvent(WALLET_CONNECTED_SUCCESSFULLY, {
        wallet_name:
          walletLabelMapping[
            extensionType === BLADEWALLET ? BLADEWALLET : HASHPACKWALLET
          ],
        wallet_address: saveObj.accountIds[0],
      });

      dispatch(
        updateWalletData({
          walletAddress: saveObj.accountIds[0],
        })
      );
      await getAccounts(saveObj.accountIds[0]!);
    }
  };

  const foundExtensionEventHandler = (data: any) => {
    // Do a thing
    if (statusData === WalletStatus.INITIALIZING) {
      setStatusData(WalletStatus.WALLET_NOT_CONNECTED);
      setInstalledExtensions(data as any);
      setIsExtensionInstalled(true);
    }
  };

  const pairingEventHandler = (data: any) => {
    // Save Data to localStorage
    saveDataInLocalStorage(data, HASHPACKWALLET);
  };

  const transactionHandler = () => {
    // console.log("received data", data);
  };

  useEffect(() => {
    hashConnect.listeners.foundExtensionEvent.once(foundExtensionEventHandler);
    hashConnect.listeners.pairingEvent.on(pairingEventHandler);
    hashConnect.listeners.transactionEvent.on(transactionHandler);

    hashConnect.listeners.transactionResolver = () => {};
    initializeHashConnect().then(() => {
      if (StorageService.loadLocalData()) return;
    });

    return () => {
      //   clearInterval(tvlInterval);
      hashConnect.listeners.foundExtensionEvent.off(foundExtensionEventHandler);
      hashConnect.listeners.pairingEvent.off(pairingEventHandler);
      hashConnect.listeners.transactionEvent.off(transactionHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedAccountType]);

  const disconnect = () => {
    setStatusData(WalletStatus.WALLET_NOT_CONNECTED);
    StorageService.remove();
    initializeHashConnect();
  };

  const formatHashPackBalance = (balance: any) => {
    const hbar = balance.hbars.toTinybars().toNumber();
    let hbarx = 0;
    const _isAssociated = balance.tokens?._map?.has(config.ids.tokenId);
    if (_isAssociated) {
      const token = balance.tokens?._map.get(config.ids.tokenId);
      hbarx = token?.toNumber() || 0;
    }
    dispatch(
      updateUserData({
        tokenAmount: hbar / NATIVE_TOKEN_MULTIPLIER,
        tokenXAmount: hbarx / NATIVE_TOKEN_MULTIPLIER,
      })
    );
    dispatch(
      updateWalletData({
        connectorID: HASHPACKWALLET,
        walletName: HASHPACKWALLET,
        isConnected: true,
      })
    );
  };

  const formatBladeBalance = (balance: any) => {
    const hbar = balance.hbars.toTinybars().toNumber();
    let hbarx = 0;
    const token = balance.tokens.filter(
      (t: any) => t.tokenId === config.ids.tokenId
    );
    if (token.length > 0) {
      hbarx = Number(token[0].balance);
    }
    dispatch(
      updateUserData({
        tokenAmount: hbar / NATIVE_TOKEN_MULTIPLIER,
        tokenXAmount: hbarx / NATIVE_TOKEN_MULTIPLIER,
      })
    );
    dispatch(
      updateWalletData({
        connectorID: BLADEWALLET,
        walletName: BLADEWALLET,
        isConnected: true,
      })
    );
  };

  const getAccounts = async (accountId: string) => {
    //Create the account info query
    try {
      if (connectedAccountType === HASHPACKWALLET) {
        const query = new AccountBalanceQuery().setAccountId(accountId);
        const client = Client.forName(config.network.name);
        // Sign with client operator private key and submit the query to a Hedera network
        const balance = await query.execute(client);
        formatHashPackBalance(balance);
      } else if (connectedAccountType === BLADEWALLET) {
        const balance = await bladeService.getBalance();
        let x: any = { ...balance };
        x.hbars = Hbar.fromString(x.hbars);
        formatBladeBalance(x);
      }
      if (connectedAccountType !== "") {
        setStatusData(WalletStatus.WALLET_CONNECTED);
        // checkAllowance(accountId);
      }
    } catch (error: any) {
      sentryErrorLog(error);
      setNetworkError(true);
    }
  };

  const checkMaintainance = async () => {
    const id = "maintainance";
    const maintainanceData = await getMaintainanceData();
    if (maintainanceData?.isActive && !toast.isActive(id)) {
      toast({
        id,
        description: (
          <Text fontWeight={600} fontSize="16px">
            Transactions unavailable: Sync with protocol staking rewards in
            progress
          </Text>
        ),
        status: "error",
        icon: <Icon Icon={ErrorToast} width="24px" height="24px" />,
        duration: 5000,
        position: "top",
        isClosable: false,
      });
    }
    return maintainanceData?.isActive;
  };

  const startTransaction = (transactionType: string) => {
    setPersistantData((prev: any) => ({
      ...prev,
      hasUserDenied: false,
      isTxnProcessing: true,
      transactionType: transactionType,
    }));
    dispatch(
      updateStakeData({
        isUnstaking: transactionType === TXN_TYPES.UNSTAKE,
        isStaking: transactionType === TXN_TYPES.STAKE,
        txnLoader: true,
      })
    );
  };

  const endTransaction = () => {
    dispatch(
      updateStakeData({
        isUnstaking: false,
        isStaking: false,
      })
    );
    setPersistantData((prev: any) => ({
      ...prev,
      isTxnProcessing: false,
    }));
    getAccounts(walletAddress);
  };

  const getErrorMessage = (err: any, errorMessage: string) => {
    if (connectorID === HASHPACKWALLET) {
      return err?.error || err?.message;
    }
    return err?.message || errorMessage;
  };

  const errorTransaction = (error: any, errorMessage: string) => {
    sentryErrorLog(error);

    setPersistantData((prev: any) => ({
      ...prev,
      hasUserDenied: formatErrorData(error),
      error: getErrorMessage(error, errorMessage),
    }));
  };

  const handleStake = async () => {
    const isUnderMaintainance = await checkMaintainance();
    if (isUnderMaintainance) {
      return;
    }
    trackEvent(STAKE_CTA, {
      stake_amount_entered: parseFloat(stakeAmount),
    });
    if (+stakeAmount === tokenAmount) {
      trackEvent(MAX_STAKE, {
        stake_amount_entered: parseFloat(stakeAmount),
      });
    }
    startTransaction(TXN_TYPES.STAKE);

    stakeToken(
      walletAddress,
      signer,
      stakeAmount,
      {
        topic: saveData.topic,
        isNativeChain,
        connectorID,
      },
      stakeReferralId
    )
      .then(async (response: any) => {
        const res: any = await getFormatedResponse(response, connectorID);
        if (res.success) {
          setPersistantData((prev: any) => ({
            ...prev,
            hasUserDenied: false,
            hash: res?.response?.transactionHash || "",
          }));
        } else {
          setPersistantData((prev: any) => ({
            ...prev,
            ...getErrorData(res),
          }));
        }
        dispatch(updateStakeData({ isStaking: false, stakeAmount: "" }));
      })
      .catch((error: any) => {
        errorTransaction(error, "staking failed");
        trackEvent(STAKE_FAILED, {
          stake_amount_entered: parseFloat(stakeAmount),
          reason: getErrorMessage(error, "staking failed"),
        });
      })
      .finally(() => {
        endTransaction();
      });
  };

  const handleUnStake = async () => {
    const isUnderMaintainance = await checkMaintainance();
    if (isUnderMaintainance) {
      return;
    }
    trackEvent(UNSTAKE_CTA, {
      unstake_amount_entered: parseFloat(unstakeAmount),
    });
    if (+unstakeAmount === tokenXAmount) {
      trackEvent(MAX_STAKE, {
        unstake_amount_entered: parseFloat(unstakeAmount),
      });
    }
    startTransaction(TXN_TYPES.UNSTAKE);
    unstakeToken(
      walletAddress,
      signer,
      unstakeAmount,
      {
        topic: saveData.topic,
        isNativeChain,
        connectorID,
      },
      stakeReferralId
    )
      .then(async (response: any) => {
        const res: any = await getFormatedResponse(response, connectorID);
        if (res.success) {
          setPersistantData((prev: any) => ({
            ...prev,
            hasUserDenied: false,
            hash: res?.response?.transactionHash || "",
          }));
          dispatch(updateUserData({ approvedTokenX: false }));
        } else {
          setPersistantData((prev: any) => ({
            ...prev,
            ...getErrorData(res),
          }));
        }
        dispatch(updateStakeData({ isUnstaking: false, unstakeAmount: "" }));
      })
      .catch((error: any) => {
        errorTransaction(error, "unstaking failed");
        trackEvent(UNSTAKE_FAILED, {
          unstake_amount_entered: parseFloat(unstakeAmount),
          reason: getErrorMessage(error, "unstaking failed"),
        });
      })
      .finally(() => {
        endTransaction();
      });
  };

  const handleClaim = async (transaction: any) => {
    const isUnderMaintainance = await checkMaintainance();
    if (isUnderMaintainance) {
      return;
    }

    trackEvent(UNSTAKE_CTA, {
      unstake_amount_entered: parseFloat(transaction.amount),
    });

    startTransaction(TXN_TYPES.WITHDRAW);

    withdrawToken(
      walletAddress,
      signer,
      transaction.id,
      {
        topic: saveData.topic,
        isNativeChain,
        connectorID,
      },
      transaction.oldContract
    )
      .then(async (response: any) => {
        const res: any = await getFormatedResponse(response, connectorID);
        if (res.success) {
          setPersistantData((prev: any) => ({
            ...prev,
            hasUserDenied: false,
            hash: res?.response?.transactionHash || "",
          }));
        } else {
          setPersistantData((prev: any) => ({
            ...prev,
            ...getErrorData(res),
          }));
        }
      })
      .catch((error: any) => {
        errorTransaction(error, "withdrawal failed");
        trackEvent(WITHDRAWAL_FAILED, {
          reason: getErrorMessage(error, "withdrawal failed"),
        });
      })
      .finally(() => {
        endTransaction();
      });
  };

  const handleTokenAssociate = async (
    transaction: any,
    tokenType: TOKENTYPE
  ) => {
    const isUnderMaintainance = await checkMaintainance();
    if (isUnderMaintainance) {
      return;
    }

    trackEvent(TOKEN_ASSOCIATE_CTA);

    startTransaction(TXN_TYPES.XTOKEN_ASSOCIATE);

    associateToken(walletAddress, signer, {
      topic: saveData.topic,
      isNativeChain,
      connectorID,
    })
      .then(async (response: any) => {
        const res: any = await getFormatedResponse(response, connectorID);
        if (res.success) {
          if (tokenType === TOKENTYPE.TOKENX) {
            dispatch(
              updateStakeData({
                tokenXAssociated: true,
              })
            );
          }
          setPersistantData((prev: any) => ({
            ...prev,
            hasUserDenied: false,
            hash: res?.response.transactionHash || "",
          }));
        } else {
          setPersistantData((prev: any) => ({
            ...prev,
            ...getErrorData(res),
          }));
        }
      })
      .catch((error: any) => {
        errorTransaction(error, "associate failed");
        trackEvent(
          tokenType === TOKENTYPE.TOKEN
            ? TOKEN_ASSOCIATE_FAILED
            : TOKENX_ASSOCIATE_FAILED,
          {
            reason: getErrorMessage(error, "associate failed"),
          }
        );
      })
      .finally(() => {
        endTransaction();
      });
  };

  const handleApprove = async (transaction: any, tokenType: TOKENTYPE) => {
    const isUnderMaintainance = await checkMaintainance();
    if (isUnderMaintainance) {
      return;
    }

    if (tokenType === TOKENTYPE.TOKEN) {
      trackEvent(TOKEN_APPROVE_CTA, {
        stake_amount_entered: parseFloat(transaction.amount),
      });
    } else {
      trackEvent(TOKEN_APPROVE_CTA, {
        unstake_amount_entered: parseFloat(transaction.amount),
      });
    }

    startTransaction(TXN_TYPES.TOKEN_APPROVE);
    approveToken(
      walletAddress,
      signer,
      tokenType === TOKENTYPE.TOKEN
        ? stakeAmount
        : (Number(tokenXAllowence) + Number(unstakeAmount)).toString(),
      tokenType,
      {
        topic: saveData.topic,
        isNativeChain,
        connectorID,
      }
    )
      .then(async (response: any) => {
        const res: any = await getFormatedResponse(response, connectorID);
        if (res.success) {
          if (tokenType === TOKENTYPE.TOKENX) {
            dispatch(
              updateUserData({
                approvedTokenX: true,
              })
            );
          }
          setPersistantData((prev: any) => ({
            ...prev,
            hasUserDenied: false,
            hash: res?.response.transactionHash || "",
          }));
        } else {
          setPersistantData((prev: any) => ({
            ...prev,
            hasUserDenied: res.error.includes("denied"),
            error: res.error.replace(/_/g, " ").toLowerCase(),
            hash: res?.hash || "",
          }));
        }
      })
      .catch((error: any) => {
        errorTransaction(error, "approval failed");
        trackEvent(
          tokenType === TOKENTYPE.TOKEN
            ? TOKEN_APPROVE_FAILED
            : TOKENX_APPROVE_FAILED,
          {
            reason: getErrorMessage(error, "approval failed"),
          }
        );
      })
      .finally(() => {
        endTransaction();
      });
  };

  const handleTransactions = (transaction: any) => {
    switch (transaction.type) {
      case TRANSACTION_TYPE.STAKE:
        return handleStake();
      case TRANSACTION_TYPE.UNSTAKE:
        return handleUnStake();
      case TRANSACTION_TYPE.WITHDRAW:
        return handleClaim(transaction);
      case TRANSACTION_TYPE.APPROVE_TOKEN:
        return handleApprove(transaction, TOKENTYPE.TOKEN);
      case TRANSACTION_TYPE.APPROVE_TOKEN_X:
        return handleApprove(transaction, TOKENTYPE.TOKENX);
      case TRANSACTION_TYPE.ASSOCIATE_TOKEN_X:
        return handleTokenAssociate(transaction, TOKENTYPE.TOKENX);
    }
  };

  const attachEventListners = (event: any) => {
    switch (event.name) {
      case EVENTS.HANDLE_CONNECT:
        if (event.data) {
          if (event.data.id === BLADEWALLET) {
            connect(ConnectType.BLADE_WALLET);
          } else if (event.data.id === HASHPACKWALLET) {
            connect(ConnectType.HASHPACK_WALLET);
          }
        }
        break;

      case EVENTS.HANDLE_DISCONNECT:
        dispatch(clearWalletData());
        dispatch(clearUserData());
        dispatch(clearStakeData());
        disconnect();
        break;

      case EVENTS.HANDLE_TRANSACTION:
        if (event.data) {
          handleTransactions(event.data);
        }
        break;
    }
  };

  useEffect(() => {
    attachEventListners(emitter);
  }, [emitter]);

  useEffect(() => {
    if (walletAddress) {
      const interval = setInterval(() => {
        getAccounts(walletAddress);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [walletAddress]);

  const resetLatestTransactionInfo = () => {
    dispatch(
      updateStakeData({
        txnLoader: false,
        isStaking: false,
        isUnstaking: false,
      })
    );
    setPersistantData({ isTxnProcessing: false });
  };

  const handleTxViewOnEtherScan = () => {
    const url = `${config.blockExplorerURL}/transaction/${persistantData.hash}`;
    window.open(url, "_blank");
  };

  return (
    <>
      {txnLoader && (
        <TransactionModal
          isOpen={txnLoader}
          hash={persistantData.hash}
          isTxnProcessing={persistantData.isTxnProcessing}
          error={persistantData.error}
          daysToWaitForWithdraw={DAYS_TO_WAIT_FOR_WITHDRAW}
          hasUserDenied={persistantData.hasUserDenied}
          closeAlert={resetLatestTransactionInfo}
          handleTxView={handleTxViewOnEtherScan}
          transactionType={persistantData.transactionType}
          token={token}
          network={network}
          chainId={config.ids.chainId}
        />
      )}
    </>
  );
};

export default ServiceProvider;
