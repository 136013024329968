import React, { useMemo, useRef } from "react";

import { Flex, Box, useDimensions } from "@chakra-ui/react";

import { TextSecondary } from "theme";

import { shortenAddress } from "../../utils/common";
import { walletIconMapping } from "../../constants/walletMenuOptions";
import CopyIcon from "../CopyIcon";
import BaseImage from "../Image";

interface WalletInfoProps {
  walletAddress?: string;
  connectorId: string;
  onCopyAddress(): void;
  onClick(isVisible: boolean): void;
  domain?: { domain: string; isSpaceId: boolean };
}

const WalletInfo = ({
  walletAddress,
  connectorId,
  onCopyAddress,
  onClick,
  domain,
}: WalletInfoProps) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(elementRef, true);

  const shortenWalletAddress = useMemo(() => {
    return shortenAddress(walletAddress || "", dimensions?.borderBox?.width);
  }, [walletAddress, dimensions?.borderBox?.width]);

  const shortenedDomain = useMemo(() => {
    if (!domain?.domain) return;
    return shortenAddress(domain.domain, dimensions?.borderBox?.width);
  }, [domain, dimensions?.borderBox?.width]);

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        color="textPrimary"
        h={{ base: "32px", lg: "56px" }}
        w={{ base: "116px", lg: "240px" }}
        _light={{
          color: {
            base: "#FFFFFF",
            lg: "primary",
          },
        }}
        ref={elementRef}
        p={{ base: "1rem 12px", lg: "1rem 1.5rem" }}
        bg="backgroundSecondary"
        borderRadius="0.5rem"
        cursor="pointer"
        onClick={() => onClick(true)}
      >
        {connectorId && (
          <Box
            h={{ base: "22px", lg: "24px" }}
            w={{ base: "16px", lg: "24px" }}
          >
            <BaseImage
              src={walletIconMapping[connectorId.toLowerCase()]}
              alt="wallet"
              width={24}
              height={24}
            />
          </Box>
        )}
        <Flex alignItems="center">
          <TextSecondary
            fontWeight="400"
            fontSize={{ base: "10px", lg: "14px" }}
            ml={{ base: "4px", lg: "12px" }}
          >
            {shortenedDomain || shortenWalletAddress}
          </TextSecondary>
          {walletAddress && (
            <Box
              as="button"
              w={{ base: "16px", lg: "24px" }}
              h={{ base: "16px", lg: "24px" }}
              ml={{ base: "4px", lg: "12px" }}
              cursor="pointer"
            >
              <CopyIcon
                address={walletAddress}
                onClick={() => onCopyAddress && onCopyAddress()}
              />
            </Box>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default WalletInfo;
