import { extendTheme } from "@chakra-ui/react";
import { commonStyles } from "./common";

export const styles = {
  global: () => ({
    "html, body": {
      padding: 0,
      margin: 0,
      fontFamily: "Gilroy, Helvetica, sans-serif, system-ui",
    },
    body: {
      bg: "backgroundPrimary",
      color: "textPrimary",
    },
  }),
};

export const getCustomTheme = (colors: any, semanticTokens: any) => {
  return extendTheme({
    colors: colors,
    // fonts: {
    //   body: `Poppins, Montserrat, -apple-system, BlinkMacSystemFont, Roboto, sans-serif;`,
    // },
    styles,
    semanticTokens: semanticTokens,
    ...commonStyles,
  });
};
