import { IIndexable } from "@customtypes/common";

export const descriptionMappings: IIndexable = {
  BNB: "BNB Staking - Stake & earn rewards with BNB Staking. Access binance staking across the BNB ecosystem with Stader Labs.",
  POLYGON:
    "Polygon Staking - Stake & earn rewards with polygon liquid matic staking. Access matic staking across the Polygon ecosystem with Stader Labs.",
  ETH: "Ethereum Staking - Stake Ether with Stader & earn rewards. Earn Eth2 rewards across Ethereum ecosystem.",
  HEDERA:
    "Hedera Staking - Stake Hbar/hedera Crypto With Stader & earn rewards while Keeping Full Control of Your Staked Tokens. Start staking with Stader labs.",
};

export const titleMappings: IIndexable = {
  BNB: "BNB Staking - Earn Liquid Binance Staking Rewards | Stader Labs",
  POLYGON: "Polygon Staking - Earn Liquid Matic Staking Rewards | Stader Labs",
  ETH: "Ethereum Staking - Stake Ether & Earn Eth2 Rewards | Stader Labs",
  HEDERA: "Hedera Staking - Liquid Staking With Hbar Simplified|Stader Labs",
};

export const keyWordsMapping: IIndexable = {
  BNB: "bnb staking, binance staking, crypto binance, binance staking rewards",
  ETH: "ether staking, eth2 staking rewards, eth2 staking",
  HEDERA: "hedera staking, hedera crypto, hbarcrypto",
  POLYGON:
    "polygon staking, staking polygon, polygon matic staking, matic staking",
};

export const headingMappings: IIndexable = {
  BNB: "Binance Staking | BNB",
  ETH: "Ethereum Staking | Eth2 Rewards",
  HEDERA: "Liquid Hedera Staking | HBAR",
  POLYGON: "Polygon Staking | Matic",
};

export const headersTags: IIndexable = {
  ETH: {
    h1: ["ETH Staking | Stake Ethereum & Earn Rewards"],
    h2: [
      "FAQ About Ethereum Staking",
      "Know more about ETH Staking",
      "What is Ethereum?",
      "What is ETH Staking?",
      "Why should you stake your ETH?",
      "How much is the reward for staking Ethereum?",
      "What are the possible risks of staking ETH?",
    ],
    h3: [
      "What are the benefits of staking Ethereum?",
      "What is the minimum staking period for Ethereum?",
      "How much Ethereum do I need to stake?",
      "What are the risks of staking Ethereum?",
      "How does Ethereum staking differ from mining?",
      "What are the rewards for staking Ethereum?",
      "How often are rewards distributed for Ethereum staking?",
      "What happens to my staked Ethereum if the price drops?",
      "Is it safe to stake Ethereum?",
    ],
  },
  POLYGON: {
    h1: ["Polygon staking | Maticx Rewards"],
    h2: [
      "FAQs about Polygon Staking (MaticX)",
      "Know more about Matic Staking & MaticX Rewards",
      "Is MATIC a layer 1 or 2 token?",
      "What is meant by a Layer 2 blockchain?",
      "How can I stake MATIC if I'm holding MATIC on a Centralized Exchange [CEX]?",
      "Which wallets are best for MATIC staking?",
      "Is MATIC good to stake?",
    ],
    h3: [
      "What is MATIC staking and how does it work?",
      "How do I start staking my Matic tokens with Stader?",
      "What’s the minimum amount required to start staking Matic with Stader?",
      "Is there a Matic Staking Calculator?",
      "How much can I get rewarded by staking Matic?",
      "How often do I receive rewards for staking Matic?",
      "What is MaticX?",
      "How does MaticX work?",
      "What are the major benefits of staking Matic with Stader?",
      "What are the risks of staking Matic with Stader?",
      "Can I unstake my MATIC tokens at any time?",
      "How do I withdraw my staked Matic?",
    ],
  },
  BNB: {
    h1: ["BNB staking | BNBx Rewards"],
    h2: [
      "FAQ About BNB Staking",
      "Know more about BNB Staking",
      "What is BNB Token?",
      "How Does BNB Staking Work?",
      "Is BNB worth staking?",
      "How can I stake BNB if I'm holding BNB on a Centralized Exchange [CEX]?",
      "What wallets to use for BNB staking?",
    ],
    h3: [
      "What is BNB staking?",
      "How to do BNB staking at Stader?",
      "How much BNB do I need for staking?",
      "How does Stader BNB ensure security?",
      "How are the BNB staking rewards accrued?",
      "How much rewards can you earn by staking BNB?",
      "How often do I receive rewards for staking on the BNB network?",
      "Is there a BNB Staking calculator to calculate staking rewards with Stader?",
      "What are the benefits of staking BNB with Stader?",
      "What are the risks of staking BNB?",
      "Can I unstake my BNB tokens at any time?",
      "How do I withdraw my staked BNB tokens?",
    ],
  },
};
