import React from 'react';
import { Link, Button, Center } from '@chakra-ui/react';

interface ScannerLinkProps {
  scannerLink: string;
  onClickScanner(): void;
}

const ScannerLink = ({ scannerLink, onClickScanner }: ScannerLinkProps) => {
  return (
    <Center>
      <Link
        fontWeight={{
          base: '700',
          md: '600',
        }}
        fontSize={{
          base: '10px',
          md: '14px',
        }}
        cursor="pointer"
        href={scannerLink}
        target="_blank"
        rel="noreferrer"
        onClick={onClickScanner}
        _hover={{
          borderBottom: 'none',
        }}
      >
        <Button
          p="12px 45px"
          variant="unstyled"
          borderRadius="8px"
          bg="primary"
          color="white"
          h="48px"
          _dark={{
            bg: 'white',
            color: '#142528',
          }}
        >
          View on explorer
        </Button>
      </Link>
    </Center>
  );
};

export default ScannerLink;
