import { TestNetConfig } from "./testnet";
import { MainNetConfig } from "./mainnet";
import { Chain } from "wagmi/chains";

export const SDChainConfig: Chain = {
  id: 1,
  name: "ETH Smart Chain",
  network: "eth",
  nativeCurrency: {
    symbol: "SD",
    decimals: 18,
    name: "SD",
  },
  rpcUrls: {
    default: {
      http: [
        "https://eth-mainnet.g.alchemy.com/v2/hjyB9RgJj-d6ofClhQGO6v5njFNyuKU5",
      ],
    },
    public: {
      http: [
        "https://eth-mainnet.g.alchemy.com/v2/hjyB9RgJj-d6ofClhQGO6v5njFNyuKU5",
      ],
    },
  },
  blockExplorers: {
    default: { name: "Etherscan", url: "https://etherscan.io" },
  },
  testnet: false,
};

export const SDTestnet: Chain = {
  id: 5,
  name: "Goerli",
  network: "eth",
  nativeCurrency: {
    symbol: "SD",
    decimals: 18,
    name: "SD",
  },
  rpcUrls: {
    default: {
      http: [
        "https://eth-goerli.g.alchemy.com/v2/hjyB9RgJj-d6ofClhQGO6v5njFNyuKU5",
      ],
    },
    public: {
      http: [
        "https://eth-goerli.g.alchemy.com/v2/hjyB9RgJj-d6ofClhQGO6v5njFNyuKU5",
      ],
    },
  },
  blockExplorers: {
    default: { name: "Etherscan", url: "https://goerli.etherscan.io" },
  },
  testnet: true,
};

export const getSDconfig = (network: string) =>
  network === "mainnet" ? MainNetConfig : TestNetConfig;

export const getSDChains = (network: string) => {
  return network === "mainnet" ? [SDChainConfig] : [SDTestnet];
};
