import React, { ReactNode } from "react";
import {
  BoxProps,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Show,
} from "@chakra-ui/react";

interface ModalDrawerProps {
  isOpen: boolean;
  closeAlert: () => void;
  children: ReactNode;
  header?: ReactNode;
  headerProps?: BoxProps;
  contentProps?: BoxProps;
  bodyProps?: BoxProps;
}

const ModalDrawer = ({
  isOpen,
  closeAlert,
  children,
  header,
  headerProps = {},
  contentProps = {},
  bodyProps = {},
}: ModalDrawerProps) => {
  return (
    <>
      <Show above="md">
        <Modal isOpen={isOpen} onClose={closeAlert} isCentered>
          <ModalOverlay bg="modalOverlay" />
          <ModalContent
            minW="560px"
            bg="backgroundSecondary"
            borderRadius="1.5rem"
            boxShadow="0px 32px 16px rgba(0, 0, 0, 0.12)"
            {...contentProps}
          >
            {header && <ModalHeader {...headerProps}>{header}</ModalHeader>}
            <ModalBody p="0" {...bodyProps}>
              {children}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Show>

      <Show below="md">
        <Drawer isOpen={isOpen} onClose={closeAlert} placement="bottom">
          <DrawerOverlay bg="modalOverlay" />
          <DrawerContent
            bg="backgroundSecondary"
            borderTopRadius="2rem"
            maxH="75%"
            {...contentProps}
          >
            {header && <DrawerHeader {...headerProps}>{header}</DrawerHeader>}
            <DrawerBody p="0" {...bodyProps}>
              <Center flexDirection="column" fontWeight="600">
                {children}
              </Center>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Show>
    </>
  );
};

export default ModalDrawer;
