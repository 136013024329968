import { useState } from "react";
import { Button } from "@chakra-ui/react";

import AddXSDMessageModal from "../AddXSDMessageModal";

import { ADD_LIQUID_TOKEN } from "../../constants/firebase";
import { useSelector } from "react-redux";
import { trackEvent } from "../../utils/firebase";

interface AddXSymbolProps {
  addXSymbolCTA?: string;
}

const AddXSymbol = ({ addXSymbolCTA }: AddXSymbolProps) => {
  const { activeConnector, config } = useSelector((state: any) => state.wallet);
  const { tokenAmount, tokenXAmount, tokenXSymbol, isEmbed, isSafeApp } =
    useSelector((state: any) => state.user);
  const [errorAddingXSD, setErrorAddingXSD] = useState("");
  const [shouldDisplayAddXSDModal, setShouldDisplayXSDModal] = useState(false);
  const [isTxnProcessing, setIsTxnProcessing] = useState(false);

  const isIframeEnv = isEmbed || isSafeApp;

  if (isIframeEnv) {
    return null;
  }

  const addXTokenToWallet = async () => {
    trackEvent(ADD_LIQUID_TOKEN, {
      token_balance: tokenAmount,
      liquidtoken_balance: tokenXAmount,
    });

    setIsTxnProcessing(true);
    setShouldDisplayXSDModal(true);

    if (activeConnector?.watchAsset) {
      try {
        await (activeConnector &&
          activeConnector?.watchAsset?.({
            address: config.contractAddresses.xtoken.token || "",
            symbol: config.nativeCurrency.xsymbol,
          }));
      } catch (error) {
        const err = error as any;
        if (err instanceof Error || err?.message) {
          setErrorAddingXSD(err.message);
        } else {
          setErrorAddingXSD(`Error Adding ${tokenXSymbol}`);
        }
      } finally {
        setIsTxnProcessing(false);
      }
    } else {
      const provider = await activeConnector?.getProvider();
      await provider.sendAsync(
        {
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: config.contractAddresses.xtoken.token,
              symbol: config.nativeCurrency.xsymbol,
              decimals: config.nativeCurrency.decimals,
            },
          },
        },
        (err: any, added: any) => {
          setIsTxnProcessing(false);
          if (added) {
            console.log(`${tokenXSymbol}`);
          } else {
            if (err instanceof Error || err?.message) {
              setErrorAddingXSD(err.message);
            } else {
              setErrorAddingXSD(`Error Adding ${tokenXSymbol}`);
            }
          }
        }
      );
    }
  };

  return (
    <>
      {addXSymbolCTA ? (
        <Button
          variant="outline"
          size="sm"
          border="1px"
          borderRadius="6px"
          borderColor="primary"
          background="backgroundSecondary"
          fontSize="10px"
          fontWeight="500"
          ml="8px"
          h="24px"
          p="4px 16px"
          onClick={addXTokenToWallet}
          color="primary"
          _dark={{
            color: "textPrimary",
            border: "none",
            bg: "backgroundPrimary",
          }}
        >
          {addXSymbolCTA}
        </Button>
      ) : null}

      {shouldDisplayAddXSDModal && (
        <AddXSDMessageModal
          isOpen={shouldDisplayAddXSDModal}
          onCancel={() => {
            setShouldDisplayXSDModal(false);
            setErrorAddingXSD("");
          }}
          error={errorAddingXSD}
          isTxnProcessing={isTxnProcessing}
          token={config.nativeCurrency.xsymbol}
        />
      )}
    </>
  );
};

export default AddXSymbol;
