export const themeColors = {
  theme: {
    50: "#E6FFFA",
    100: "#B2F5EA",
    200: "#2FA8AF",
    300: "#4FD1C5",
    400: "#38B2AC",
    500: "#2FA8AF",
    600: "#2C7A7B",
    700: "#285E61",
    800: "#234E52",
    900: "#1D4044",
  },
};

export const semanticTokens = {
  colors: {
    primary: {
      default: "#07A658",
    },
    backgroundSecondary: {
      default: "#FFFFFF",
      _dark: "#1B3034",
    },
    backgroundPrimary: {
      default: "#F5F5F7",
      _dark: "#202022",
    },
    textPrimary: {
      default: "#142528",
      _dark: "#FFFFFF",
    },
    textSecondary: {
      default: "#3F6870",
      _dark: "#A1B6BA",
    },
    seperator: {
      default: "#AEDFCA",
      _dark: "#424242",
    },
    bannerLogoBackground: {
      default: "#8D66B4",
      _dark: "#FFFFFF",
    },
    tabBackground: {
      default: "#F9F9F9",
      _dark: "#2c2c2e4d",
    },
    iconBackground: {
      default: "#F5F5F7",
      _dark: "#424242",
    },
    error: {
      default: "#E53E3E",
    },
    success: {
      default: "#38A169",
    },
    warning: {
      default: "#ECC94B",
    },
    textInput: {
      default: "#ACB2B5",
      _dark: "#766A6A",
    },
    tooltipBackground: {
      default: "#FFFFFF",
      _dark: "#424242",
    },
    modalOverlay: {
      default: "#14252880",
      _dark: "#142528cc",
    },
    textTertiary: {
      default: "#A1B6BA",
      _dark: "#5D6D71",
    },
    closeIcon: {
      default: "#3C4852",
      _dark: "#3C4852",
    },
    primaryIconBG: {
      default: "#F3BA2F",
      _dark: "#FFFFFF",
    },
  },
};
