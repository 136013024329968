import { IIndexable } from "@customtypes/common";

export const SD = "SD";
export const BNB = "BNB";
export const ETH = "ETH";
export const POLYGON = "POLYGON";
export const FANTOM = "FANTOM";
export const HEDERA = "HEDERA";

interface CalculatorToken {
  [key: string]: string;
}

export const REWARDSCALCULATORMAP: CalculatorToken = {
  SD,
  BNB,
  ETH,
  POLYGON: "Matic",
  FANTOM: "FTM",
  HEDERA: "HBAR",
};

export const dappName: CalculatorToken = {
  SD,
  BNB,
  ETH,
  POLYGON: "Polygon",
  FANTOM: "Fantom",
  HEDERA: "Hedera",
};

const getWithdrawalsDays = () => {
  const TOKEN = process.env.NEXT_PUBLIC_TOKEN;
  switch (TOKEN) {
    case BNB:
      return "7-15";
    case ETH:
      return "7-10";
    case HEDERA:
      return "1";
    case FANTOM:
      return "7";
  }
  return "15";
};

export const STAKING_DISABLED_CHAINS = [FANTOM];

export const xTokenMapping: IIndexable = {
  bnbx: "BNBx",
  maticx: "MaticX",
  hbarx: "HBARX",
  ftmx: "sFTMX",
  ethx: "ETHx",
};

export const STADER_URL = "https://staderlabs.com/";
export const AUDIT_TOOLTIP_TEXT = "Click to know more on audits";
export const TVL_TOOLTIP_TEXT = "Total SD Staked";
export const TERMS_URL = "https://staderlabs.com/terms-of-service/";
export const PRIVACY_POLICY_URL = "https://staderlabs.com/privacy-policy/";
export const SUBSCRIPTIONS_URL = "https://common.staderlabs.com/subscriptions";
export const TOAST_DURATION = 5000;
export const DAYS_TO_WAIT_FOR_WITHDRAW = getWithdrawalsDays();

export const getTransitionFee = (usdPrice: number) => {
  const TOKEN = process.env.NEXT_PUBLIC_TOKEN;
  switch (TOKEN) {
    case HEDERA:
      return parseFloat((0.2 / usdPrice).toFixed(4));
    default:
      return null;
  }
};

export const showTransactionCost = () => {
  const TOKEN = process.env.NEXT_PUBLIC_TOKEN;
  switch (TOKEN) {
    case HEDERA:
      return false;
    default:
      return true;
  }
};

export const showTransactionFee = () => {
  const TOKEN = process.env.NEXT_PUBLIC_TOKEN;
  switch (TOKEN) {
    case HEDERA:
      return true;
    default:
      return false;
  }
};

export const TILL_DATE = "2024-05-31 16:00:00";

export const chainIconMapping = (chain: string) => {
  switch (chain) {
    case "arbitrum":
      return "/assets/images/chains/arbitrum.svg";
    case "optimism":
      return "/assets/images/chains/optimism.svg";
    case "eth":
      return "/assets/images/chains/eth.svg";
  }
  return "/assets/images/chains/optimism.svg";
};

export type BridgeData = {
  route_details: {
    route_from: string;
    route_to: string;
    token_from: string;
    token_to: string;
  };
  cta: Array<{
    title: string;
    link: string;
  }>;
};
