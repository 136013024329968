import { useEffect, useState } from "react";
import { useInterval, useIdle } from "react-use";
import { getStaderTVL } from "@stader-labs/web-sdk";

const POLLING_INTERVAL = 20000;

export const useStaderTVL = () => {
  const isIdle = useIdle(150e3);

  const [staderTVL, setStaderTVL] = useState("");

  useEffect(() => {
    (async function fetchStaderTVL() {
      try {
        const response = await getStaderTVL();

        setStaderTVL(response.totalUSD.toFixed(0));
      } catch (error) {
        // setStaderTVL("");
      }
    })();
  }, []);

  useInterval(() => {
    if (!isIdle) {
      (async function fetchStaderTVL() {
        try {
          const response = await getStaderTVL();

          setStaderTVL(response.totalUSD.toFixed(0));
        } catch (error) {
          // setStaderTVL("");
        }
      })();
    }
  }, POLLING_INTERVAL);

  return staderTVL;
};
