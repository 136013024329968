import { useEffect, useState } from "react";
import { useFirebaseEvent } from "../hooks/useFirebaseEvent";
import { AppProvider } from "@stader-labs/web-sdk"; // from "@stader-labs/web-sdk"
import { getTokenForWebSDK } from "../utils/common";
import { Token } from "../types/common";
import { config } from "../config/hedera";
import ServiceProvider from "./services/services";
import WebSDKServices from "./services/WebSDKServices";
import { useDispatch } from "react-redux";
import { updateWalletData } from "store";
const token = process.env.NEXT_PUBLIC_TOKEN || "";

var bladeService: any;
var hashConnect: any;

export const HashgraphProvider = () => {
  const dispatch = useDispatch();
  const [servicesLoaded, setServicesLoaded] = useState<boolean>(false);
  useFirebaseEvent("Hedera");

  useEffect(() => {
    const { BladeService } = require("./services/blade");
    const { HashConnectService } = require("./services/hash-connect");
    const { HashConnect: HashConnectInstance } = require("hashconnect");

    bladeService = new BladeService();
    hashConnect = new HashConnectService(new HashConnectInstance(false));
    setServicesLoaded(true);
    dispatch(updateWalletData({ config, isLoading: false }));
  }, []);

  if (servicesLoaded) {
    return (
      <AppProvider
        token={getTokenForWebSDK(token as unknown as Token)}
        chainId={config.ids.chainId}
      >
        <ServiceProvider
          bladeService={bladeService}
          hashConnect={hashConnect}
        />
        <WebSDKServices />
      </AppProvider>
    );
  }

  return null;
};
