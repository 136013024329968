import React from "react";
import { Box, chakra, Checkbox, Text } from "@chakra-ui/react";

interface TermsAndConditionProps {
  isChecked: boolean;
  termsPageLink: string;
  onChange(): void;
  onTermsLinkClick(): void;
}

const TermsAndCondition = ({
  onChange,
  onTermsLinkClick,
  isChecked,
  termsPageLink,
}: TermsAndConditionProps) => {
  return (
    <Box bg="backgroundPrimary" p="1rem" borderRadius="0.5rem">
      <Checkbox onChange={onChange} checked={isChecked} mr="1rem">
        <Text fontSize={{ base: "12px", md: "14px" }} fontWeight="400">
          I have read and accept{" "}
          <chakra.a
            color="primary"
            fontWeight={{ base: "400", md: "600" }}
            fontSize="14px"
            href={termsPageLink}
            target="_blank"
            rel="nofollow"
            borderBottom="1px dashed"
            onClick={onTermsLinkClick}
          >
            Terms of Service.
          </chakra.a>
        </Text>
      </Checkbox>
    </Box>
  );
};

export default TermsAndCondition;
